import React, { Component } from "react";
import { PaginationFilterTableV2 } from "../../common/PaginationFilterTableV2";
import * as Yup from "yup";
import moment from "moment";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import { confirmAlert } from "react-confirm-alert";

import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import {
  postUserDefaultLocation,
  setupDepartAsset,
  yardCheck,
  promiseUserByLocation,
  postUserDefaultShift,
  saveYardCheckStatusToAllTasks,
  searchLocations,
  promiseAllTaskTypes,
  downloadNonNullMoveToTasksV2,
  postDepartAsset,
  requestOnSiteYardToExcel,
  getSecondaryReferenceByRefID,
  getTaskReferenceByRefID,
  clientResponseByTaskUuid,
  getMoveTaskStatueByTaskuuid,
  promiseAssetStatusesByLocationUUid,
  promiseEZTrackEquipmentTypeByPrefix,
  promiseEZTrackEquipmentFuel,
  getAllSecondaryReferenceByRefID,
} from "../../NetworkHelpers";
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import {
  Alert,
  Card,
  CardBody,
  Form,
  FormFeedback,
  Button,
  Label,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  Input,
  Tooltip,
} from "reactstrap";
import { ChangeLocationFormField } from "../../Dashboard/Main/components/CurrentLocation";
import { ChangeShiftFormField } from "../../Dashboard/Main/components/CurrentShift";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { withRouter } from "react-router";
import { getFormattedDwellDateString } from "../../assets/components/StaticDurationDisplay";
import { getFlooredFixed } from "./Tasks";
import {
  convertCtoF,
  convertFtoC,

  getErrorsFromValidationError,
  endOfDay,
  getLocalStorageName,
  getTableSize,
  startOfDay,
} from "../../common/utilities";
import AsyncSelect from "react-select/lib/Async";
import * as PropTypes from "prop-types";
import TableDateRangePicker from "../../common/TableDateRangePicker";
import { showInfo, showError } from "../../MainApp";
import HelpIcon from "../../common/HelpIcon";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import EditTaskModal from "../../common/poweryard/EditTaskModal";
import AddAssetModal from "../../common/poweryard/AddAssetModal";
import DepartTaskModal from "../../common/poweryard/DepartTaskModal";
import ReferenceModal from "./ReferenceModal";
import IconWithTooltip from "../../common/IconWithTooltip";
import FlagModal from "../../common/FlagModal";
import move from "../../assets/img/icons/truck.svg"
class CancelTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideFilters: true,
      disabled: false,
      isReeferOn: false,
      assetTemperatureControlled: this.props.currentTask.assetTemperatureControlled,
      previousProps: this.props.previousProps,
      currentTask: this.props.currentTask,
      task: this.initializeTask(this.props.currentTask),
      initialTask: this.initializeTask(this.props.currentTask) // Store the initial state
    };
  }


  componentDidMount() {

    // Initialization logic
    this.setState({
      assetTemperatureControlled: this.props.currentTask.assetTemperatureControlled,
      isReeferOn: this.props.currentTask.assetReefer,
    });

    // this.fetchAssetStatusData();
    // this.fetchFuelLevelData();
  }

  fetchFuelLevelData() {
    promiseEZTrackEquipmentFuel('').then((results) => {
      const fuelLevel = results.find(item => item.label === this.props.currentTask.fuelLevel);
      this.setState((prevState) => ({
        task: {
          ...prevState.task,
          fuelLevel: fuelLevel ? { value: fuelLevel.uuid, label: fuelLevel.label, fuelLevel: fuelLevel.fuelLevel, ...fuelLevel } : null,
        },
      }));
    });
  }

  fetchAssetStatusData() {
    promiseAssetStatusesByLocationUUid(this.props.currentLocation.uuid)('').then((results) => {
      const assetStatus = results.find(item => item.label === this.props.currentTask.assetStatus);
      this.setState((prevState) => ({
        task: {
          ...prevState.task,
          assetStatus: assetStatus ? { value: assetStatus.uuid, label: assetStatus.name, ...assetStatus } : null,
        },
      }));
    });
  }
  componentDidUpdate(prevProps) {
    // Synchronize task state with currentTask prop if necessary
    console.log("Task1", this.state.task.fuelLevel, this.props.currentTask, this.props.currentTask.temperatureInc);
    if (prevProps.currentTask !== this.props.currentTask) {
      console.log("Task2", this.state.task, this.props.currentTask, this.props.currentTask.temperatureInc);
      const initialTask = this.initializeTask(this.props.currentTask);

      this.setState({
        assetTemperatureControlled: this.props.currentTask.assetTemperatureControlled,
        isReeferOn: this.props.currentTask.assetReefer,
        task: initialTask,
        initialTask: initialTask // Update initial state when currentTask changes
      }, () => {
        this.fetchAssetStatusData();
        this.fetchFuelLevelData();
      });
    }

    if (!prevProps.open && this.props.open) {
      let obj = {
        locationUuid: this.props.currentLocation.uuid,
      };
      setupDepartAsset(obj, (res) => {
        let results = res.content.taskTypes.map((it) => ({
          label: it.name,
          value: it.name,
          ...it,
        }));
        results = results.filter(
          (it) =>
            it.name.toLocaleLowerCase() === "depart asset" ||
            it.name.toLocaleLowerCase() === "check-out asset"
        );
        let index = results.findIndex(
          (it) => it.name.toLocaleLowerCase() === "check-out asset"
        );
        let currentTask = { ...this.state.task };
        currentTask.created = new Date().getTime();
        currentTask.type = results[index];
        this.setState({
          taskTypeOptions: results,
          task: currentTask,
          timeStamp: new Date().getTime(),
        });
      });
    }
  }

  initializeTask(currentTask) {
    let tempSetPoint = currentTask.temperatureSetPointInC;
    let actualTemp = currentTask.temperatureInC;

    if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
      tempSetPoint = convertCtoF(tempSetPoint);
      actualTemp = convertCtoF(actualTemp);
    }

    console.log("tempSetPoint after conversion:", tempSetPoint, "original tempSetPointInC:", currentTask.temperatureSetPointInC);
    console.log("actualTemp after conversion:", actualTemp, "original temperatureInC:", currentTask.temperatureInC);

    // Return the updated task object
    return {
      ...currentTask,
      assetStatus: null,
      fuelLevel: null,
      tempSetPoint: tempSetPoint ? this.getFlooredFixed(tempSetPoint, 1) : '',
      actualTemp: actualTemp ? this.getFlooredFixed(actualTemp, 1) : ''
    };
  }


  handleCancel = () => {
    this.setState((prevState) => ({
      task: prevState.initialTask // Revert to initial state
    }));
    this.props.onCancel && this.props.onCancel();
  };
  getAssetType() {
    const { asset, equipment, assetType } = this.props.currentTask;
    if (asset && equipment) {
      return asset.equipment.name;
    } else if (assetType) {
      return assetType.name;
    } else {
      return "";
    }
  }

  handleTaskChange = (field, value) => {
    this.setState((prevState) => ({
      task: {
        ...prevState.task,
        [field]: value,
      },
    }));
  };

  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1>Depart / Check-Out Asset</h1>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FFFormTextField
                  fieldname="trailerName"
                  label={"Trailer Name"}
                  icon="fa fa-truck"
                  defaultValue={
                    this.props.currentTask
                      ? this.props.currentTask.trailerName
                        ? this.props.currentTask.trailerName
                        : ""
                      : ""
                  }
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FFFormTextField
                  fieldname="trailerNumber"
                  label={"Trailer Number"}
                  icon="fa fa-truck"
                  defaultValue={
                    this.props.currentTask
                      ? this.props.currentTask.trailerNumber
                        ? this.props.currentTask.trailerNumber
                        : ""
                      : ""
                  }
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FFFormSelectField
                  label="Task Type"
                  required={true}
                  placeholder="Enter Value"
                  icon="fa-keynote"
                  fieldname="type"
                  name="type"
                  value={this.state.task.type ? this.state.task.type.name : ""}
                  options={this.state.taskTypeOptions}
                  noSearch={true}
                  onChange={(v) => this.handleTaskChange('type', v)}
                />
              </Col>
            </Row>
            <Row >
              <Col lg="2" md="3" sm="4">
                <Label>{"Timestamp"}</Label>
              </Col>
              <Col className={"task-form"} lg="10" md="9" sm="8">
                <DatePicker
                  placeholderText={"MM/DD/YYYY HH:MM AP"}
                  className={
                    this.state.errors &&
                    this.state.errors.start &&
                    "is-invalid"
                  }
                  selected={this.state.timeStamp}
                  onChange={(date) => this.setState({ timeStamp: date })}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm a"
                  showTimeInput
                  shouldCloseOnSelect={false}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col>
                <FFFormAsyncSelectField
                  defaultOptions={true}
                  isClearable={false}
                  fieldname="assetType"
                  label="Asset Type"
                  icon="fa-spot"
                  value={this.state.task.assetType || ""}
                  required={false}
                  hideEmpty={true}
                  placeholder="Asset Type - Select the Trailer Type"
                  loadOptions={
                    this.props.currentLocation
                      ? promiseEZTrackEquipmentTypeByPrefix(
                        this.props.currentLocation.uuid
                      )
                      : promiseEZTrackEquipmentTypeByPrefix
                  }
                  onChange={(v) => {
                    console.log(v, "Loading");
                    this.setState({

                      isReeferOn: v.reefer,
                      assetTemperatureControlled: v.temperatureControlled,
                    });

                    this.handleTaskChange('assetType', v.label)
                  }}
                />
              </Col>
            </Row>


            {(this.state.isReeferOn) && (
              <>

                <Row >
                  <Col>


                    <FFFormAsyncSelectField
                      defaultOptions={true}

                      fieldname="fuelLevel"
                      label="Fuel Level"
                      hideEmpty={true}
                      placeholder="Select the fuel Level of the trailer (from the list)"
                      icon="fa-solid fa-gas-pump"
                      isClearable={true}
                      value={this.state.task.fuelLevel ? this.state.task.fuelLevel.label : this.props.currentTask ? this.props.currentTask.fuelLevel : null}

                      required={false}
                      loadOptions={promiseEZTrackEquipmentFuel}
                      onChange={(v) => {
                        this.handleTaskChange('fuelLevel', v);


                      }}
                    />
                  </Col>
                </Row>
                <Row >
                  <Col>


                    <FFFormCheckboxField
                      fieldname="reefer"
                      icon="fa-power-off"
                      label="Reefer (On/Off)"
                      required={false}
                      value={this.state.task.reefer || false}
                      onChange={(v) => {
                        let s = { ...this.state.task };
                        s.reefer = v;
                        if (!v) {
                          s.continous = false;
                        }
                        this.setState({ task: s });
                      }}
                    />
                  </Col>
                </Row>
                <Row >
                  <Col>
                    <FFFormCheckboxField
                      fieldname="continous"
                      icon="fa-power-off"
                      label="Continuous (Yes/No)"
                      disabled={!this.state.task.reefer}
                      required={false}
                      value={this.state.task.continous || false}
                      onChange={(v) => this.handleTaskChange('continous', v)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {(this.state.assetTemperatureControlled) && (
              <>
                <Row >
                  <Col>
                    <FFFormTextField
                      fieldname="tempSetPoint"
                      icon="fa-temperature-low"
                      label={
                        "Temp - Set Point (" +
                        (this.props.company.temperatureUnitsOfMeasure ===
                          "Fahrenheit"
                          ? "℉"
                          : "℃") +
                        ")"
                      }
                      required={false}
                      numbersOnly={false}
                      placeholder="Enter the temperature set point"
                      //  value={this.state.task.temperatureSetPointInC}
                      onChange={(v) => {
                        console.log(v, "temp");
                        let s = { ...this.state.task };
                        s.tempSetPoint = v;

                        this.setState({ task: s });
                      }}
                    />
                  </Col>
                </Row>
                <Row >
                  <Col>
                    <FFFormTextField
                      fieldname="actualTemp"
                      label={
                        "Temp - Actual (" +
                        (this.props.company.temperatureUnitsOfMeasure ===
                          "Fahrenheit"
                          ? "℉"
                          : "℃") +
                        ")"
                      }

                      placeholder="Enter the actual temperature"
                      icon="fa-temperature-high"
                      required={false}
                      value={this.state.task.actualTemp}
                      onChange={(v) => {
                        console.log(v, "temp");
                        let s = { ...this.state.task };
                        s.actualTemp = v;

                        this.setState({ task: s });
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row >
              <Col>
                <FFFormAsyncSelectField
                  defaultOptions={true}
                  isClearable={false}
                  fieldname="assetStatus"
                  label="Asset Status"
                  icon="fa-spot"
                  value={this.state.task.assetStatus ? this.state.task.assetStatus.label : this.props.currentTask ? this.props.currentTask.assetStatus : ""}
                  required={false}
                  hideEmpty={true}
                  placeholder="Select the status of the trailer (from the list)"
                  loadOptions={promiseAssetStatusesByLocationUUid(
                    this.props.currentLocation.uuid
                  )}
                  onChange={(v) => this.handleTaskChange('assetStatus', v)}
                />
              </Col>
            </Row>
            {this.props.currentLocation.apiSetting !== null &&
              this.props.currentLocation.apiSetting !== undefined &&
              this.props.currentLocation.apiSetting.refernceApi && (
                <Row >
                  <Col>
                    <FFFormAsyncSelectField
                      // key={
                      //   "ID_" +
                      //   (this.state.taskUnderEdit.secondaryRef !== null
                      //     ? this.state.taskUnderEdit.secondaryRef.value
                      //     : "")
                      // }
                      // isDisabled={this.state.isNew}
                      isDisabled={true}
                      defaultOptions={false}
                      placeholder="Select Your Company ID for the Shipment on this Trailer"
                      isClearable={true}
                      hideEmpty={true}
                      fieldname="secondaryRef"
                      label="Reference Number"
                      icon="fa-regular fa-hashtag"
                      value={
                        this.state.task.secondaryRef
                          ? this.state.task.secondaryRef

                          : ""
                      }
                      required={false}
                      loadOptions={
                        this.props.location
                          ? getAllSecondaryReferenceByRefID(
                            this.props.location.uuid
                          )
                          : getAllSecondaryReferenceByRefID
                      }
                      // isDisabled={this.state.secRefNumber}
                      // onBlur={() => {
                      //   let errors = this.validate(
                      //     this.validationSchema.bind(this)
                      //   )(this.state.taskUnderEdit);
                      //   let touched = { ...this.state.touched };
                      //   touched.secondaryRef = true;
                      //   this.setState({ errors: errors, touched: touched });
                      // }}
                      // error={this.state.errors.secondaryRef}
                      // touched={this.state.touched.secondaryRef}
                      onChange={(v) => {
                        let s = { ...this.state.taskUnderEdit };
                        s.secondaryRef = v;
                        if (v !== null) {
                          if (s.isNew) {
                            this.onChangeRefNum(v);
                          }
                          this.setState({
                            taskUnderEdit: s,
                            secRefNumber: v.valueSubmit,
                          });
                        } else {
                          this.setState({
                            taskUnderEdit: s,
                            secRefNumber: null,
                          });
                        }

                        // let touched = {...this.state.touched};
                        // touched.assignee = true;
                        // this.setState({taskUnderEdit: s, touched: touched });
                      }}
                    />
                  </Col>
                </Row>

              )}

            <Row >
              <Col>
                <FFFormTextField
                  fieldname="shipmentNumber"
                  label="Shipment Number"
                  icon="fa-tag"
                  value={this.state.task ? this.state.task.shipmentNumber : this.props.currentTask ? this.props.currentTask.shipmentNumber : ""}
                  required={false}
                  hideEmpty={true}
                  placeholder="Enter the shipment number"
                  onChange={(v) => this.handleTaskChange('shipmentNumber', v)}
                />
              </Col>
            </Row>
            <Row >
              <Col>
                <FFFormTextField
                  fieldname="sealNumber"
                  label="Seal#"
                  icon="fa-tag"
                  value={this.state.task ? this.state.task.sealNumber : this.props.currentTask ? this.props.currentTask.sealNumber : ""}
                  required={false}
                  hideEmpty={true}
                  placeholder="Enter the seal number"
                  onChange={(v) => this.handleTaskChange('sealNumber', v)}
                />
              </Col>
            </Row>
            <Row >
              <Col>
                <FFFormTextAreaField
                  label="Comments"
                  required={false}
                  placeholder="Enter Task Comments"
                  icon="fa-comment"
                  fieldname="comments"
                  isClearable={true}
                  value={this.state.task ? this.state.task.comments : this.props.currentTask ? this.props.currentTask.comments : ""}
                  onChange={(event) => {
                    let s = { ...this.state.task };
                    s.comments =
                      event !== null ? event.target.value : null;
                    this.setState({ task: s });
                  }}
                  rows={"5"}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-right" style={{ marginTop: "10px" }}>
                <Button
                  disabled={this.props.disabledDepart}

                  color="danger"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>{" "}
                &nbsp;
                <Button
                  color="primary"
                  disabled={this.props.disabledDepart}
                  onClick={() => this.onSubmit()}
                  type="button"

                >
                  Save & Close
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }

  onSubmit() {

    let task = { ...this.state.task };
    let payload = {};
    if (task.actualTemp === "") {
      task.actualTemp = null;
    }
    if (task.tempSetPoint === "") {
      task.tempSetPoint = null;
    }

    if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
      task.temperatureSetPointInC = convertFtoC(task.tempSetPoint);
      task.temperatureInC = convertFtoC(task.actualTemp);
    } else if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
      task.temperatureSetPointInC = task.tempSetPoint;
      task.temperatureInC = task.actualTemp;
    }
    if (this.state.isReeferOn) {

      payload = {
        taskUuid: task.taskUuid,
        type: task.type,
        assetType: task.assetType,
        assetStatus: task.assetStatus ? { name: task.assetStatus.label, uuid: task.assetStatus.uuid } : null,
        shipmentNumber: task.shipmentNumber,
        sealNumber: task.sealNumber,
        actualTemp: task.actualTemp,
        tempSetPoint: task.tempSetPoint,
        fuelLevel: task.fuelLevel ? { uuid: task.fuelLevel.uuid, name: task.fuelLevel.name, fuelLevel: task.fuelLevel.fuelLevel } : null,
        comments: task.comments,
        reefer: task.reefer ? true : false,
        continous: task.continous ? true : false,
        timeStamp: this.state.timeStamp,
        temperatureSetPointInC: task.temperatureSetPointInC,
        temperatureInC: task.temperatureInC
      };
    }
    else if (this.state.assetTemperatureControlled) {

      payload = {
        taskUuid: task.taskUuid,
        type: task.type,
        assetType: task.assetType,
        assetStatus: task.assetStatus ? { name: task.assetStatus.label, uuid: task.assetStatus.uuid } : null,
        shipmentNumber: task.shipmentNumber,
        sealNumber: task.sealNumber,
        reefer: null,
        continous: null,
        actualTemp: task.actualTemp,
        tempSetPoint: task.tempSetPoint,
        comments: task.comments,
        timeStamp: this.state.timeStamp,
        temperatureSetPointInC: task.temperatureSetPointInC,
        temperatureInC: task.temperatureInC
      };
    } else {
      payload = {
        taskUuid: task.taskUuid,
        type: task.type,
        assetType: task.assetType,
        assetStatus: task.assetStatus ? { name: task.assetStatus.label, uuid: task.assetStatus.uuid } : null,
        shipmentNumber: task.shipmentNumber,
        sealNumber: task.sealNumber,
        reefer: null,
        continous: null,
        comments: task.comments,
        timeStamp: this.state.timeStamp,

      };

    }

    return this.props.onSave && this.props.onSave(payload);
  }
}

CancelTaskModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  currentTask: PropTypes.object.isRequired,
  currentLocation: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};


class AuthenticationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.currentLocationObj,
      user: this.props.user,
    };
  }
  componentDidMount() { }
  componentDidUpdate(previousProps, prevState, snapshot) {
    this.state.user = this.props.previousProps.user;
    this.state.location = this.props.currentLocationObj;
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} size={"lg"}>
          <ModalHeader>Yardcheck Complete Authorization</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <div className={"bg-white p-3"}>
                    <p>
                      I have reviewed{" "}
                      {this.state.location && this.state.location.name} and
                      check all Yards and Docks.
                    </p>
                    <p>
                      {" "}
                      To the best of my knowledge the yard check is complete.
                    </p>
                    <p>
                      {" "}
                      Everything is current as of now{" "}
                      {<MomentDisplay date={new Date()} />}.{" "}
                    </p>
                    <p>When authorized this Yard check cannot be changed. </p>
                    <p>
                      {this.state.user && this.state.user.firstname}{" "}
                      {this.state.user && this.state.user.lastname}{" "}
                    </p>
                    <p>{this.state.user && this.state.user.uuid}</p>
                  </div>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col className="text-right" style={{ marginTop: "10px" }}>
                  <Button
                    color="danger"
                    onClick={() => this.props.onCancel && this.props.onCancel()}
                  >
                    Cancel
                  </Button>{" "}
                  &nbsp;
                  <Button
                    color="primary"
                    onClick={() => this.onSubmit()}
                    type="submit"
                  >
                    Save & Authorize
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    );
  }

  onSubmit() {
    return (
      this.props.onSave && this.props.onSave(this.state && this.state.task)
    );
  }
}

AuthenticationModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
};
export const getTempSetpoint = function (row, user) {
  let tempSetPoint = row.original.temperatureSetPointInc;
  if (tempSetPoint == null) {
    return "";
  }
  if (isF(user)) {
    let a = convertCtoF(tempSetPoint);
    return getFlooredFixed(a, 1) + " ℉";
  } else {
    let a = tempSetPoint;
    return getFlooredFixed(a, 1) + " ℃";
  }
};
export const getTemp = function (row, setPoint, user) {
  let temperatureInC = row.original.temperatureInc;

  if (temperatureInC == null) {
    return "";
  }

  if (setPoint != null) {
    if (isF(user)) {
      let tempInF = convertCtoF(temperatureInC);
      let setPointInF = convertCtoF(setPoint);
      tempInF -= setPointInF;
      let a = tempInF;
      return getFlooredFixed(a, 1) + " ℉";
    } else {
      temperatureInC -= setPoint;
      let a = temperatureInC;
      return getFlooredFixed(a, 1) + " ℃";
    }
  }
  if (isF(user)) {
    let a = convertCtoF(temperatureInC);
    return getFlooredFixed(a, 1) + " ℉";
  } else {
    let a = temperatureInC;
    return getFlooredFixed(a, 1) + " ℃";
  }
};

export const isF = function (user) {
  return (
    user &&
    user.company &&
    user.company.temperatureUnitsOfMeasure &&
    user.company.temperatureUnitsOfMeasure === "Fahrenheit"
  );
};

class Onsite extends Component {
  handleClickComments = (rowindex) => {
    if (this.state.istab === true) {
      this.setState({ Showhover: true });
      if (this.state.clickedRowIndex === rowindex) {
        this.setState((prevState) => ({
          showTooltip: !prevState.showTooltip,
          clickedRowIndex: rowindex,
        }));
      } else {
        this.setState({
          showTooltip: true,
          clickedRowIndex: rowindex,
        });
      }
    }

    console.log("tooltip", this.state.showTooltip);
  };

  latestLocation = {
    longitude: "",
    latitude: "",
  };
  openRefModal(ref, elem) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = `${elem.split(" ")[0]}  (${elem.split(" ")[1]})`;
    this.onGetRefDataByRefID(ref, elem.split(" ")[0]);
  }
  onGetRefDataByRefID(ref, elem) {
    getSecondaryReferenceByRefID(
      elem.trim(),
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }
  tableColumns = [
    {
      Header: "Check",
      accessor: "yardStatusCheck",
      hidden: true,
      width: 80,
      filterable: false,
      default: false,
      show: false,
      sortable: false,
      keepShowing: true,
      disableControls: true,
      Cell: (row) => {
        // let a = Math.random();
        return (
          <>
            <FormGroup check>
              <div className="round">
                <input
                  onChange={(e) => {
                    let obj = row.original;
                    obj.yardStatusCheck = e.target.checked;
                    let a = [...this.state.allTasks];
                    let index = a.findIndex(
                      (it) => it.taskUuid === obj.taskUuid
                    );
                    if (index > -1) {
                      a[index] = obj;
                      this.setState({
                        allTasks: a,
                      });
                    }
                    let falseCheckArr = a.filter(
                      (it) => it.yardStatusCheck === false
                    );
                    let prevArr = JSON.parse(localStorage.getItem("onsiteArr"));
                    let ind = prevArr.findIndex(
                      (it) =>
                        it.carrierUuid === obj.carrierUuid &&
                        it.assetId === obj.assetId &&
                        it.taskUuid !== obj.taskUuid
                    );
                    if (ind > -1) {
                      prevArr.splice(ind, 1);
                    }
                    if (prevArr) {
                      let z = prevArr.findIndex(
                        (it) => it.taskUuid === obj.taskUuid
                      );
                      if (z > -1) {
                        prevArr[z] = obj;
                        localStorage.setItem(
                          "onsiteArr",
                          JSON.stringify(prevArr)
                        );
                      }
                    }
                    console.log("Success Here", prevArr, falseCheckArr,)
                    let request = [];
                    if (this.state.requestRes) {
                      request = this.state.requestRes;
                    } else {
                      request = this.state.previousSearchRequest.filtered;
                    }
                    console.log(request.length, this.state.requestRes, this.state.previousSearchRequest.filtered, "request Here");
                    if (request.length > 1) {
                      let filteredData = prevArr;
                      for (let zid = 0; zid < request.length - 1; zid++) {
                        let idToCatch = request[zid].id;
                        if (idToCatch === "spotType") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.spotType !== null &&
                              it.spotType
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "lastModifiedBy") {
                          filteredData = filteredData.filter((it) =>
                            it.lastModifiedBy
                              .toLowerCase()
                              .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "createdBy") {
                          filteredData = filteredData.filter((it) =>
                            it.createdBy !== null &&
                            it.createdBy
                              .toLowerCase()
                              .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "secondaryRef") {
                          filteredData = filteredData.filter((it) =>
                            it.secondaryRef !== null
                              ? it.secondaryRef
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                              : ""
                          );
                        } else if (idToCatch === "sealNumber") {
                          filteredData = filteredData.filter((it) =>
                            it.sealNumber !== null
                              ? it.sealNumber
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                              : ""
                          );
                        } else if (idToCatch === "sealed") {
                          let isBool = request[zid].value === "true";
                          filteredData = filteredData.filter(
                            (it) => it.sealed == isBool
                          );
                          console.log(filteredData, "found");

                        } else if (idToCatch === "moveToZone") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.moveToZone !== null &&
                              it.moveToZone
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "spotName") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.spotName !== null &&
                              it.spotName
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "trailerName") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.trailerName !== null &&
                              it.trailerName
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "trailerNumber") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.trailerNumber !== null &&
                              it.trailerNumber
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "assetStatus") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.assetStatus !== null &&
                              it.assetStatus
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "assetType") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.assetType !== null &&
                              it.assetType
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        }
                        else if (idToCatch === "arrivalTrackingId") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.arrivalTrackingId !== null &&
                              it.arrivalTrackingId
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        }
                        else if (idToCatch === "taskType") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.taskType !== null &&
                              it.taskType
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        }

                        else if (idToCatch === "fuelLevel") {
                          filteredData = filteredData.filter(
                            (it) =>
                              it.fuelLevel !== null &&
                              it.fuelLevel
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                          );
                        } else if (idToCatch === "reefer") {
                          let isBool;

                          if (request[zid].value === "YES") {
                            isBool = true;
                          } else if (request[zid].value === "NO") {
                            isBool = false;
                          } else {
                            isBool = null; // Handle unexpected values as null or another appropriate value
                          }
                          console.log(filteredData, isBool, request[zid].value, "found1");

                          filteredData = filteredData.filter(
                            (it) => it.reefer == isBool
                          );
                          console.log(filteredData, "found");
                        }
                        else if (idToCatch === "continous") {
                          let isBool;

                          if (request[zid].value === "YES") {
                            isBool = true;
                          } else if (request[zid].value === "NO") {
                            isBool = false;
                          } else {
                            isBool = null; // Handle unexpected values as null or another appropriate value
                          } filteredData = filteredData.filter((it) => it.continous == isBool);
                        }

                        else {
                          filteredData = this.filterDataOnFilters(
                            idToCatch,
                            request[zid].value,
                            filteredData
                          );
                        }
                      }

                      prevArr = filteredData;
                      a = filteredData;
                      console.log(a, "sss Here");
                      falseCheckArr = a.filter(
                        (it) => it.yardStatusCheck === false
                      );

                    }
                    let s = parseInt(localStorage.getItem("totalElements"));
                    console.log(s, prevArr.length, falseCheckArr.length, "totalElements Here");

                    if (falseCheckArr.length === 0 && prevArr.length == s) {
                      if (
                        prevArr.filter((it) => it.yardStatusCheck == false)
                          .length == 0
                      ) {
                        this.setState({
                          enableSaveChangedOnSite: true,
                        });
                      }
                    } else {
                      this.setState({
                        enableSaveChangedOnSite: false,
                      });
                    }
                    // row.enableYardCheck = !row.enableYardCheck
                  }}
                  type="checkbox"
                  id={"checkbox" + row.original.taskUuid}
                />
                <label htmlFor={"checkbox" + row.original.taskUuid}></label>
              </div>
            </FormGroup>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      width: 200,
      filterable: false,
      default: true,
      sortable: false,
      resizable: false,

      Cell: (row) => {


        let disable = row.original.taskMoved;


        return (
          <>
            &nbsp; {(this.state.editButtonStatus === undefined || this.state.editButtonStatus) &&
              <Button
                title="Edit"
                aria-label="Edit"
                className={"me-2"}
                style={{
                  opacity: this.props.currentLocation.enableArrivalTracking && row.original.taskMoved ? 0.5 : 1,
                }}
                onClick={() => {
                  if (this.props.currentLocation.enableArrivalTracking && row.original.taskMoved) {

                    this.onClickActionButtons(row.original.taskUuid);

                  } else {
                    this.setState({
                      autoRefreshEnabled: false,
                      editDepartAllowance: true,
                      currentTaskUnderEdit: row.original,
                      editModalOpen: true,
                    });

                  }

                }}
              >
                <i className="fa fa-edit" />
              </Button>}  &nbsp; &nbsp;
            {(this.state.moveButtonStatus === undefined || this.state.moveButtonStatus) && (this.props.currentLocation.enableMoveMe == true) &&

              <Button
                title={
                  // !(row.original.taskMoved == false || status === "Complete" || status === "Canceled" || status === null)
                  //   ? "Task Create Time: " + dateString + " Move To Spot: " + spot +
                  //   " Task Status: " + status
                  //   : "Move Me"
                  "Move Me"
                }
                aria-label="Move Me"
               


               // className={"btn-primary"}
                // style={{ backgroundColor: row.original.taskMoved == true ? "#c8ced3" : "#51B32E", }}
                style={{
                  backgroundColor: 
                    row.original.taskMoved == true ? "#c8ced3" :
                      "#51B32E", 
                }}
                onClick={() => {
                  if (!row.original.taskMoved) { this.props.history.push("/task/new/" + row.original.taskUuid) }
                  else {

                    this.onClickActionButtons(row.original.taskUuid);




                  }
                }}
               

              

              >
                <img style={{ height: "16px", width: "18px" }} src={move} alt="move me" />
              </Button>} &nbsp; &nbsp;
            {(this.state.departButtonStatus === undefined || this.state.departButtonStatus) && <Button
              title="Depart"
              aria-label="Depart"
              className={"btn-danger"}
              style={{
                opacity: this.props.currentLocation.enableArrivalTracking && row.original.taskMoved ? 0.5 : 1,
              }}
              onClick={() => {
                if (this.props.currentLocation.enableArrivalTracking && row.original.taskMoved) {

                  this.onClickActionButtons(row.original.taskUuid);

                } else {
                  this.setState({
                    autoRefreshEnabled: false,
                    editDepartAllowance: true,
                    currentTask: row.original,
                    cancelModalOpen: true,
                  });

                }

              }}
            // disabled={this.state.autoRefreshEnabled === true}

            >
              <i className="fa fa-truck" />
            </Button>}
          </>
        );
      },

    },

    // {
    //   Header: "Edit",
    //   accessor: "edit",
    //   // width: 200,
    //   filterable: false,
    //   default: true,
    //   sortable: false,
    //   disableControls: true,
    //   Cell: (row) => {
    //     return (
    //       <>
    //         <Button
    //           title="Edit"
    //           aria-label="Edit"
    //           // disabled={this.state.autoRefreshEnabled === true}
    //           onClick={() => {
    //             if (this.state.autoRefreshEnabled === true) {
    //               this.setState({ editDepartAllowance: false });
    //             } else {
    //               this.setState({
    //                 autoRefreshEnabled: false,
    //                 editDepartAllowance: true,
    //                 currentTaskUnderEdit: row.original,
    //                 editModalOpen: true,
    //               });
    //             }
    //           }}
    //         >
    //           <i className="fa fa-edit" />
    //         </Button>
    //         {/* {this.state.autoRefreshEnabled === true &&
    //         <HelpIcon helpMessage={"In order to edit please turn off Last Refresh."} />
    //       }  */}
    //       </>
    //     );
    //   },
    // },
    // {
    //   accessor: "statusFlag",
    //   Header: "Alert",
    //    default: false,
    //   filterable: false,
    //   sortable: false,
    //   Cell: (row) => {
    //     let taskObj = row.original;
    //     return (
    //       taskObj.statusFlag &&
    //       <div className="text-center">
    //         {/* <i class="fas fa-flag text-danger c-pointer" ></i> */}
    //         {/* <Tooltip placement="right" target={`ID_${v.taskUuid}`} isOpen={this.state.tooltipOpen} toggle={this.toggle}>
    //           {'System Response to this Task, click for details'}
    //         </Tooltip> */}
    //         {taskObj.statusFlag &&
    //           <IconWithTooltip
    //             isToolTip={true}
    //             isIcon={true}
    //             isClickAble={true}
    //             toolTipTxt={'System Response to this Task, click for details'}
    //             classList={'fas fa-flag text-danger c-pointer'}
    //             placement="top"
    //             onClick={() => {
    //               this.onClickIcon(taskObj)
    //             }}
    //           />
    //         }
    //       </div>
    //     );
    //   },
    // },
    // {
    //   accessor: "statusSource",
    //   Header: "Alert Status Source",
    //    default: false,
    //   filterable: false,
    //   sortable: false,
    //   Cell: (row) => {
    //     let v = row.original;
    //     return v.statusSource !== "" ? v.statusSource : 'UI'
    //   }
    // },
    // {
    //   Header: "Depart",
    //   accessor: "depart",
    //   // width: 200,
    //   filterable: false,
    //   default: true,
    //   sortable: false,
    //   disableControls: true,
    //   Cell: (row) => {
    //     return (
    //       <>
    //         <Button
    //           title="Depart"
    //           aria-label="Depart"
    //           className={"btn-danger"}
    //           // disabled={this.state.autoRefreshEnabled === true}
    //           onClick={() => {
    //             if (this.state.autoRefreshEnabled === true) {
    //               this.setState({ editDepartAllowance: false });
    //             } else {
    //               this.setState({
    //                 autoRefreshEnabled: false,
    //                 editDepartAllowance: true,
    //                 currentTask: row.original,
    //                 cancelModalOpen: true,
    //               });
    //             }
    //           }}
    //         >
    //           <i className="fa fa-truck" />
    //         </Button>
    //         {/* {this.state.autoRefreshEnabled === true &&
    //         <HelpIcon helpMessage={"In order to depart please turn off Last Refresh."} />
    //       } */}
    //       </>
    //     );
    //   },
    // },
    {
      accessor: "createdBy",
      Header: "Task Created By User",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "createdDate",
      Header: "Task Created Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.createdDate) {
          return (
            <MomentDisplay
              date={task.createdDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "completedDate",
      Header: "Task Complete Datetime",
      default: false,
      show: false,
      filterable: false,
      dataType: "date",
      operator: "LT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.completedDate) {
          return (
            <MomentDisplay
              date={task.completedDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return <div />;
        }
      },
    },
    {
      accessor: "lastModifiedBy",
      Header: "Last Updated By User",
      filterable: true,
      sortable: true,
      default: false,
      show: false,
      Cell: (row) => {
        let task = row.original;
        if (task.lastModifiedBy) {
          return task.lastModifiedBy;
        } else {
          return null;
        }
      },
    },
    {
      accessor: "lastModified",
      Header: "Last Updated Datetime",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.lastModified) {
          return (
            <MomentDisplay
              date={task.lastModified}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "spotType",
      Header: "Spot Type",
      default: true,
      filterable: true,
      sortable: true,
      // Filter: ({ filter, onChange }) => (
      //   <>
      //     <select
      //       ref={this.sealedFilterRef}
      //       onChange={(event) => {
      //         this.setState({ sealedFilter: event.target.value });
      //         onChange(event.target.value);
      //       }}
      //       style={{ width: "100%" }}
      //       value={this.state.sealedFilter ? this.state.sealedFilter : ""}
      //     >
      //       <option value="">Select</option>
      //       <option value="Dock">Dock Spot</option>
      //       <option value="Yard">Yard Spot</option>
      //     </select>
      //   </>
      // ),
      // sortable: true,
      // Cell: row => {
      //     let name = row.original.moveTo ? row.original.moveTo.name : '';
      //     let zone = row.original.moveTo ? row.original.moveToZone : '';
      //     if (name) {
      //         return name + ' - ' + zone;
      //     } else {
      //         return zone;
      //     }
      // }
    },
    {
      accessor: "moveToZone",
      Header: "Zone Name",
      default: true,
      filterable: true,
      sortable: true,
      // Cell: row => {
      //     let name = row.original.moveTo ? row.original.moveTo.name : '';
      //     let zone = row.original.moveTo ? row.original.moveToZone : '';
      //     if (name) {
      //         return name + ' - ' + zone;
      //     } else {
      //         return zone;
      //     }
      // }
    },
    {
      accessor: "spotName",
      Header: "Spot #",
      default: true,
      filterable: true,
      sortable: true,
    },


    {
      accessor: "trailerName",
      Header: "Trailer Name",
      default: true,
      filterable: true,
      sortable: true,

    },
    {
      accessor: "trailerNumber",
      Header: "Trailer Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerNumber ? row.original.trailerNumber.replace(/ /g, '').toUpperCase() : "";
      },
    },

    {
      accessor: "comments",
      Header: "Comments",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        const targetId = `commentsCell_${row.index}`;

        return (
          <span
            id={targetId}
            title={this.state.Showhover ? " " : row.original.comments}
            onClick={() => {
              this.handleClickComments(row.index);
            }}
          >
            {this.state.showTooltip &&
              this.state.clickedRowIndex == row.index && (
                <Tooltip
                  style={{ color: "#fff" }}
                  target={targetId}
                  isOpen={this.state.showTooltip}
                  placement="right"
                >
                  {row.original.comments}
                </Tooltip>
              )}
            {console.log(this.state.Showhover, "titlestate")}
            {row.original.comments}
          </span>
        );
      },
    },

    {
      accessor: "assetType",
      Header: "Trailer Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assetStatus",
      Header: "Trailer Status",
      default: true,
      title: "Current asset status for the task",
      filterable: true,
      sortable: true,
    },
    {
      accessor: "shipmentNumber",
      Header: "Shipment Number",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "sealed",
      Header: "Sealed",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.sealed ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.sealedFilterRefSealed}
            onChange={(event) => {
              this.setState({ sealedFilterSealed: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.sealedFilterSealed
                ? this.state.sealedFilterSealed
                : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
      ),
    },
    {
      accessor: "sealNumber",
      Header: "Seal Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return (
          <span title={row.original.sealNumber}>{row.original.sealNumber}</span>
        );
      },
    },
    // Code By Ali
    // {
    //   accessor: "secondaryRef",
    //   Header: "Reference Number",
    //   default: true,
    //   filterable: false,
    //   sortable: false,
    //   // Cell: (row) => {
    //   //   let v = row.original;
    //   //   // console.log("v", v);
    //   //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null ? "" : row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //   //     }`}</a>
    //   // }
    //   Cell: (row) => {
    //     if (row.value !== null && row.value !== '' && row.value !== undefined) {
    //       let v = row.original;
    //       return row.value.split(",").map((elem, index) => (
    //         <a href={"javascript:;"} onClick={() => this.openRefModal(v, elem)}>
    //           {" "}
    //           {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //             }`} */}
    //           {`${elem}`}
    //           {row.value.split(",").length - 1 === index ? "" : ","} <br />
    //         </a>
    //       ));
    //     }
    //     else {
    //       return 'N/A'
    //     }
    //   },

    // },
    // },

    // {
    //   accessor: 'primaryReferenceNumber',
    //   Header: 'Reference Number',
    //   default: true,
    //   filterable: false,
    //   sortable: false,

    //   Cell: row => <a href={'javascript:;'} onClick={() => alert(row.original.primaryReferenceID)}> {row.value} </a>
    // },

    // {
    //     accessor: 'sealId',
    //     Header: 'Seal ID',
    //      default: false,
    //     filterable: true,
    //     sortable: true,
    //     Cell: row => {

    //         return row.original.sealId ? row.original.sealId : "";
    //     },
    // },

    {
      accessor: "fuelLevel",
      Header: "Fuel Level",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.fuelLevel ? row.original.fuelLevel : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.fuelFilterRef}
            onChange={(event) => {
              this.setState({ fuelFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.fuelFilter ? this.state.fuelFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Empty">Empty</option>
            <option value="1/8">1/8</option>
            <option value="1/4">1/4</option>
            <option value="3/8">3/8</option>
            <option value="1/2">1/2</option>
            <option value="5/8">5/8</option>
            <option value="3/4">3/4</option>
            <option value="7/8">7/8</option>
            <option value="Full">Full</option>
          </select>
        </>
      ),
    },
    {
      accessor: "reefer",
      Header: "Reefer (On/Off )",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.reefer == true ? "Yes" : row.original.reefer == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.reeferFilterRef}
            onChange={(event) => {
              this.setState({ reeferFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.reeferFilter ? this.state.reeferFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "continous",
      Header: "Continuous (Yes/No)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.continous == true ? "Yes" : row.original.continous == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.continousFilterRef}
            onChange={(event) => {
              this.setState({ continousFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.continousFilter ? this.state.continousFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "temperatureSetPointInc",
      Header: "Temp - Setpoint" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTempSetpoint(row, this.props.user);
      },
    },

    {
      accessor: "temperatureInc",
      Header: "Temp - Actual" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTemp(row, null, this.props.user);
      },
    },
    {
      accessor: "temperatureDeviationInc",
      Header: "Temp - Deviation" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        return getTemp(
          row,
          row.original.temperatureSetPointInc,
          this.props.user
        );
      },
    },
  ];

  noEnd(row) {
    return !row.original.end;
  }

  notCancelled(row) {
    return row.original.taskStatus !== "Canceled";
  }

  inProgress(row) {
    return row.original.taskStatus === "InProgress";
  }

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.onSiteFilterRef = React.createRef();
    const parse = JSON.parse(
      localStorage.getItem(getLocalStorageName("poweryard-onsite", props.user)),
      this.mapFromJson
    );

    this.state = {

      disabledDepart: false,
      showTooltip: false,
      clickedRowIndex: 0,
      Showhover: true,
      istab: false,
      movedtaskstatus: null
    };

    if (parse) {
      this.state = {
        name: null,
        previousSearchRequest: parse.previousSearchRequest,
        taskStatusFilter: parse.taskStatusFilter,
        filterStartDate: parse.filterStartDate,
        filterEndDate: parse.filterEndDate,
        filterDateType: parse.filterDateType,
        dateFilterEnabled: parse.dateFilterEnabled,
        tableSize: parse.tableSize,
        columnSizes: parse.columnSizes,
        allLocations: [],
        showFilterCollaps: false,
        currentLocationObj: {},
        enableYardCheck: parse.enableYardCheck,
        autoRefreshEnabled: true,
        isChecked: false,
        enableSaveChangedOnSite: false,
        currentTask: {},
        user: props.user,
        tableColumns: this.tableColumns,
        loaded: false,
        locationEnablerCheck: true,
        sealedFilter: parse.sealedFilter,
        reeferFilter: parse.reeferFilter,
        fuelFilter: parse.fuelFilter,
        continousFilter: parse.continousFilter,
        pageSizes: [5, 10, 20, 50, 200],
        disableExport: false,
        sealedFilterSealed: parse.sealedFilterSealed,

        selectedTask: {
          refData: {
            prNumber: null,
            prName: null,
            prAppointmentDate: null,
            prAppointmentWindow: null,
            prAppointmentType: null,
            prAppointmentPlan: null,
            prScac: null,
            prRefIdFlagDate: null,
            cancelled: null,
            prRefIdFlag: null,
            uuid: null,
            carrier: {},
            location: null,
            secondaryRef: "",
          },
        },
        flagData: {
          respondingSystem: null,
          reasonCode: null,
          Description: null,
          Datetime: null,
        },
      };
    } else {
      this.state = {
        name: null,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            { id: "taskLocationUuid", value: this.props.currentLocation.uuid },
          ],
        },
        showFilterCollaps: false,
        autoRefreshEnabled: true,
        // filterStartDate: startOfDay(new Date(),28),
        // filterEndDate: endOfDay(new Date()),
        // filterDateType: 'created',
        tableSize: { height: "500px", width: "500px" },
        itemsPerPage: 20,
        columnSizes: new Map(),
        dateFilterEnabled: true,
        allLocations: [],
        currentLocationObj: {},
        allUsers: [],
        enableYardCheck: false,
        isChecked: false,
        enableSaveChangedOnSite: false,
        currentTask: {},
        user: props.user,
        tableColumns: this.tableColumns,
        loaded: false,
        locationEnablerCheck: true,
        sealedFilter: "",
        sealedFilterSealed: "",
        reeferFilter: "",
        fuelFilter: "",
        continousFilter: "",
        pageSizes: [5, 10, 20, 50, 200],
        disableExport: false,
        selectedTask: {
          refData: {
            prNumber: null,
            prName: null,
            prAppointmentDate: null,
            prAppointmentWindow: null,
            prAppointmentType: null,
            prAppointmentPlan: null,
            prScac: null,
            prRefIdFlagDate: null,
            cancelled: null,
            prRefIdFlag: null,
            uuid: null,
            carrier: {},
            location: null,
            secondaryRef: "",
          },
        },
        flagData: {
          respondingSystem: null,
          reasonCode: null,
          Description: null,
          Datetime: null,
        },
      };
    }

    // let searchRequest = { "page": 0, "pageSize": 100, "sorted": [], "filtered": [] };
    // searchLocations(searchRequest,
    //   (response) => {
    //     this.setState({ allLocations: response.content });
    //     let locationobj = this.state.allLocations.find(it => it.uuid === this.props.currentLocation.uuid);
    //     this.setState({ currentLocationObj: locationobj });

    //   });
    // promiseUserByLocation(searchRequest,
    //   (response) => {

    //     let userArr = response.content;

    //     userArr.forEach(element => {
    //       element.assignee = element.firstname + " " + element.lastname;
    //       element.label = element.firstname + " " + element.lastname;

    //     });
    //     this.setState({ allUsers: userArr });
    //   });
    // if (!this.state.filterEndDate) {
    //     this.state.filterEndDate = endOfDay(new Date());
    // }
    // if (!this.state.filterStartDate) {
    //     this.state.filterStartDate = startOfDay(new Date(new Date().setDate(new Date().getDate() - 14)));
    // }
    // if (!this.state.filterDateType) {
    //     this.state.filterDateType = "created";
    // }
    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "500px" };
    }

    if (!this.state.columnSizes) {
      this.state.columnSizes = new Map();
    }
    if (
      this.props.currentLocation.enableArrivalTracking !== null
        ? this.props.currentLocation.enableArrivalTracking
        : false
    ) {
      let length = this.tableColumns.length;
      if (length != 0) {
        this.tableColumns = this.tableColumns;
        let item = {
          accessor: "arrivalTrackingId",
          Header: "Yard Arrival ID",
          default: true,
          show: true,
          filterable: true,
          sortable: true,

        };

        this.tableColumns.splice(length >= 5 ? 5 : 0, 0, item);
      }
      //For Assersorials
    }
    if (
      this.props.currentLocation.apiSetting !== null
        ? this.props.currentLocation.apiSetting.refernceApi
        : false
    ) {
      let length = this.tableColumns.length;
      if (length != 0) {
        this.tableColumns = this.tableColumns;
        let item = {
          accessor: "secondaryRef",
          Header: "Reference Number",
          default: true,
          filterable: true,
          sortable: false,
          show: true,
          cleanHeader: "Reference Number",
          //  Cell: row => <ReferencePopup isPopoverOpen={true} primaryReferenceNumber={row.original.primaryReferenceNumber} primaryRefId={row.original.primaryReferenceID}/>
          // Cell: (row) => {
          //   let v = row.original;
          //   // console.log("v", v);
          //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null ? "" : row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
          //     }`}</a>
          // }
          Cell: (row) => {
            if (
              row.value !== null &&
              row.value !== "" &&
              row.value !== undefined
            ) {
              let v = row.original;
              return row.value.split(",").map((elem, index) => (
                <a
                  href={"javascript:;"}
                  onClick={() => this.openRefModal(v, elem.trim())}
                >
                  {" "}
                  {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
                    }`} */}
                  {`${elem}`}
                  {row.value.split(",").length - 1 === index ? "" : ","} <br />
                </a>
              ));
            } else {
              return "N/A";
            }
          },
        };
        let item1 = {
          show: false,
          cleanHeader: "Alert",
          accessor: "statusFlag",
          Header: "Alert",
          default: false,
          filterable: false,
          sortable: false,
          Cell: (row) => {
            let taskObj = row.original;
            return (
              taskObj.statusFlag && (
                <div className="text-center">
                  {/* <i class="fas fa-flag text-danger c-pointer" ></i> */}
                  {/* <Tooltip placement="right" target={`ID_${v.taskUuid}`} isOpen={this.state.tooltipOpen} toggle={this.toggle}>
                {'System Response to this Task, click for details'}
              </Tooltip> */}
                  {taskObj.statusFlag && (
                    <IconWithTooltip
                      isToolTip={true}
                      isIcon={true}
                      isClickAble={true}
                      toolTipTxt={
                        "System Response to this Task, click for details"
                      }
                      classList={"fas fa-flag text-danger c-pointer"}
                      placement="top"
                      onClick={() => {
                        this.onClickIcon(taskObj);
                      }}
                    />
                  )}
                </div>
              )
            );
          },
        };
        this.tableColumns.splice(length >= 3 ? 3 : 0, 0, item, item1);
      }
      //For Assersorials
    }
    if (
      this.props.currentLocation.enableArrivalLog !== null
        ? this.props.currentLocation.enableArrivalLog
        : false
    ) {
      let length = this.tableColumns.length;
      if (length != 0) {
        this.tableColumns = this.tableColumns;
        let item0 = {
          accessor: "taskType",
          Header: "Arrival Task Type",
          default: true,
          filterable: true,
          sortable: true,
          show: true,
        }
        let item = {
          show: true,
          accessor: "arrivalTime",
          Header: "Asset Arrival",
          default: true,
          filterable: false,
          sortable: true,
          dataType: "date",
          operator: "GT",
          sortable: true,
          cleanHeader: "Asset Arrival",

          Cell: (row) => {
            let task = row.original;
            if (task.arrivalTime) {
              return (
                <MomentDisplay
                  date={task.arrivalTime}
                  timezone={
                    this.props.currentLocation &&
                    this.props.currentLocation.timezone
                  }
                />
              );
            } else {
              return null;
            }
          },
        }
        let item1 = {
          accessor: "dwellTime",
          Header: "Dwell Time",
          cleanHeader: "Dwell Time",
           default: true,
          filterable: false,
          sortable: true,
        }
        this.tableColumns.splice(length >= 13 ? 13 : 0, 0, item0, item, item1);
      }
      //For Assersorials
    }
    this.tableColumns = this.tableColumns.map((col) => {
      if (this.state.columnSizes.has(col.accessor)) {
        col.width = this.state.columnSizes.get(col.accessor);
      }
      return col;
    });
    // this.addOrRemoveTableColumn();
  }
  addOrArrivalTrackingColumn() {
    let s = this.tableColumns;

    let PriorityIndex = 0;
    let length = s.length;
    PriorityIndex = s.findIndex((it) => it.accessor === "arrivalTrackingId");


    let priorityObj = {
      accessor: "arrivalTrackingId",
      Header: "Yard Arrival ID",
      default: true,
      show: true,
      filterable: true,
      sortable: true,

    };

    if (this.props.currentLocation.enableArrivalTracking == true) {
      if (PriorityIndex === -1) {
        s.splice(length >= 5 ? 5 : 0, 0, priorityObj);
      }

    } else {
      if (PriorityIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = PriorityIndex;


        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }

    this.setState({ tableColumns: s });

    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }
  addOrRemoveDwellTableColumn() {
    let s = this.tableColumns;
    let typeIndex = 0;
    let dwellIndex = 0;
    let arrivalIndex = 0;
    let length = s.length;
    typeIndex = s.findIndex((it) => it.accessor === "taskType");
    arrivalIndex = s.findIndex((it) => it.accessor === "arrivalTime");
    dwellIndex = s.findIndex((it) => it.accessor === "dwellTime");
    let typeobj = {
      accessor: "taskType",
      Header: "Arrival Task Type",
      default: true,
      filterable: true,
      sortable: true,
      show: true,
    }
    let arrivalobj = {
      show: true,
      cleanHeader: "Asset Arrival",
      accessor: "arrivalTime",
      Header: "Asset Arrival",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.arrivalTime) {
          return (
            <MomentDisplay
              date={task.arrivalTime}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    }
    let dwellobj = {
      show: true,
      cleanHeader: "Dwell Time",
      accessor: "dwellTime",
      Header: "Dwell Time",
      default: true,
      filterable: false,
      sortable: true,
    }

    if (this.props.currentLocation.enableArrivalLog == true) {
      if (typeIndex === -1) {
        s.splice(length >= 12 ? 12 : 0, 0, typeobj);
      }
      if (arrivalIndex === -1) {
        s.splice(length >= 13 ? 13 : 0, 0, arrivalobj);
      }
      if (dwellIndex === -1) {
        s.splice(length >= 14 ? 14 : 0, 0, dwellobj);
      }

    } else {
      if (arrivalIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = typeIndex;
        removeIndexArr[1] = arrivalIndex;
        removeIndexArr[2] = dwellIndex;

        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }

    this.setState({ tableColumns: s });

    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }
  addOrRemoveTableColumn() {
    let s = this.tableColumns;
    let secRefIndex = 0;
    let statusFlagIndex = 0;
    let length = s.length;

    let isRefApiOn =
      this.props.currentLocation.apiSetting !== null
        ? this.props.currentLocation.apiSetting.refernceApi
        : false;
    let isDwellPiOn = this.props.currentLocation.enableArrivalLog !== null
      ? this.props.currentLocation.enableArrivalLog
      : false;
    secRefIndex = s.findIndex((it) => it.accessor === "secondaryRef");
    statusFlagIndex = s.findIndex((it) => it.accessor === "statusFlag");


    let secRefObj = {
      cleanHeader: "Reference Number",
      accessor: "secondaryRef",
      Header: "Reference Number",
      default: true,
      filterable: true,
      sortable: false,
      show: true,
      //  Cell: row => <ReferencePopup isPopoverOpen={true} primaryReferenceNumber={row.original.primaryReferenceNumber} primaryRefId={row.original.primaryReferenceID}/>
      // Cell: (row) => {
      //   let v = row.original;
      //   // console.log("v", v);
      //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null ? "" : row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
      //     }`}</a>
      // }
      Cell: (row) => {
        if (row.value !== null && row.value !== "" && row.value !== undefined) {
          let v = row.original;
          return row.value.split(",").map((elem, index) => (
            <a
              href={"javascript:;"}
              onClick={() => this.openRefModal(v, elem.trim())}
            >
              {" "}
              {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
                }`} */}
              {`${elem}`}
              {row.value.split(",").length - 1 === index ? "" : ","} <br />
            </a>
          ));
        } else {
          return "N/A";
        }
      },
    };
    let statusFlagObj = {
      show: true,
      cleanHeader: "Alert",
      accessor: "statusFlag",
      Header: "Alert",
      default: true,
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let taskObj = row.original;
        return (
          taskObj.statusFlag && (
            <div className="text-center">
              {/* <i class="fas fa-flag text-danger c-pointer" ></i> */}
              {/* <Tooltip placement="right" target={`ID_${v.taskUuid}`} isOpen={this.state.tooltipOpen} toggle={this.toggle}>
            {'System Response to this Task, click for details'}
          </Tooltip> */}
              {taskObj.statusFlag && (
                <IconWithTooltip
                  isToolTip={true}
                  isIcon={true}
                  isClickAble={true}
                  toolTipTxt={"System Response to this Task, click for details"}
                  classList={"fas fa-flag text-danger c-pointer"}
                  placement="top"
                  onClick={() => {
                    this.onClickIcon(taskObj);
                  }}
                />
              )}
            </div>
          )
        );
      },
    };

    if (isRefApiOn) {
      if (secRefIndex === -1) {
        s.splice(length >= 4 ? 4 : 0, 0, secRefObj);
      } else {
        s.splice(secRefIndex, 1);
      }
      if (statusFlagIndex === -1) {
        s.splice(length >= 5 ? 5 : 0, 0, statusFlagObj);
      } else {
        s.splice(statusFlagIndex, 1);
      }
    } else {
      if (secRefIndex > -1 && statusFlagIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = secRefIndex;
        removeIndexArr[1] = statusFlagIndex;

        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }

    this.setState({ tableColumns: s });
    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }

  onClickIcon(taskObj) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    this.getFlagDataByTakUuid(taskObj);
  }

  getFlagDataByTakUuid(taskObj) {
    let ref = {
      system: undefined,
      reasonCode: undefined,
      description: undefined,
      responseDate: new Date(),
      taskName: undefined,
    };
    clientResponseByTaskUuid(
      taskObj.taskUuid,
      (success) => {
        if (success.code === "200") {
          ref = success.content;
          this.setState({
            isFalRefModalOpen: true,
            flagData: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          this.setState({ loaded: false });
          showError(success.message);
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  // saveYardcheck(task) {
  //   this.setState({
  //     loaded: true,
  //   });

  //   saveTask(
  //     task,
  //     () => {

  //       showInfo("Yard Check saved.");

  //       this.setState({
  //         loaded: false,
  //         loaderMessage: "",
  //       });
  //     },
  //     () => {
  //       this.setState({
  //         loaded: false,
  //         loaderMessage: "",
  //       });
  //       showError("Unable to save Yard Check.");
  //     }
  //   );
  // }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.calculateTableSize.bind(this));
    this.calculateTableSize();

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    if (isTablet === true) {
      const datePickers = document.getElementsByClassName(
        "react-datepicker__input-container"
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute("readOnly", true)
      );
      this.setState({ istab: true });
    }



  }

  componentDidUpdate() {
    console.log("Here", this.state.autoRefreshEnabled);
    // checkLocationInterval();
    // let taskPopUpCheck = localStorage.getItem('taskPopUpCheck');
    // if (taskPopUpCheck === 'false') {
    //     if (this.props.user.roles[0].role !== "ROLE_POWERYARD_JOCKEY") {
    //         document.getElementById("checkLocation").click();
    //         localStorage.setItem('taskPopUpCheck', true);
    //     }
    //     if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY") {
    //         document.getElementById("checkShift").click();
    //         localStorage.setItem('taskPopUpCheck', true);
    //     }
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTableSize.bind(this));
    window.scrollTo(0, 0);
    localStorage.removeItem("onsiteArr");
    this.savePageState();
  }


  mapToJson(key, value) {
    let objectToSerialize = this[key];
    if (objectToSerialize instanceof Map) {
      return {
        dataType: "Map",
        values: Array.from(objectToSerialize.entries()),
      };
    } else {
      return value;
    }
  }
  mapFromJson(key, value) {
    if (typeof value === "object" && value !== null) {
      if (value.dataType === "Map") {
        return new Map(value.values);
      }
    }
    return value;
  }
  savePageState() {
    localStorage.setItem(
      getLocalStorageName("poweryard-onsite", this.props.user),
      JSON.stringify(
        {
          previousSearchRequest: this.state.previousSearchRequest,
          taskStatusFilter: this.state.taskStatusFilter,
          autoRefreshEnabled: this.state.autoRefreshEnabled,
          // filterStartDate: this.state.filterStartDate,
          // filterEndDate: this.state.filterEndDate,
          // filterDateType: this.state.filterDateType,
          showFilterCollaps: this.state.showFilterCollaps,
          dateFilterEnabled: this.state.dateFilterEnabled,
          columnSizes: this.state.columnSizes,
          enableYardCheck: this.state.enableYardCheck,
          sealedFilter: this.state.sealedFilter,
          sealedFilterSealed: this.state.sealedFilterSealed,
          reeferFilter: this.state.reeferFilter,
          fuelFilter: this.state.fuelFilter,
          continousFilter: this.state.continousFilter,
        },
        this.mapToJson
      )
    );
    let localStorageState = JSON.parse(
      localStorage.getItem(
        getLocalStorageName("poweryard-onsite-pft", this.props.user)
      )
    );
    if (localStorageState) {
      localStorageState.autoRefreshEnabled = this.state.autoRefreshEnabled;
      localStorage.setItem(
        getLocalStorageName("poweryard-onsite-pft", this.props.user),
        JSON.stringify(localStorageState)
      );
    } else {
      localStorageState = {
        autoRefreshEnabled: this.state.autoRefreshEnabled,
      };
      localStorage.setItem(
        getLocalStorageName("poweryard-onsite-pft", this.props.user),
        JSON.stringify(localStorageState)
      );
    }
  }
  calculateTableSize = () => {
    let size = getTableSize(
      "power-yard-onsite-page",
      "power-yard-onsite-header"
    );
    this.setState({ tableSize: { height: size.height, width: size.width } });
  };
  saveTaskYardCheckStatus() {
    let savingTasks = JSON.parse(localStorage.getItem("onsiteArr"));
    savingTasks = savingTasks.filter((it) => it.yardStatusCheck != false);
    saveYardCheckStatusToAllTasks(
      savingTasks,
      (response) => {
        console.log("True");
        this.tableRef.current.requestPageSearch();
        let s = this.state.tableColumns;
        s[0].show = false;

        // this.state.tableColumns = s;

        this.setState({ tableColumns: s, enableYardCheck: false });

        this.tableRef.current.refreshColumns();
        let struser = JSON.stringify(this.props.user);
        let user = JSON.parse(struser);
        user.onYardCheck = false;
        yardCheck(
          user,
          (res) => {
            this.props.setCurrentUser(res.content);
          },
          (err) => { }
        );

        this.setState({ loaded: false });
        showInfo("Yard Check Records Successfully Saved.");

      },
      (error) => {
        console.log("False");
      }
    );
  }
  render() {
    return (
      <>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        {this.state.flagData !== undefined && (
          <FlagModal
            flagData={this.state.flagData}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isFalRefModalOpen: false })}
            isRefModalOpen={this.state.isFalRefModalOpen}
          />
        )}
        <Loader show={this.state.loaded} message={this.spinner}>
          <div id="power-yard-onsite-page">
            <DepartTaskModal
              open={this.state.cancelModalOpen}
              previousProps={this.props}
              user={this.props.user}
              company={this.props.user.company}
              currentLocation={this.props.currentLocation}
              currentTask={this.state.currentTask}
              onSave={(reasonCode) => {
                showInfo("Depart in Progress");
                this.setState({ loaded: true, disabledDepart: true });
                this.updateSpinner("");
                postDepartAsset(
                  reasonCode,
                  (res) => {
                    if (res.code == "200") {
                      this.setState({ loaded: false, disabledDepart: false });
                      showInfo("Task Departed");
                      let uui = reasonCode.taskUuid;
                      let storedArrJson = localStorage.getItem("onsiteArr");
                      if (
                        storedArrJson !== "undefined" &&
                        storedArrJson !== undefined &&
                        storedArrJson !== null
                      ) {
                        let storedArr = JSON.parse(storedArrJson);
                        if (storedArr) {
                          let id = storedArr.findIndex(
                            (it) => it.taskUuid === uui
                          );
                          if (id > -1) {
                            storedArr.splice(id, 1);
                            localStorage.setItem(
                              "onsiteArr",
                              JSON.stringify(storedArr)
                            );
                          }
                        }
                      }
                      this.tableRef.current.requestPageSearch();
                      if (this.state.enableYardCheck) {

                        this.setState({ cancelModalOpen: false, autoRefreshEnabled: false });

                      }
                      else {
                        this.setState({ cancelModalOpen: false, autoRefreshEnabled: true });


                      }
                      /* dev-junaid */
                    }
                    else {
                      this.setState({ loaded: false, disabledDepart: false });
                      showError(res.message);

                    }
                  },
                  () => {
                    this.setState({ loaded: false, disabledDepart: false });
                  }
                );
              }}
              onCancel={() => {
                this.setState(prevState => ({
                  cancelModalOpen: false,
                  autoRefreshEnabled: !prevState.enableYardCheck
                }));
              }}            /* dev-junaid */
            />
            <EditTaskModal
              open={this.state.editModalOpen}
              previousProps={this.props}
              user={this.props.user}
              company={this.props.user.company}
              currentLocation={this.props.currentLocation}
              currentTask={this.state.currentTaskUnderEdit}
              onSave={(task) => {
                this.setState({ loaded: true });

                let uui = task.uuid;
                let storedArr = JSON.parse(localStorage.getItem("onsiteArr"));
                if (storedArr) {
                  let id = storedArr.findIndex((it) => it.taskUuid === uui);
                  if (id > -1) {
                    let taskToBeEdit = storedArr[id];
                    taskToBeEdit.spotType = task.moveTo.type;
                    taskToBeEdit.moveToZone = task.moveTo.zone;
                    taskToBeEdit.spotName = task.moveTo.name;
                    taskToBeEdit.trailerName = task.carrier.name;
                    taskToBeEdit.trailerNumber = task.asset.assetId;
                    // taskToBeEdit.assetStatus =
                    taskToBeEdit.assetType = task.asset.equipment.name;
                    taskToBeEdit.comments = task.comments;
                    taskToBeEdit.shipmentNumber = task.shipmentNumber;

                    storedArr[id] = taskToBeEdit;
                    // storedArr[id].yardStatusCheck = taskToBeEdit.yardStatusCheck;
                    // storedArr[id].taskUuid = task.uuid;
                    localStorage.setItem(
                      "onsiteArr",
                      JSON.stringify(storedArr)
                    );
                  }
                }
                this.tableRef.current.requestPageSearch();
                if (this.state.enableYardCheck) {

                  this.setState({ editModalOpen: false, autoRefreshEnabled: false });

                }
                else {
                  this.setState({ editModalOpen: false, autoRefreshEnabled: true });


                }
              }}
              /* dev-junaid */
              onCancel={() => {
                this.setState(prevState => ({
                  editModalOpen: false,
                  autoRefreshEnabled: !prevState.enableYardCheck
                }));
              }}
            /* dev-junaid */
            />
            <AuthenticationModal
              open={this.state.authenticationModal}
              previousProps={this.props}
              currentLocationObj={this.state.currentLocationObj}
              onSave={(reasonCode) => {
                this.setState({ loaded: true });
                this.updateSpinner("Saving.  Please wait.");



                this.saveTaskYardCheckStatus();
                localStorage.removeItem("onsiteArr");
                this.setState({ authenticationModal: false, autoRefreshEnabled: true });
              }}
              onCancel={() => this.setState({ authenticationModal: false })}
            />
            <AddAssetModal
              open={this.state.assetModal}
              previousProps={this.props}
              user={this.props.user}
              company={this.props.user.company}
              currentLocation={this.props.currentLocation}
              onSave={(reasonCode) => {
                this.tableRef.current.requestPageSearch();
                if (this.state.enableYardCheck) {
                  this.setState({ assetModal: false, autoRefreshEnabled: false });
                }
                else {
                  this.setState({ assetModal: false, autoRefreshEnabled: true });
                }

              }}
              onCancel={() => {
                this.tableRef.current.requestPageSearch();
                this.setState(prevState => ({
                  assetModal: false,
                  autoRefreshEnabled: !prevState.enableYardCheck
                }));
              }}

            />
            <div className="column-wrapper text-left">
              <div className="page-header" id="power-yard-onsite-header">
                <Row>
                  <Col sm={"7"}>
                    <h1>
                      On-Site &nbsp; &nbsp;
                      <Button
                        disabled={this.state.locationEnablerCheck === false}
                        onClick={() => this.setState({ assetModal: true, autoRefreshEnabled: false })}
                      >
                        Add Asset
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        disabled={
                          this.state.enableYardCheck === true ||
                          this.state.locationEnablerCheck === false
                        }
                        onClick={() => {
                          this.updateSpinner("Starting YardCheck ...");
                          this.setState({ loaded: true, autoRefreshEnabled: false });
                          let struser = JSON.stringify(this.props.user);
                          let user = JSON.parse(struser);
                          user.onYardCheck = true;
                          user.defaultLocation = this.props.currentLocation;
                          yardCheck(user, (res) => {
                            if (
                              res.message ===
                              "ONSITE_YARD_CHECK_ALREADY_ENABLED"
                            ) {
                              this.setState({ loaded: false, autoRefreshEnabled: true });
                              showInfo(
                                "Yardcheck already in progress by another user."
                              );
                            } else {
                              this.props.setCurrentUser(user);

                              let s = this.state.tableColumns;
                              s[0].show = true;

                              // this.savePageState();
                              // this.state.tableColumns = s;
                              this.setState({
                                enableSaveChangedOnSite: false,
                                loaded: false,
                                user: res.content,
                                tableColumns: s,
                                enableYardCheck: true,
                              });
                              let allTaskStorage = JSON.parse(
                                localStorage.getItem("onsiteArr")
                              );
                              let allTasks = [];
                              if (allTaskStorage) {
                                allTasks = allTaskStorage;
                              } else {
                                allTasks = this.state.allTasks;
                              }
                              allTasks.map(
                                (it) => (it.yardStatusCheck = false)
                              );
                              this.setState({
                                allTasks: allTasks,
                              });
                              localStorage.setItem(
                                "onsiteArr",
                                JSON.stringify(this.state.allTasks)
                              );
                              this.tableRef.current.refreshColumns();

                              //  for (let i = 0; i < this.state.allTasks.length; i++) {

                              //   let id = document.getElementById("checkbox"+this.state.allTasks[i].uuid );
                              //   id.checked = false;

                              //  }
                              //  this.tableRef.current.requestPageSearch();

                              showInfo("Yard check Started");
                            }
                          });
                        }}
                      >
                        Begin Yard Check

                      </Button>
                      {/* {this.state.allTasks && this.state.user.company.yardCheckActive === true && this.state.user.onYardCheck === true &&
                      <Button disabled={this.state.locationEnablerCheck === false || this.state.enableYardCheck === true || this.state.autoRefreshEnabled === true} onClick={() => {


                        this.updateSpinner("Starting YardCheck ...");
                        this.setState({ loaded: true });
                        let user = this.props.user;
                        user.onYardCheck = true;
                        postUser(user, (res) => {
                          let s = this.state.tableColumns;
                          s[0].show = true;

                          // this.savePageState();
                          // this.state.tableColumns = s;
                          this.setState({ loaded: false, user: res, tableColumns: s, enableYardCheck: true });
                          this.tableRef.current.refreshColumns();

                          showInfo("Yardcheck Started");


                        });

                      }

                      }>Yard Check</Button>
                    } */}
                      {/* {this.state.autoRefreshEnabled === true && (
                        <HelpIcon
                          helpMessage={
                            "In order to do YardCheck please turn off Last Refresh."
                          }
                        />
                      )} */}
                    </h1>
                  </Col>
                  <Col sm={"5"} className={"text-right"}>
                    <ChangeLocationFormField
                      yardCheckActive={this.state.enableYardCheck}
                      onChange={(location) => {
                        if (this.props.currentLocation.uuid !== location.uuid) {
                          this.props.setCurrentLocation(location);
                          if (location && location.uuid) {
                            this.setState({
                              locationEnablerCheck: true,
                            });
                            postUserDefaultLocation(
                              location.uuid,
                              (sucess) => {
                                this.addOrRemoveTableColumn();
                                this.addOrRemoveDwellTableColumn();
                                this.addOrArrivalTrackingColumn();
                                let onsiteArr = JSON.parse(localStorage.getItem("onsiteArr"));
                                onsiteArr = [];
                                console.log('Before clear: Here', localStorage.getItem("onsiteArr"));
                                localStorage.setItem("onsiteArr", JSON.stringify(onsiteArr));
                                console.log('After clear: Here', localStorage.getItem("onsiteArr"));
                                localStorage.removeItem("onsiteArr");
                                window.location.reload();

                              },
                              (err) => { }
                            );
                            // setTimeout(() => {
                            //   window.location.reload();
                            // }, 500);
                          }
                        }
                      }}
                      pageType={"onsite"}
                      currentLocation={this.props.currentLocation}
                    />
                    <Button
                      onClick={() => {
                        if (this.state.showFilterCollaps === true) {
                          this.setState({ showFilterCollaps: false });
                        } else {
                          this.setState({ showFilterCollaps: true });
                        }
                      }}
                    >
                      <i
                        className={
                          this.state.showFilterCollaps === true
                            ? "fa fa-chevron-up"
                            : "fa fa-chevron-down"
                        }
                      />
                    </Button>
                  </Col>
                  <Col className={"text-left"}>
                    <ChangeShiftFormField
                      onChange={(shift, location) => {
                        if (shift) {
                          if (this.props.currentShift !== shift) {
                            this.props.setCurrentShift(shift);
                            if (shift) {
                              let data = this.props.user;
                              data.shift = shift.value;
                              data.version = data.version + 1;
                              postUserDefaultShift(data);
                            }
                          }
                        }
                        if (location) {
                          if (
                            this.props.currentLocation.uuid !== location.uuid
                          ) {
                            this.props.setCurrentLocation(location);
                            if (location && location.uuid) {
                              postUserDefaultLocation(location.uuid);
                            }
                          }
                        }
                        let s = this.state.tableColumns;
                        s[0].show = false;
                        this.setState({
                          tableColumns: s,
                          enableYardCheck: false,
                        });
                        // this.savePageState();
                        // this.state.tableColumns = s;
                        this.tableRef.current.refreshColumns();
                      }}
                      currentShift={this.props.currentShift}
                      pageType={"onsite"}
                      currentLocation={this.props.currentLocation}
                    />
                  </Col>
                </Row>
              </div>
              {this.state.editDepartAllowance === false && (
                <Alert color="info">
                  <span>
                    <i
                      style={{ color: "grey" }}
                      className={"fa fa-question-circle mr-3"}
                    />
                    In order to <b>edit , depart</b> please turn off Last
                    Refresh.
                  </span>
                </Alert>
              )}
              <PaginationFilterTableV2
                hideTableForAccessDenied={this.state.locationEnablerCheck}
                openFiltersCollaps={this.state.showFilterCollaps}
                showEditColumns
                itemsPerPage={this.state.itemsPerPage}
                autoRefreshEnabled={this.state.autoRefreshEnabled}
                autoRefreshInterval={120000}
                disableAutoRefresh={this.state.enableYardCheck}
                disableAutoRefreshMsg={
                  "Auto refresh will be disabled during yardcheck."
                }
                setloaded={(check) => {
                  this.setState({ loaded: check });
                  this.updateSpinner("");
                }}
                onFilterHide={() => {
                  this.setState({
                    sealedFilterSealed: "undefined",
                    reeferFilter: "undefined",
                    fuelFilter: "undefined",
                    continousFilter: "undefined",
                  });
                }}
                RefreshReload={(res) => {
                  if (
                    res === true &&
                    this.props.user.onYardCheck === true &&
                    this.props.user.company.yardCheckActive === true
                  ) {
                    let s = this.state.tableColumns;
                    s[0].show = false;

                    // this.state.tableColumns = s;
                    this.setState({ tableColumns: s, enableYardCheck: false });

                    this.tableRef.current.refreshColumns();
                    let struser = JSON.stringify(this.props.user);
                    let user = JSON.parse(struser);
                    user.onYardCheck = false;
                    yardCheck(
                      user,
                      (res) => {
                        this.props.setCurrentUser(res.content);
                      },
                      (err) => { }
                    );
                  }
                  this.setState({ autoRefreshEnabled: res });
                }}
                user={this.props.user}
                specificPageSizes={this.state.pageSizes}
                style={{
                  maxHeight: this.state.tableSize.height,
                  maxWidth: this.state.tableSize.width,
                  minHeight: "120px",
                  // display: this.state.locationEnablerCheck === true ? "block" : "none"
                }}
                ref={this.tableRef}
                get={downloadNonNullMoveToTasksV2}
                successCallback={(response) => {
                  if (response.content.results.length === 0) {
                    this.setState({
                      disableExport: true,
                    });
                  } else {
                    this.setState({
                      disableExport: false,
                    });
                  }
                  if (
                    response.content.message &&
                    response.content.message === "ONSITE_YARD_CHECK_NOT_ENABLED"
                  ) {
                    this.setState({
                      locationEnablerCheck: false,
                    });
                  } else {
                    let prevArr = JSON.parse(localStorage.getItem("onsiteArr"));
                    const totalElements = response.content.totalElements;
                    if (prevArr) {
                      let resArr = response.content.results;
                      resArr.map((it) => (it.yardStatusCheck = false));
                      for (let z = 0; z < resArr.length; z++) {
                        let a = prevArr.findIndex(
                          (it) => it.taskUuid === resArr[z].taskUuid
                        );
                        if (a === -1) {
                          prevArr.push(resArr[z]);
                        }
                      }
                      localStorage.setItem(
                        "onsiteArr",
                        JSON.stringify(prevArr)
                      );
                      localStorage.setItem(
                        "totalElements",
                        JSON.stringify(totalElements)
                      );
                    } else {
                      let onsArr = response.content.results.map((it) => {
                        return { yardStatusCheck: false, ...it };
                      });
                      localStorage.setItem("onsiteArr", JSON.stringify(onsArr));
                      localStorage.setItem(
                        "totalElements",
                        JSON.stringify(totalElements)
                      );
                    }
                    if (this.state.allTasks) {
                      let prevArr = JSON.parse(
                        localStorage.getItem("onsiteArr")
                      );
                      let tempTasks = prevArr;
                      let resArr = response.content.results;
                      let request = response.content.request.filtered;
                      this.setState({ requestRes: request });
                      for (let index = 0; index < tempTasks.length; index++) {
                        let i = resArr.findIndex(
                          (r) => r.taskUuid === tempTasks[index].taskUuid
                        );
                        if (i > -1) {
                          resArr[i].yardStatusCheck =
                            tempTasks[index].yardStatusCheck;
                        }
                      }
                      for (let z = 0; z < resArr.length; z++) {
                        if (resArr[z].yardStatusCheck === false) {
                          let id = document.getElementById(
                            "checkbox" + resArr[z].taskUuid
                          );
                          id.checked = false;
                        } else {
                          let id = document.getElementById(
                            "checkbox" + resArr[z].taskUuid
                          );
                          id.checked = true;
                        }
                      }
                      if (request.length > 1) {
                        let filteredData = tempTasks;
                        for (let zid = request.length - 2; zid >= 0; zid--) {
                          let idToCatch = request[zid].id;
                          if (idToCatch === "spotType") {
                            filteredData = filteredData.filter((it) =>
                              it.spotType
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "moveToZone") {
                            filteredData = filteredData.filter((it) =>
                              it.moveToZone
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "spotName") {
                            filteredData = filteredData.filter((it) =>
                              it.spotName
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "trailerName") {
                            filteredData = filteredData.filter((it) =>
                              it.trailerName
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "trailerNumber") {
                            filteredData = filteredData.filter(
                              (it) =>
                                it.trailerNumber !== null &&
                                it.trailerNumber
                                  .toLowerCase()
                                  .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "assetStatus") {
                            filteredData = filteredData.filter(
                              (it) =>
                                it.trailerNumber !== null &&
                                it.assetStatus
                                  .toLowerCase()
                                  .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "assetType") {
                            filteredData = filteredData.filter((it) =>
                              it.assetType
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "sealed") {
                            filteredData = filteredData.filter((it) =>
                              it.sealed
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "sealNumber") {
                            filteredData = filteredData.filter((it) =>
                              it.sealNumber
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "secondaryRef") {
                            filteredData = filteredData.filter((it) =>
                              it.secondaryRef
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "createdBy") {
                            filteredData = filteredData.filter((it) =>
                              it.createdBy
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          } else if (idToCatch === "lastModifiedBy") {
                            filteredData = filteredData.filter((it) =>
                              it.lastModifiedBy
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          }

                          else if (idToCatch === "arrivalTrackingId") {
                            filteredData = filteredData.filter((it) =>
                              it.arrivalTrackingId
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          }
                          else if (idToCatch === "taskType") {
                            filteredData = filteredData.filter((it) =>
                              it.taskType
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          }
                          else if (idToCatch === "fuelLevel") {
                            filteredData = filteredData.filter((it) =>
                              it.fuelLevel
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          }
                          else if (idToCatch === "reefer") {
                            filteredData = filteredData.filter((it) =>
                              it.reefer
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          }
                          else if (idToCatch === "continous") {
                            filteredData = filteredData.filter((it) =>
                              it.continous
                                .toLowerCase()
                                .includes(request[zid].value.toLowerCase())
                            );
                          }
                          else {
                            filteredData = this.filterDataOnFilters(
                              idToCatch,
                              request[zid].value,
                              filteredData
                            );
                          }
                          // else {
                          //   filteredData  = filteredData.filter(it=> it[idToCatch].includes(request[zid].value));
                          // }
                        }
                        tempTasks = filteredData;
                        resArr = filteredData;
                      }
                      let falseCheckArr = resArr.filter(
                        (it) => it.yardStatusCheck === false
                      );
                      if (
                        falseCheckArr.length === 0 &&
                        tempTasks.length !== 0 &&
                        tempTasks.length === totalElements
                      ) {
                        if (
                          tempTasks.filter((it) => it.yardStatusCheck == false)
                            .length == 0
                        ) {
                          this.setState({
                            enableSaveChangedOnSite: true,
                          });
                        }
                      } else {
                        this.setState({
                          enableSaveChangedOnSite: false,
                        });
                      }
                      this.setState({
                        allTasks: resArr,
                        locationEnablerCheck: true,
                      });
                    } else {
                      this.setState({
                        allTasks: response.content.results,
                        locationEnablerCheck: true,
                      });
                    }
                  }
                  if (this.props.user && this.props.user.onYardCheck === true) {
                    // this.state.enableYardCheck = true;
                    let s = this.state.tableColumns;
                    s[0].show = true;

                    // this.state.tableColumns = s;
                    this.tableRef.current.refreshColumns();
                    this.setState({
                      tableColumns: s,
                      enableYardCheck: true,
                      autoRefreshEnabled: false,
                    });
                  } else {
                    let s = this.state.tableColumns;
                    s[0].show = false;

                    // this.state.tableColumns = s;
                    this.tableRef.current.refreshColumns();
                    this.setState({
                      tableColumns: s,
                      enableYardCheck: false,
                    });
                  }
                }}
                baseFilter={[
                  {
                    id: "taskLocationUuid",
                    value: this.props.currentLocation
                      ? this.props.currentLocation.uuid
                      : "null",
                  },
                ]}
                filtered={this.state.previousSearchRequest.filtered}
                onFilteredChange={(filter) => {
                  const delayFilters = ['taskStatus', 'fuelLevel', 'priority', 'boxedIn', 'sealed', 'reefer', 'continous'];
                  let psr = { ...this.state.previousSearchRequest };
                  psr.filtered = { ...this.state.filtered };
                  psr.filtered = filter;
                  const hasDelayFilter = psr.filtered.some(filter => delayFilters.includes(filter.id));
                  this.setState({ previousSearchRequest: psr }, () => {
                    if (hasDelayFilter) {
                      this.tableRef.current.requestPageSearch();
                    } else {
                      setTimeout(() => {
                        this.tableRef.current.requestPageSearch();
                      }, 3000); // 3000 milliseconds = 4 seconds
                    }
                  });

                }}
                clearFilter={(resetAll) => {
                  if (resetAll) {
                    let psr = { ...this.state.previousSearchRequest };
                    psr.filtered = [];
                    // this.onSiteFilterRef.current.value = undefined;
                    this.setState({
                      autoRefreshEnabled: true,
                      dateFilterEnabled: false,
                      previousSearchRequest: psr,
                      taskStatusFilter: undefined,
                    });
                  } else {
                    let psr = { ...this.state.previousSearchRequest };
                    psr.filtered = psr.filtered.filter(
                      (f) =>
                        f.id === "start" ||
                        f.id === "end" ||
                        f.id === "dateFilterField"
                    );
                    // this.onSiteFilterRef.current.value = undefined;
                    this.setState(
                      {
                        previousSearchRequest: psr,
                        taskStatusFilter: undefined,
                      },
                      () => {

                        if (this.state.hideFilters == false) {
                          this.updateSpinner("");
                          this.tableRef.current.requestPageSearch()
                        }
                      }
                    );
                  }
                }}
                setFilterToogle={(value) => {
                  this.setState({
                    hideFilters: value,

                  });
                }}
                defaultSorted={[]}
                stateStorage={"poweryard-onsite"}
                columns={this.state.tableColumns}
                onChange={(state) => {
                  this.setState({
                    previousSearchRequest: {
                      dateFilter: state.dateFilter,
                      itemsPerPage: state.itemsPerPage,
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    },
                  });
                }}
                onResizedChange={(customizedColumns, event) => {
                  let columnSizes = this.state.columnSizes
                    ? this.state.columnSizes
                    : new Map();
                  customizedColumns.forEach((col) => {
                    columnSizes.set(col.id, col.value);
                  });
                  this.setState({ columnSizes: columnSizes });
                }}
                showFooterSaveCloseButton={this.state.enableYardCheck}
                footerCancelButton={
                  <Button
                    color="danger"
                    onClick={() => {
                      // let s = this.state.tableColumns;
                      // s[2].show = false;
                      // this.setState({ tableColumns : s,enableYardCheck: false});
                      // // this.savePageState();
                      // // this.state.tableColumns = s;
                      // this.tableRef.current.refreshColumns();
                      confirmAlert({
                        title: "Confirmation",
                        message: `Are you sure you would like to cancel this yard check?`,
                        buttons: [
                          {
                            label: "Return",
                            className: "btn danger",
                            onClick: () => {

                              return;
                            },
                          },
                          {
                            label: "Proceed",
                            className: "btn btn-primary",
                            onClick: () => {
                              this.updateSpinner("Stopping YardCheck ...");
                              localStorage.removeItem("onsiteArr");
                              this.setState({ loaded: true });
                              let struser = JSON.stringify(this.props.user);
                              let user = JSON.parse(struser);
                              user.onYardCheck = false;
                              yardCheck(user, (res) => {
                                let s = this.state.tableColumns;
                                s[0].show = false;
                                this.props.setCurrentUser(user);
                                // this.savePageState();
                                // this.state.tableColumns = s;
                                this.setState({
                                  loaded: false,
                                  user: res.content,
                                  tableColumns: s,
                                  autoRefreshEnabled: true,
                                  enableYardCheck: false,
                                });
                                this.tableRef.current.refreshColumns();
                                var q = [...this.state.allTasks];
                                // for (let z = 0; z < q.length; z++) {
                                //   let id = document.getElementById("checkbox"+q[z].uuid );
                                //     id.checked = false;

                                // }
                                q.map((it) => (it.yardStatusCheck = false));
                                this.setState({
                                  allTasks: q,
                                });
                                this.savePageState();
                                showInfo("Yardcheck Cancelled");
                              });
                            },
                          },
                        ],

                      });


                    }}
                  >
                    {" "}
                    Cancel Check
                  </Button>
                }
                footerButton={
                  <Button
                    disabled={
                      this.state.enableYardCheck === true ||
                      this.state.disableExport === true
                    }
                    color="secondary"
                    onClick={() => this.exportYardHistory()}
                  >
                    <i className="fa fa-file-excel" /> Export to Excel
                  </Button>
                }
                footerSaveButton={
                  <Button
                    color="primary"
                    disabled={this.state.enableSaveChangedOnSite === false}
                    className={"float-right"}
                    onClick={() =>
                      //  this.saveTaskYardCheckStatus();

                      this.setState({
                        authenticationModal: true,
                      })
                    }
                  >
                    {" "}
                    Complete Check
                  </Button>
                }
              >
                {/* <TableDateRangePicker
                            ref={this.tableRef}
                            showTaskSelector={true}
                            start={this.state.filterStartDate}
                            end={this.state.filterEndDate}
                            dateType={this.state.filterDateType}
                            filterEnabled={this.state.dateFilterEnabled}
                            onDateFilterEnable={(enabled) => {
                                this.setState({ dateFilterEnabled: enabled });
                                if (enabled) {
                                    this.tableRef.current.setState({ page: 0, forcePage: 0 });
                                    this.updateProperty("start", this.state.filterStartDate);
                                    this.updateProperty("end", this.state.filterEndDate);
                                    this.updateProperty("dateFilterField", this.state.filterDateType);
                                } else {
                                    this.removeFilter("start");
                                    this.removeFilter("end");
                                    this.removeFilter("dateFilterField");
                                }
                                this.tableRef.current.requestPageSearch();
                            }}
                            onStartDateChange={(date) => {
                                this.updateProperty("start", date);
                                this.setState({ filterStartDate: date });
                                this.tableRef.current.requestPageSearch();
                            }}
                            onEndDateChange={(date) => {
                                this.updateProperty("end", date);
                                this.setState({ filterEndDate: date });
                                this.tableRef.current.requestPageSearch();
                            }}
                            onDateTypeChange={(val) => {
                                this.updateProperty("dateFilterField", val);
                                this.setState({ filterDateType: val });
                                this.tableRef.current.requestPageSearch();
                            }}
                        /> */}
              </PaginationFilterTableV2>

              {this.state.locationEnablerCheck === false && (
                <Alert color="danger">
                  <h4 className="alert-heading">Access Denied </h4>
                  <p>Page not enabled, contact your PowerYard Representative</p>
                </Alert>
              )}
            </div>
          </div>

          {/* {this.props.currentLocation.enableYardCheck === false &&
            <div id="power-yard-onsite-page">
                <Alert color="danger">
                        <h4 className="alert-heading">Access Denied </h4>
                        <p>
                        Page not enabled, contact your PowerYard Representative
                        </p>
                        
                    </Alert>
        
            </div>
    } */}
        </Loader>
      </>
    );
  }
  onClickActionButtons(Uuid) {
    let date;
    let spot;
    let status;
    let disable = false;
    getMoveTaskStatueByTaskuuid(Uuid,
      (success) => {
        date = success.content.moveDate;
        spot = success.content.movedToSpotName ? success.content.movedToZone + "-" + success.content.movedToSpotName : "  ";
        status = success.content.taskStatus;
        this.setState({ movedtaskstatus: status });
        if (success.content.taskStatus == "IsAssinged") {
          status = "Assinged";
        }
        if (success.content.taskStatus == "InProgress") {
          status = "In Progress";
        }

        console.log('SuccessAde:', success.content);
        if (status && !(status == "Complete" || status == "Canceled")) {
          confirmAlert({


            closeOnClickOutside: false,
            closeOnEscape: false,
            overlayClassName: "overlay-custom-class-name",
            customUI: ({ onClose }) => (
              <div className="custom-ui" style={{ background: '#ffffff', padding: '20px', display: 'flex', flexDirection: 'column', borderRadius: "5px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <h5>Task Info</h5>
                      </div>
                      <Button className="btn btn-primary close-button" onClick={onClose} style={{ marginLeft: 'auto' }}>
                        &times;
                      </Button>
                    </div>
                    <hr style={{ margin: '2px 0', border: '1px solid #ccc' }} />
                    <div style={{ background: '#f5f5f5', padding: '20px' }}>
                      <div>        Task Create Time:   <MomentDisplay date={date} timezone={this.props.currentLocation && this.props.currentLocation.timezone} />
                      </div>
                      <div>        Move To Spot:{spot}
                      </div>
                      <div>        Task Status: {status}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            ),


          });
        }
        else {

          disable = true;
        }
      },
      (failure) => {
        console.error('Failure:', failure);
      }
    );


  }
  removeFilter(id) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => {
      this.savePageState();
    });
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }

    if (!found) {
      if (id === "start") {
        filtered.push({ id: id, value: value, operator: "GT" });
      } else if (id === "end") {
        filtered.push({ id: id, value: value, operator: "LT" });
      } else if (id === "filterDateType") {
        filtered.push({ id: id, value: value, operator: "EQ" });
      } else {
        filtered.push({ id: id, value: value, operator: "EQ" });
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState(
      {
        previousSearchRequest: previousSearchRequest,
      },
      () => {
        this.savePageState();
      }
    );
  }
  filterDataOnFilters(property, val, arr) {
    let returningArr = [];
    for (let index = 0; index < arr.length; index++) {
      if (property === "comments") {
        if (arr[index].comments !== null) {
          if (arr[index].comments.toLowerCase().includes(val.toLowerCase())) {
            returningArr.push(arr[index]);
          }
        }
      }
      if (property === "shipmentNumber") {
        if (arr[index].shipmentNumber !== null) {
          if (
            arr[index].shipmentNumber.toLowerCase().includes(val.toLowerCase())
          ) {
            returningArr.push(arr[index]);
          }
        }
      }
    }
    return returningArr;
  }
  exportYardHistory() {

    if (this.state.previousSearchRequest) {
      let index = this.state.previousSearchRequest.filtered.findIndex(
        (it) => it.id === "taskLocationUuid"
      );
      if (index > -1) {
        this.state.previousSearchRequest.filtered[index].value = this.props
          .currentLocation
          ? this.props.currentLocation.uuid
          : "null";
      } else {
        this.state.previousSearchRequest.filtered.push({
          id: "taskLocationUuid",
          value: this.props.currentLocation
            ? this.props.currentLocation.uuid
            : "null",
        });
      }
      this.setState({ loaded: true });
      this.updateSpinner("Exporting...");
      requestOnSiteYardToExcel(
        this.state.previousSearchRequest,
        (res) => {

          let a = res.content.message;
          if (a && a.includes("s3.amazonaws.com")) {
            window.location.href = a;
          } else {
            showError(
              "We are not able to generate the excel file at this time.  Please try again later."
            );
          }
          this.setState({ loaded: false });

          // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
        },
        (e) => {
          console.log(e);
          showError(
            "We are not able to generate the excel file at this time.  Please try again later."
          );
        }
      );
    }
  }
  getProperty(field) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }
  }
}
Onsite.propTypes = {
  currentLocation: PropTypes.object,
  currentShift: PropTypes.any,
  position: PropTypes.object,
  user: PropTypes.object,
  setCurrentLocation: PropTypes.func,
  setCurrentShift: PropTypes.func,
  setCurrentUser: PropTypes.func,
};

export default withRouter(Onsite);
