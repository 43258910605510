import React, { Component } from "react";
import * as Yup from "yup";
import Modal from "reactstrap/es/Modal";
import {
    TabContent, TabPane, Nav, NavItem, NavLink,
    Button,
    Card,
    CardBody,
    Col,
    FormFeedback,
    Form,
    FormGroup,
    Label,
    Row,
    ModalBody,
    ModalHeader
} from "reactstrap";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import UploadModal from "./UploadImageModal";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import {
    postCreateGateArrivalRecord,
    postArrivalInfoByArrivald,
    getArrivalInfoByArrivald,
    getDataByArrivalID,
    cancelArrival,
    postSaveArrival,
    promiseEZTrackEquipmentTypeByPrefix,
    promiseSpotTypeByLocationUUidAzure,
    carriersByLocationUUid,
    getTaskTypeGate,
    getDepartureTrailerInfo,
    postArrivalTrailerData,
    postArrivalTrailerDataV2,
    FetchAllMatchingOnSiteTrailer,
    getDataOnCreateGate,

} from "../../NetworkHelpers";
import { getLocalStorageName } from '../../common/utilities';
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectFieldDepart from "../../common/formik-free/FFFormSelectFieldDepartTrailer"
import FFFormAsyncSelectFieldClear from "../../common/formik-free/FFFormAsyncSelectFieldCleareable"
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {
    getErrorsFromValidationError
} from "../utilities";
import Config from "../../Config";
import "react-image-lightbox/style.css";
import CancelArrival from "./CancelArrival";
import FFFormCheckboxField from "../formik-free/FFFormCheckboxField";
import FFFormAsyncSelectField from "../formik-free/FFFormAsyncSelectField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import FFFormTextAreaField from "../formik-free/FFFormTextAreaField";

class CreateGateModal extends Component {

    constructor(props) {
        super(props);
        let activeTabValue = '1';

        // Conditionally set activeTab based on this.props.btnStr

        this.state = {
            clearDepartPartner: false,
            trailerOptions: [],
            arrivalOptions: [],
            departureOptions: [],
            attachments: {},
            selectedArrival: {
                firstname: "",
                mobile: "",
                arrivalID: "",
            },
            selectedArrivalInfo: {
                arrivalstatus: "empty",
                departurestatus: "empty",
                eta: new Date().toISOString(), // This sets the eta to the current date and time in ISO 8601 format
                arrivaltasktype: "CHECK-IN ASSET (GATE)",
                departuretasktype: "CHECK-OUT ASSET (GATE)",

                arrivaltasktypename: "",
                departtasktypename: "",
            },
            singleSelectedArrivalInfo: {},
            activeTab: activeTabValue,
            updatePopupState: false,
            uploadModalOpen: false,
            uploadprops: {},
            errors: {},
            loaded: false,
            touched: {},
            isdisabled: false,
            carriers: [],

        };


    }
    componentDidMount() {
        if (this.props.open) {
            console.log("Modal is open on mount, calling fetchDataOnOpen");
            this.updateTaskTypes();
            this.fetchDataOnOpen();
        }
        getTaskTypeGate(
            this.props.currentLocation.uuid,
            this.props.user.company.uuid,
            ({ arrivalOptions, departureOptions }) => {
                console.log("Task types received:", { arrivalOptions, departureOptions });
                this.setState({ arrivalOptions, departureOptions });
            },
            (error) => {
                if (error) {
                    console.error(`Server error: ${error.message} (Status Code: ${error.statusCode})`);
                } else {
                    console.error("Error fetching task types:", error);
                }
            }
        );
        // getDataOnCreateGate(this.props.currentLocation.uuid, (res) => {
        //     if (res.statusCode === "200") {

        //         console.log(res, "adeemzaki");



        //     }

        // },
        //     (err) => {
        //         showError(err);
        //     }
        // );
    }

    fetchDataOnOpen() {
        const { uuid } = this.props.currentLocation; // Assuming currentLocation is passed as a prop

        getDataOnCreateGate(uuid, (res) => {
            if (res.statusCode === "200") {
                console.log(res, "Data fetched successfully");

                const departTaskInfo = res.data.departTaskInfo ? res.data.departTaskInfo[0] : {};
                const driverInfo = res.data.driverInfo ? res.data.driverInfo[0] : {};
                const arrivalTaskInfo = res.data.arrivalTaskInfo ? res.data.arrivalTaskInfo[0] : {};
                const attachments = {}
                if (driverInfo) {
                    if (driverInfo.firstnameControllMapping && driverInfo.firstnameControllMapping.attachmentEnabled) {
                        attachments.firstname = {
                            fieldName: 'firstname',
                            controllId: driverInfo.firstnameControllMapping.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (driverInfo.lastnameControllMapping && driverInfo.lastnameControllMapping.attachmentEnabled) {
                        attachments.lastname = {
                            fieldName: 'lastname',
                            controllId: driverInfo.lastnameControllMapping.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (driverInfo.trailernameControllMapping && driverInfo.trailernameControllMapping.attachmentEnabled) {
                        attachments.trailername = {
                            fieldName: 'trailername',
                            controllId: driverInfo.trailernameControllMapping.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (driverInfo.trucknumberControllMapping && driverInfo.trucknumberControllMapping.attachmentEnabled) {
                        attachments.trucknumber = {
                            fieldName: 'trucknumber',
                            controllId: driverInfo.trucknumberControllMapping.controllId,
                            attachmentEnabled: true
                        };
                    }
                    if (driverInfo.mobileControllMapping && driverInfo.mobileControllMapping.attachmentEnabled) {
                        attachments.mobile = {
                            fieldName: 'mobile',
                            controllId: driverInfo.mobileControllMapping.controllId,
                            attachmentEnabled: true
                        };
                    }


                }
                if (arrivalTaskInfo) {
                    // Check arrivaltrailernumber

                    if (arrivalTaskInfo.arrivaltrailernumber && arrivalTaskInfo.arrivaltrailernumber.attachmentEnabled) {
                        attachments.arrivaltrailernumber = {
                            fieldName: 'arrivaltrailernumber',
                            controllId: arrivalTaskInfo.arrivaltrailernumber.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (arrivalTaskInfo.arrivaltrailername && arrivalTaskInfo.arrivaltrailername.attachmentEnabled) {
                        attachments.arrivaltrailername = {
                            fieldName: 'arrivaltrailername',
                            controllId: arrivalTaskInfo.arrivaltrailername.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (arrivalTaskInfo.arrivalsealno && arrivalTaskInfo.arrivalsealno.attachmentEnabled) {
                        attachments.arrivalsealno = {
                            fieldName: 'arrivalsealno',
                            controllId: arrivalTaskInfo.arrivalsealno.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (arrivalTaskInfo.arrivalplan) {
                        attachments.arrivalplan = {
                            fieldName: 'arrivalplan',
                            controllId: 0,
                            attachmentEnabled: false
                        };
                    }

                    if (arrivalTaskInfo.arrivalcomment && arrivalTaskInfo.arrivalcomment.attachmentEnabled) {
                        attachments.arrivalcomment = {
                            fieldName: 'arrivalcomment',
                            controllId: arrivalTaskInfo.arrivalcomment.controllId,
                            attachmentEnabled: true
                        };
                    }
                    if (arrivalTaskInfo.arrivalshipmentnumber && arrivalTaskInfo.arrivalshipmentnumber.attachmentEnabled) {
                        attachments.arrivalshipmentnumber = {
                            fieldName: 'arrivalshipmentnumber',
                            controllId: arrivalTaskInfo.arrivalshipmentnumber.controllId,
                            attachmentEnabled: true
                        };
                    }


                    // Check other fields as needed...
                    if (departTaskInfo.departuretrailerno && departTaskInfo.departuretrailerno.attachmentEnabled) {
                        attachments.departuretrailerno = {
                            fieldName: 'departuretrailerno',
                            controllId: departTaskInfo.departuretrailerno.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (departTaskInfo.departuretrailername && departTaskInfo.departuretrailername.attachmentEnabled) {
                        attachments.departuretrailername = {
                            fieldName: 'departuretrailername',
                            controllId: departTaskInfo.departuretrailername.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (departTaskInfo.departuresealno && departTaskInfo.departuresealno.attachmentEnabled) {
                        attachments.departuresealno = {
                            fieldName: 'departuresealno',
                            controllId: departTaskInfo.departuresealno.controllId,
                            attachmentEnabled: true
                        };
                    }

                    if (departTaskInfo.departureplan) {
                        attachments.departureplan = {
                            fieldName: 'departureplan',
                            controllId: 0,
                            attachmentEnabled: false
                        };
                    }

                    if (departTaskInfo.departcomment && departTaskInfo.departcomment.attachmentEnabled) {
                        attachments.departcomment = {
                            fieldName: 'departcomment',
                            controllId: departTaskInfo.departcomment.controllId,
                            attachmentEnabled: true
                        };
                    }
                    if (departTaskInfo.departshipmentnumber && departTaskInfo.departshipmentnumber.attachmentEnabled) {
                        attachments.departshipmentnumber = {
                            fieldName: 'departshipmentnumber',
                            controllId: departTaskInfo.departshipmentnumber.controllId,
                            attachmentEnabled: true
                        };
                    }

                }
                console.log("attachment: ", attachments);
                this.setState({ attachments });
                // Handle successful response, update state, or render data
            }
        }, (err) => {
            this.showError(err);
        });
    }

    findDefaultValue = (options, defaultLabel) => {
        const option = options.find(option => option.label === defaultLabel);
        return option ? option.value : null;
    };
    updateTaskTypes = () => {
        const { arrivalOptions, departureOptions, selectedArrivalInfo } = this.state;

        const findDefaultValue = (options, defaultLabel) => {
            const option = options.find(option => option.label === defaultLabel);
            return option ? option.value : null;
        };

        const arrivaltasktypeOption = arrivalOptions.find(option => option.value === selectedArrivalInfo.arrivaltasktype);
        const departuretasktypeOption = departureOptions.find(option => option.value === selectedArrivalInfo.departuretasktype);

        const eta = new Date().toISOString();

        this.setState({
            selectedArrivalInfo: {
                ...selectedArrivalInfo,
                eta: eta,
                arrivalstatus: "empty",
                departurestatus: "empty",
                arrivaltasktype: arrivaltasktypeOption
                    ? arrivaltasktypeOption.value
                    : findDefaultValue(arrivalOptions, "CHECK-IN ASSET (GATE)"),
                arrivaltasktypename: arrivaltasktypeOption
                    ? arrivaltasktypeOption.label
                    : "CHECK-IN ASSET (GATE)",
                departuretasktype: departuretasktypeOption
                    ? departuretasktypeOption.value
                    : findDefaultValue(departureOptions, "CHECK-OUT ASSET (GATE)"),
                departtasktypename: departuretasktypeOption
                    ? departuretasktypeOption.label
                    : "CHECK-OUT ASSET (GATE)",
            },
        });
    };


    fetchArrivalTrailerInfo = (trailerUuid) => {
        this.setState({ loaded: true });
        this.updateSpinner("Loading...");

        const payload = {
            organizationUuid: this.props.user.company.uuid,
            arrivalTrailerName: trailerUuid,
        };

        postArrivalTrailerData(
            payload,
            (response) => {
                console.log("Response:", response);

                if (response && response.statusCode === "200") {
                    this.setState({ loaded: false });

                    const trailerDetails = response.data.trailerDetails ? JSON.parse(response.data.trailerDetails) : {};

                    const arrivalAssetType = trailerDetails.arrivalassettype || "";
                    const arrivalAssetTypeName = trailerDetails.arrivalassettypename || "";
                    const arrivalTrailerNumber = trailerDetails.arrivaltrailernumber || "";

                    this.setState((prevState) => ({
                        selectedArrivalInfo: {
                            ...prevState.selectedArrivalInfo,
                            departuretrailerno: this.state.selectedArrivalInfo.departureplan && this.state.selectedArrivalInfo.departureplan.toLowerCase() == "live" ? arrivalTrailerNumber : this.state.selectedArrivalInfo.departuretrailerno,
                            arrivalassettypename: arrivalAssetTypeName,
                            arrivalassettype: arrivalAssetType,
                            departassettypename: this.state.selectedArrivalInfo.departureplan && this.state.selectedArrivalInfo.departureplan.toLowerCase() == "live" ?   arrivalAssetTypeName:this.state.selectedArrivalInfo.departassettypename,
                            departassettype: this.state.selectedArrivalInfo.departureplan && this.state.selectedArrivalInfo.departureplan.toLowerCase() == "live" ?   arrivalAssetType:this.state.selectedArrivalInfo.departassettype,

                        },
                    }));

                    console.log("Updated selectedArrivalInfo:", this.state.selectedArrivalInfo);


                } else {
                    this.setState({ loaded: false });
                    console.error("API returned an invalid status:", response.statusCode);
                }
            },
            (error) => {
                this.setState({ loaded: false });
                console.error("API Failure Response:", error);
            }
        );
    };

    getAllMatchingOnSiteTrailer = (trailerNumber) => {
        const uuid = this.props.currentLocation ? this.props.currentLocation.uuid : null;
        this.setState({ loaded: true });
        this.updateSpinner("Loading...");

        FetchAllMatchingOnSiteTrailer(
            uuid,
            trailerNumber,
            (res) => {
                this.setState({ loaded: false });
                if (res.statusCode === "200") {
                    const trailerOptions = this.createDropdownOptionsV2(res.data);
                    this.setState({ trailerOptions });
                } else {
                    console.error("API returned an invalid status:", res.statusCode);
                }
            },
            (error) => {
                this.setState({ loaded: false });
                console.error("API Failure Response:", error);
            }
        );
    };

    fetchTrailerInfo = (locationUuid, trailerName) => {
        this.setState({ loaded: true });
        this.updateSpinner("Loading...");
        const payload = {
            locationUuid: locationUuid,
            trailerName: trailerName,
        };
        getDepartureTrailerInfo(
            payload,
            (response) => {
                console.log("adeem Response:", response);
                if (response && response.statusCode === "200") {
                    this.setState({ loaded: false });
                    const trailerOptions = this.createDropdownOptions(response.data);
                    this.setState({ trailerOptions });
                } else {
                    this.setState({ loaded: false });

                    console.error("API returned an invalid status:", response.statusCode);
                }
            },
            (error) => {
                this.setState({ loaded: false });

                console.error("API Failure Response:", error);
            }
        );
    }
    createDropdownOptions = (data) => {
        return data
            .filter(trailer => trailer.trailerNumber) // Filter out trailers with no trailerNumber
            .map((trailer) => {
                const { shipmentNumber, dwellTime, trailerNumber, assetType, sealNumber, assetTypeUuid, spotZoneName, spotUuid, spotName } = trailer;

                const optionLabel = this.state.selectedArrivalInfo.departurestatus && this.state.selectedArrivalInfo.departurestatus.toLowerCase() === "loaded"
                    ? `${trailerNumber}${shipmentNumber ? ' - ' + shipmentNumber : ' - 0'}`
                    : this.state.selectedArrivalInfo.departurestatus && this.state.selectedArrivalInfo.departurestatus.toLowerCase() === "empty"
                        && (trailerNumber && dwellTime)
                        ? `${trailerNumber} - ${dwellTime}`
                        : 'Unknown Trailer';  // Fallback label
                const departspotname = spotZoneName && spotName ? `${spotZoneName} - ${spotName}` : null;
                return {
                    label: optionLabel,
                    value: trailerNumber,
                    sealNumber: sealNumber,
                    assetType: assetType,
                    shipmentNumber: shipmentNumber,
                    assetTypeUuid: assetTypeUuid,
                    departspotname: departspotname,
                    spotUuid: spotUuid,


                };
            });
    }
    createDropdownOptionsV2 = (data) => {
        return data
            .filter(trailer => trailer.trailerName) // Filter out trailers with no trailerNumber
            .map((trailer) => {
                const { trailerName, carrierUuid, shipmentNumber, trailerNumber, assetType, sealNumber, assetTypeUuid, spotZoneName, spotName, spotUuid } = trailer;

                const optionLabel = `${trailerNumber} - ${trailerName ? '(' + trailerName + ')' : ''}`;
                const departspotname = spotZoneName && spotName ? `${spotZoneName} - ${spotName}` : null;


                return {
                    label: optionLabel,
                    value: trailerNumber,
                    trailerUuid: carrierUuid,
                    trailerName: trailerName,
                    sealNumber: sealNumber,
                    assetType: assetType,
                    shipmentNumber: shipmentNumber,
                    assetTypeUuid: assetTypeUuid,
                    spotZoneName: spotZoneName,
                    spotUuid: spotUuid,
                    departspotname: departspotname,
                    trailerName: trailerName,


                };
            });
    }





    componentDidUpdate(prevProps, prevState) {
        console.log("state update1 A", this.state.selectedArrivalInfo);

        // Existing functionality: checks if modal was just opened
        if (!prevProps.open && this.props.open) {
            console.log("Modal just opened, calling fetchDataOnOpen");
            this.fetchDataOnOpen();
            this.updateTaskTypes();

        }

        // Update submitDisable based on field values
        const shouldDisableSubmit = this.shouldDisableSubmit();

        if (shouldDisableSubmit !== prevState.submitDisable) {
            this.setState({ submitDisable: shouldDisableSubmit });
        }
    }

    shouldDisableSubmit = () => {
        const { selectedArrivalInfo } = this.state;

        // If departure status is bobtail, skip departure validation
        const isDepartureBobtail = selectedArrivalInfo.departurestatus === "bobtail";

        // If arrival status is bobtail, skip arrival validation
        const isArrivalBobtail = selectedArrivalInfo.arrivalstatus === "bobtail";

        // Departure fields validation (excluding bobtail check)
        const departureMissing =
            !isDepartureBobtail && (
                !selectedArrivalInfo.departurestatus ||
                !selectedArrivalInfo.departuretrailerno ||
                !selectedArrivalInfo.departuretrailername ||
                !selectedArrivalInfo.departspotname ||
                !selectedArrivalInfo.departassettypename |
                !selectedArrivalInfo.departureplan
            );

        // Arrival fields validation (excluding bobtail check)
        const arrivalMissing =
            !isArrivalBobtail && (
                !selectedArrivalInfo.arrivalstatus ||
                !selectedArrivalInfo.arrivalassettypename ||
                !selectedArrivalInfo.arrivaltrailernumber ||
                !selectedArrivalInfo.arrivaltrailername ||
                !selectedArrivalInfo.arrivalplan ||
                !selectedArrivalInfo.arrivalspotname
            );
        const driverMissing =
            !selectedArrivalInfo.firstname ||
            !selectedArrivalInfo.lastname ||
            !selectedArrivalInfo.mobile;
        // If either departure or arrival is missing values (and not bobtail), return true (disable submit)
        return departureMissing || arrivalMissing || driverMissing;
    };



    componentWillUpdate(previousProps, prevState, snapshot) {
        if (previousProps.open && this.state.updatePopupState === false && prevState.updatePopupState !== true) {
            this.setState({
                selectedArrival: previousProps.selectedArrival,
                updatePopupState: true,

                currentLocation: previousProps.currentLocation
            });


        }



    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, { abortEarly: false });
                return {};
            } catch (error) {
                return getErrorsFromValidationError(error);
            }
        };
    }
    validationSchema(values) {
        const NumberRegex = /^[0-9\b]+$/;

        let validationRules = {
            departpartner: Yup.string().nullable().required("Trailer Name is Required"),
            arrivaltrailername: Yup.string().nullable().required("Trailer Name is Required"),
            arrivaltrailernumber: Yup.string().nullable().required("Trailer Number is Required"),
            arrivalassettypename: Yup.string().nullable().required("Trailer Type is Required"),
            arrivalspotname: Yup.string().nullable().required("Spot is  Required"),
            departuretrailerno: Yup.string().nullable().required("Trailer Number is  Required"),
            departassettype: Yup.string().nullable().required("Trailer Type is Required"),
            departspot: Yup.string().nullable().required("Spot is  Required")
        };

        console.log(this.props.btnStr)

        // }

        return Yup.object().shape(validationRules);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    nextTab() {
        this.setState((prevState) => {
            const nextTab = (parseInt(prevState.activeTab) + 1).toString();
            return nextTab <= "3" ? { activeTab: nextTab } : prevState;
        });
    };
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    capitalizeFirstLetter = (string) => {
        if (!string) return '';
        const lowerCaseString = string.toLowerCase();
        return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    };
    handleSubmit = (e) => {
        let errors = this.validate(this.validationSchema.bind(this))(this.state.selectedArrivalInfo);
        let errorsIsEmpty =
            Object.keys(errors).length === 0 &&
            errors.constructor === Object;
        if (this.state.selectedArrivalInfo.arrivalassettypename && this.state.selectedArrivalInfo.arrivalpartner) {
            this.setState({ loaded: true });

            this.updateSpinner("Updating ...");


            this.onSubmit("save");
        }
        else {
            if (!errorsIsEmpty || this.state.errors.name) {
                let t = { ...this.state.touched };
                t.arrivalAssetType = true;
                this.setState({ touched: t, errors: errors });
                e.preventDefault();
                return;
            }
        }



    }



    render() {
        console.log(`Render`)
        return (
            <div>


                <UploadModal
                    user={this.props.user}
                    isnewtask={true}
                    uploadModalOpen={this.state.uploadModalOpen}
                    uploadprops={this.state.uploadprops}
                    organizationUuid={this.props.user.company.uuid}
                    arrivalID={this.state.selectedArrivalInfo.arrivalID}
                    onClose={() => this.setState({ uploadModalOpen: false })}

                />
                <Modal isOpen={this.props.open} size={"lg"}>
                    <Loader show={this.state.loaded} message={this.spinner}>
                        <ModalHeader>
                            <h1>Create Gate Task</h1>
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    // let s = { ...this.state.selectedArrivalInfo };
                                    // let errors = this.validate(this.validationSchema.bind(this))(
                                    //   this.state.selectedArrivalInfo
                                    // );
                                    // let closeCheck = this.state.closeCheck;
                                    // let errorsIsEmpty =
                                    //   Object.keys(errors).length === 0 &&
                                    //   errors.constructor === Object;
                                    // if (!errorsIsEmpty || this.state.errors.name) {
                                    //   let t = { ...this.state.touched };

                                    //   this.setState({ touched: t, errors: errors });
                                    //   e.preventDefault();
                                    //   return;
                                    // }

                                    //  this.setState({
                                    //   loaded: true,
                                    // });
                                    e.preventDefault();
                                }}
                            >
                                <Card className="simple-card">
                                    <CardBody>
                                        <div>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={this.state.activeTab === '1' ? "active" : ""}
                                                        onClick={() => { this.toggle('1'); }}
                                                    >
                                                        Driver Info
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={this.state.activeTab === '2' ? "active" : ""}
                                                        onClick={() => { this.toggle('2'); }}
                                                    >
                                                        Arrival
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={this.state.activeTab === '3' ? "active" : ""}
                                                        onClick={() => { this.toggle('3'); }}
                                                    >
                                                        Departure
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId="1">

                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <div style={{ flexGrow: 1 }}>
                                                            <FFFormTextField
                                                                required={true}
                                                                fieldname="firstname"
                                                                label="First Name"
                                                                // disabled={this.state.selectedArrival.arrivalstatus == "bobtail" ? true : false}
                                                                placeholder="Enter First Name"
                                                                icon="fa-user"
                                                                value={
                                                                    this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.firstname
                                                                        : ''
                                                                }

                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    // if (v) {
                                                                    s.firstname = v;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                        {this.state.attachments.firstname && (
                                                            <i
                                                                className="fa-solid fa-upload"
                                                                title="Upload"
                                                                style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    const { attachments } = this.state;

                                                                    this.setState({
                                                                        uploadModalOpen: true,
                                                                        uploadprops: {
                                                                            ...this.state.uploadprops,
                                                                            controllId: attachments.firstname.controllId,
                                                                            fieldName: attachments.firstname.name,
                                                                        },
                                                                    });
                                                                }}>

                                                            </i>
                                                        )}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <div style={{ flexGrow: 1 }}>
                                                            <FFFormTextField
                                                                fieldname="lastname"
                                                                label="Last Name"
                                                                required={true}

                                                                // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                                                                placeholder="Enter Last Name"
                                                                icon="fa-user"
                                                                value={
                                                                    this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.lastname
                                                                        : ''
                                                                }
                                                                // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                                                                // onBlur={() => {
                                                                //   let errors = this.validate(
                                                                //     this.validationSchema.bind(this)
                                                                //   )(this.state.selectedArrivalInfo);
                                                                //   let touched = { ...this.state.touched };
                                                                //   touched.arrivaltrailernumber = true;
                                                                //   this.setState({ errors: errors, touched: touched });
                                                                // }}
                                                                // error={this.state.errors.arrivaltrailernumber}
                                                                // touched={this.state.touched.arrivaltrailernumber}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    // if (v) {
                                                                    s.lastname = v;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                        {this.state.attachments.lastname && (
                                                            <i
                                                                className="fa-solid fa-upload"
                                                                title="Upload"
                                                                style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    const { attachments } = this.state;

                                                                    this.setState({
                                                                        uploadModalOpen: true,
                                                                        uploadprops: {
                                                                            ...this.state.uploadprops,
                                                                            controllId: attachments.lastname.controllId,
                                                                            fieldName: attachments.lastname.name,
                                                                        },
                                                                    });
                                                                }}>

                                                            </i>
                                                        )}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <div style={{ flexGrow: 1 }}>
                                                            <FFFormTextField
                                                                fieldname="trailername"
                                                                label="Driver Carrier Name"
                                                                // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                                                                placeholder="Enter Carrier Name"
                                                                icon="fa-keynote"
                                                                value={
                                                                    this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.trailername
                                                                        : ''
                                                                }
                                                                // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                                                                // onBlur={() => {
                                                                //   let errors = this.validate(
                                                                //     this.validationSchema.bind(this)
                                                                //   )(this.state.selectedArrivalInfo);
                                                                //   let touched = { ...this.state.touched };
                                                                //   touched.arrivaltrailernumber = true;
                                                                //   this.setState({ errors: errors, touched: touched });
                                                                // }}
                                                                // error={this.state.errors.arrivaltrailernumber}
                                                                // touched={this.state.touched.arrivaltrailernumber}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    // if (v) {
                                                                    s.trailername = v;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                        {this.state.attachments.trailername && (
                                                            <i
                                                                className="fa-solid fa-upload"
                                                                title="Upload"
                                                                style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    const { attachments } = this.state;

                                                                    this.setState({
                                                                        uploadModalOpen: true,
                                                                        uploadprops: {
                                                                            ...this.state.uploadprops,
                                                                            controllId: attachments.trailername.controllId,
                                                                            fieldName: attachments.trailername.name,
                                                                        },
                                                                    });
                                                                }}>

                                                            </i>
                                                        )}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <div style={{ flexGrow: 1 }}>
                                                            <FFFormTextField
                                                                fieldname="trucknumber"
                                                                label="Truck#"
                                                                // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                                                                placeholder="Enter Truck Number"
                                                                icon="fa-truck"
                                                                value={
                                                                    this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.trucknumber
                                                                        : ''
                                                                }
                                                                // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                                                                // onBlur={() => {
                                                                //   let errors = this.validate(
                                                                //     this.validationSchema.bind(this)
                                                                //   )(this.state.selectedArrivalInfo);
                                                                //   let touched = { ...this.state.touched };
                                                                //   touched.arrivaltrailernumber = true;
                                                                //   this.setState({ errors: errors, touched: touched });
                                                                // }}
                                                                // error={this.state.errors.arrivaltrailernumber}
                                                                // touched={this.state.touched.arrivaltrailernumber}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    // if (v) {
                                                                    s.trucknumber = v;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                        {this.state.attachments.trucknumber && (
                                                            <i
                                                                className="fa-solid fa-upload"
                                                                title="Upload"
                                                                style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    const { attachments } = this.state;

                                                                    this.setState({
                                                                        uploadModalOpen: true,
                                                                        uploadprops: {
                                                                            ...this.state.uploadprops,
                                                                            controllId: attachments.trucknumber.controllId,
                                                                            fieldName: attachments.trucknumber.name,
                                                                        },
                                                                    });
                                                                }}>

                                                            </i>
                                                        )}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <div style={{ flexGrow: 1 }}>

                                                            <FFFormTextField
                                                                fieldname="mobile"
                                                                required={true}

                                                                label="Driver Phone Number"
                                                                // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                                                                placeholder="Enter Phone Number"
                                                                icon="fa-keynote"
                                                                value={
                                                                    this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.mobile
                                                                        : ''
                                                                }
                                                                // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                                                                // onBlur={() => {
                                                                //   let errors = this.validate(
                                                                //     this.validationSchema.bind(this)
                                                                //   )(this.state.selectedArrivalInfo);
                                                                //   let touched = { ...this.state.touched };
                                                                //   touched.arrivaltrailernumber = true;
                                                                //   this.setState({ errors: errors, touched: touched });
                                                                // }}
                                                                // error={this.state.errors.arrivaltrailernumber}
                                                                // touched={this.state.touched.arrivaltrailernumber}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    // if (v) {
                                                                    s.mobile = v;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                        {this.state.attachments.mobile && (
                                                            <i
                                                                className="fa-solid fa-upload"
                                                                title="Upload"
                                                                style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    const { attachments } = this.state;

                                                                    this.setState({
                                                                        uploadModalOpen: true,
                                                                        uploadprops: {
                                                                            ...this.state.uploadprops,
                                                                            controllId: attachments.mobile.controllId,
                                                                            fieldName: attachments.mobile.name,
                                                                        },
                                                                    });
                                                                }}>

                                                            </i>
                                                        )}
                                                    </div>

                                                    <FormGroup>
                                                        <Row>
                                                            <Col lg="2" md="3" sm="4">
                                                                <Label>
                                                                    {"ETA"}
                                                                </Label>
                                                            </Col>
                                                            <Col className={"task-form"} lg="10" md="9" sm="8">
                                                                <DatePicker

                                                                    placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                                                                    className={
                                                                        this.state.errors &&
                                                                        this.state.errors.eta &&
                                                                        "is-invalid"
                                                                    }
                                                                    selected={this.state.selectedArrivalInfo.eta}
                                                                    onChange={(date) => {
                                                                        let s = { ...this.state.selectedArrivalInfo };
                                                                        s.eta = date;

                                                                        this.setState({ selectedArrivalInfo: s });

                                                                    }}
                                                                    timeInputLabel="Time:"
                                                                    dateFormat="MM/dd/yyyy h:mm:ss a"
                                                                    showTimeInput
                                                                    shouldCloseOnSelect={false}
                                                                />
                                                                <FormFeedback
                                                                    className={"d-block"}
                                                                    style={{ marginLeft: 0 }}
                                                                >
                                                                    {this.state.errors &&
                                                                        this.state.errors.eta}
                                                                </FormFeedback>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <h3>Arrival / Inbound</h3>
                                                    <FFFormSelectField
                                                        fieldname="arrivalstatus"
                                                        label="Trailer Status"// trailer status
                                                        disabled={
                                                            this.props.btnStr == 'checkout' ? true : false}
                                                        placeholder="Select Trailer Status"
                                                        icon="fa-truck"
                                                        value={
                                                            this.state.selectedArrivalInfo.arrivalstatus
                                                                ? this.capitalizeFirstLetter(this.state.selectedArrivalInfo.arrivalstatus)
                                                                : ""
                                                        }
                                                        required={true}
                                                        onBlur={() => {
                                                            let errors = this.validate(
                                                                this.validationSchema.bind(this)
                                                            )(this.state.selectedArrivalInfo);
                                                            let touched = { ...this.state.touched };
                                                            touched.arrivalstatus = true;
                                                            this.setState({ errors: errors, touched: touched });
                                                        }}
                                                        options={["Loaded", "Empty", "Bobtail"]}

                                                        error={this.state.errors.arrivalstatus}
                                                        touched={this.state.touched.arrivalstatus}
                                                        onChange={(v) => {
                                                            let s = { ...this.state.selectedArrivalInfo };
                                                            let value = v.value.toLowerCase();
                                                            if (value == "bobtail") {
                                                                s.departureplan = "hook";
                                                                s.arrivalplan = "";
                                                                s.arrivaltrailernumber = "";
                                                                s.arrivaltrailername = "";
                                                                s.arrivalstatus = "";
                                                                s.arrivalsealno = "";
                                                                s.arrivaltasktype = "";
                                                                s.arrivaltasktypename = "";
                                                                s.arrivalpartner = "";
                                                                s.arrivalPartner = "";
                                                                s.arrivalspotname = "";
                                                                s.arrivalassettypename = "";
                                                                s.arrivalspot = "";
                                                                s.arrivalassettype = "";
                                                                s.arrivalshipmentnumber = "";
                                                                s.arrivalcomment = "";


                                                            }
                                                            s.arrivalstatus = value;
                                                            this.setState({ selectedArrivalInfo: s });
                                                        }}
                                                    />


                                                    {this.state.selectedArrivalInfo.arrivalstatus !== "bobtail" && (

                                                        <>
                                                            <FFFormSelectField
                                                                defaultOptions={true}
                                                                fieldname="arrivalplan"
                                                                label="Plan Type"//plan type
                                                                icon="fa-warehouse"
                                                                value={this.capitalizeFirstLetter(this.state.selectedArrivalInfo.arrivalplan)}
                                                                required={true}
                                                                isClearable={false}
                                                                options={["Live", "Drop"]}
                                                                placeholder="Select the Plan Type"
                                                                hideEmpty={true}
                                                                isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                                                onBlur={() => {
                                                                    let errors = this.validate(
                                                                        this.validationSchema.bind(this)
                                                                    )(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.arrivalplan = true;
                                                                    this.setState({ errors: errors, touched: touched });
                                                                }}
                                                                error={this.state.errors.arrivalplan}
                                                                touched={this.state.touched.arrivalplan}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    let value = v.value.toLowerCase();
                                                                    if (value == "live") {
                                                                        s.departureplan = "live"
                                                                        s.departuretrailername = s.arrivaltrailername
                                                                        s.departuretrailerno = s.arrivaltrailernumber
                                                                        s.departassettype = s.arrivalassettype;
                                                                        s.departassettypename = s.arrivalassettypename;
                                                                    }
                                                                    else if (value == "drop") {
                                                                        s.departureplan = "hook"
                                                                        s.departuretrailerno = ""
                                                                        s.departuretrailername = ""
                                                                        s.departuretrailernoLable = ""


                                                                    }
                                                                    s.arrivalplan = value;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }
                                                                }
                                                            />
                                                            <FFFormSelectField
                                                                defaultOptions={true}
                                                                fieldname="arrivaltasktype"
                                                                label="Task Type"
                                                                icon="fa-warehouse"
                                                                value={
                                                                    this.state.selectedArrivalInfo.arrivaltasktype
                                                                        ? this.state.selectedArrivalInfo.arrivaltasktypename
                                                                        : "CHECK-IN ASSET (GATE)"
                                                                }
                                                                required={true}
                                                                isClearable={false}
                                                                options={this.state.arrivalOptions}
                                                                placeholder="Select the Task Type"
                                                                hideEmpty={true}
                                                                onBlur={() => {
                                                                    let errors = this.validate(this.validationSchema.bind(this))(
                                                                        this.state.selectedArrivalInfo
                                                                    );
                                                                    let touched = { ...this.state.touched };
                                                                    touched.arrivaltasktype = true;
                                                                    this.setState({ errors, touched });
                                                                }}
                                                                error={this.state.errors.arrivaltasktype}
                                                                touched={this.state.touched.arrivaltasktype}
                                                                onChange={(selectedOption) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    s.arrivaltasktype = selectedOption.value;
                                                                    s.arrivaltasktypename = selectedOption.label;

                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }}
                                                            />

                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormAsyncSelectField
                                                                        defaultOptions={true}
                                                                        fieldname="arrivaltrailername"
                                                                        label="Trailer Name"
                                                                        icon="fa-truck"
                                                                        value={this.state.selectedArrivalInfo.arrivaltrailername}
                                                                        required={true}
                                                                        isClearable={false}
                                                                        placeholder="Enter Trailer Name"
                                                                        hideEmpty={true}
                                                                        isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                                                        loadOptions={carriersByLocationUUid(this.props.currentLocation.uuid)}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(this.validationSchema.bind(this))(
                                                                                this.state.selectedArrivalInfo
                                                                            );
                                                                            let touched = { ...this.state.touched };
                                                                            touched.arrivaltrailername = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.arrivaltrailername}
                                                                        touched={this.state.touched.arrivaltrailername}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };

                                                                            if (v) {
                                                                                s.arrivalPartner = v.uuid;
                                                                                s.arrivaltrailername = v.name;
                                                                                this.fetchArrivalTrailerInfo(s.arrivalPartner);
                                                                                if (s.departureplan && s.departureplan == "live") {
                                                                                    s.departuretrailername = s.arrivaltrailername
                                                                                }


                                                                            }

                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.arrivaltrailername && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.arrivaltrailername.controllId,
                                                                                    fieldName: attachments.arrivaltrailername.name,
                                                                                },
                                                                            });
                                                                        }}>

                                                                    </i>
                                                                )}
                                                            </div>

                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="arrivaltrailernumber"
                                                                        label="Trailer Number"
                                                                        disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                                                            this.props.btnStr == 'checkout' ? true : false}
                                                                        placeholder="Enter Trailer Number"
                                                                        icon="fa-truck"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.arrivaltrailernumber && this.state.selectedArrivalInfo.arrivaltrailernumber != "null"
                                                                                ? this.state.selectedArrivalInfo.arrivaltrailernumber
                                                                                : ""
                                                                        }
                                                                        required={true}

                                                                        isClearable={true}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.arrivaltrailernumber = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.arrivaltrailernumber}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            // if (v) {

                                                                            let trimmedValue = v;
                                                                            if (v.startsWith(' ')) {
                                                                                trimmedValue = v.trimStart();


                                                                            }
                                                                            s.arrivaltrailernumber = trimmedValue;
                                                                            if (s.departureplan && s.departureplan == "live") {
                                                                                s.departuretrailerno = s.arrivaltrailernumber

                                                                            }
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                            // }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.arrivaltrailernumber && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.arrivaltrailernumber.controllId,
                                                                                    fieldName: attachments.arrivaltrailernumber.fieldname,
                                                                                },
                                                                            });
                                                                        }}
                                                                    ></i>
                                                                )}
                                                            </div>
                                                            <FFFormAsyncSelectField
                                                                defaultOptions={true}
                                                                fieldname="arrivalassettypename"
                                                                label="Trailer Type"
                                                                icon="fa-truck"
                                                                value={this.state.selectedArrivalInfo.arrivalassettypename}
                                                                required={this.props.btnStr != 'Save & Arrive' || this.state.selectedArrivalInfo.arrivalplan == "Bobtail" ? false : true}

                                                                isClearable={false}
                                                                placeholder="Select the Trailer Type"
                                                                hideEmpty={true}
                                                                isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                                                loadOptions={promiseEZTrackEquipmentTypeByPrefix(
                                                                    this.props.currentLocation.uuid
                                                                )}
                                                                onBlur={() => {
                                                                    let errors = this.validate(
                                                                        this.validationSchema.bind(this)
                                                                    )(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.arrivalassettype = true;
                                                                    this.setState({ errors: errors, touched: touched });
                                                                }}
                                                                error={this.state.errors.arrivalassettype}
                                                                touched={this.state.touched.arrivalassettype}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    let t = { ...this.state.touched };

                                                                    if (v) {
                                                                        t.arrivalassettype = true;
                                                                        s.arrivalassettype = v.uuid;
                                                                        s.arrivalassettypename = v.name;
                                                                        if (s.arrivalplan == "live") {
                                                                            s.departassettype = s.arrivalassettype;
                                                                            s.departassettypename = s.arrivalassettypename;
                                                                        }
                                                                    }

                                                                    this.setState({ selectedArrivalInfo: s, touched: t });
                                                                }
                                                                }
                                                            />

                                                            <FFFormAsyncSelectField
                                                                defaultOptions={true}
                                                                fieldname="arrivalspotname"
                                                                label="Move To Spot Spot-Number"
                                                                icon="fa-spot"

                                                                value={this.state.selectedArrivalInfo.arrivalspotname}
                                                                required={true}

                                                                isClearable={false}
                                                                placeholder="Select the Spot"
                                                                hideEmpty={true}
                                                                isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                                                loadOptions={promiseSpotTypeByLocationUUidAzure(
                                                                    this.props.currentLocation.uuid
                                                                )}
                                                                onBlur={() => {
                                                                    let errors = this.validate(
                                                                        this.validationSchema.bind(this)
                                                                    )(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.arrivalspotname = true;
                                                                    this.setState({ errors: errors, touched: touched });
                                                                }}
                                                                error={this.state.errors.arrivalspotname}
                                                                touched={this.state.touched.arrivalspotname}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };

                                                                    if (v) {
                                                                        s.arrivalspot = v.uuid;
                                                                        s.arrivalspotname = v.name;
                                                                    }

                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }
                                                                }
                                                            />


                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="arrivalsealno"
                                                                        label="Seal Number"
                                                                        disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                                                            this.props.btnStr == 'checkout' ? true : false}
                                                                        placeholder="Enter Seal Number"
                                                                        icon="fa-tag"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.arrivalsealno && this.state.selectedArrivalInfo.arrivalsealno != "null"
                                                                                ? this.state.selectedArrivalInfo.arrivalsealno
                                                                                : ""
                                                                        }
                                                                        required={false}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.arrivalsealno = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.arrivalsealno}
                                                                        touched={this.state.touched.arrivalsealno}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            s.arrivalsealno = v;
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.arrivalsealno && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.arrivalsealno.controllId,
                                                                                    fieldName: attachments.arrivalsealno.fieldName,
                                                                                },
                                                                            });
                                                                        }}
                                                                    ></i>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="arrivalshipmentnumber"
                                                                        label="Arrival Shipment Number"
                                                                        disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                                                            this.props.btnStr == 'checkout' ? true : false}
                                                                        placeholder="Enter Shipment Number"
                                                                        icon="fa-tag"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.arrivalshipmentnumber && this.state.selectedArrivalInfo.arrivalshipmentnumber !== "null"

                                                                                ? this.state.selectedArrivalInfo.arrivalshipmentnumber
                                                                                : ""
                                                                        }
                                                                        required={false}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.arrivalshipmentnumber = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.arrivalshipmentnumber}
                                                                        touched={this.state.touched.arrivalshipmentnumber}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            s.arrivalshipmentnumber = v;
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.arrivalshipmentnumber && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.arrivalshipmentnumber.controllId,
                                                                                    fieldName: attachments.arrivalshipmentnumber.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                  ></i>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="arrivalcomment"
                                                                        label="Comments"
                                                                        disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                                                            this.props.btnStr == 'checkout' ? true : false}
                                                                        placeholder="Enter Comments..."
                                                                        icon="fa-tag"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.arrivalcomment && this.state.selectedArrivalInfo.arrivalcomment != "null"

                                                                                ? this.state.selectedArrivalInfo.arrivalcomment
                                                                                : ""
                                                                        }
                                                                        required={false}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.arrivalcomment = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.arrivalcomment}
                                                                        touched={this.state.touched.arrivalcomment}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            s.arrivalcomment = v;
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.arrivalcomment && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.arrivalcomment.controllId,
                                                                                    fieldName: attachments.arrivalcomment.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                             ></i>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <h3>Departure / Outbound</h3>
                                                    <FFFormSelectField
                                                        fieldname="departurestatus"
                                                        label="Trailer Status"
                                                        //disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                                        placeholder="Select Trailer Status"
                                                        options={["Loaded", "Empty", "Bobtail"]}
                                                        icon="fa-truck"
                                                        value={
                                                            this.state.selectedArrivalInfo.departurestatus
                                                                ? this.capitalizeFirstLetter(this.state.selectedArrivalInfo.departurestatus)
                                                                : ""
                                                        }

                                                        required={true}
                                                        onBlur={() => {
                                                            let errors = this.validate(
                                                                this.validationSchema.bind(this)
                                                            )(this.state.selectedArrivalInfo);
                                                            let touched = { ...this.state.touched };
                                                            touched.departurestatus = true;
                                                            this.setState({ errors: errors, touched: touched });
                                                        }}
                                                        error={this.state.errors.departurestatus}
                                                        touched={this.state.touched.departurestatus}
                                                        onChange={(v) => {
                                                            let s = { ...this.state.selectedArrivalInfo };
                                                            let value = v.value.toLowerCase();
                                                            if (value == "bobtail") {
                                                                s.departuretrailernoLable = "";
                                                                s.departuretrailername = "";
                                                                s.departuretrailerno = "";
                                                                s.departpartner = "";
                                                                s.departurestatus = "";
                                                                s.departuresealno = "";
                                                                s.departspotname = "";
                                                                s.departassettypename = "";
                                                                s.departspot = "";
                                                                s.departassettype = "";
                                                                s.departuretasktype = "";
                                                                s.departshipmentnumber = "";
                                                                s.departcomment = "";
                                                                s.departureplan = "";
                                                                s.departtasktypename = "";




                                                            }
                                                            s.departurestatus = value;

                                                            this.setState({ selectedArrivalInfo: s });
                                                        }}
                                                    />
                                                    {this.state.selectedArrivalInfo.departurestatus !== "bobtail" && (

                                                        <>

                                                            <FFFormSelectField
                                                                defaultOptions={true}
                                                                fieldname="departureplan"
                                                                label="Plan Type"
                                                                icon="fa-warehouse"
                                                                value={this.capitalizeFirstLetter(this.state.selectedArrivalInfo.departureplan)}
                                                                required={true}
                                                                isClearable={false}
                                                                placeholder="Select the Plan Type"
                                                                hideEmpty={true}
                                                                options={["Live", "Hook"]}
                                                                isDisabled={true}


                                                                onBlur={() => {
                                                                    let errors = this.validate(
                                                                        this.validationSchema.bind(this)
                                                                    )(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.departureplan = true;
                                                                    this.setState({ errors: errors, touched: touched });
                                                                }}
                                                                error={this.state.errors.departureplan}
                                                                touched={this.state.touched.departureplan}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    let value = v.value.toLowerCase();

                                                                    s.departureplan = value;

                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }
                                                                }
                                                            />
                                                            <FFFormSelectField
                                                                defaultOptions={true}
                                                                fieldname="departuretasktype"
                                                                label="Task Type"
                                                                icon="fa-warehouse"
                                                                value={
                                                                    this.state.selectedArrivalInfo.departuretasktype
                                                                        ? this.state.selectedArrivalInfo.departtasktypename
                                                                        : "CHECK-OUT ASSET (GATE)"
                                                                }
                                                                required={true}
                                                                isClearable={false}
                                                                options={this.state.departureOptions} // Use departureOptions array with value and label
                                                                placeholder="Select the Task Type"
                                                                hideEmpty={true}
                                                                onBlur={() => {
                                                                    let errors = this.validate(this.validationSchema.bind(this))(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.departuretasktype = true;
                                                                    this.setState({ errors, touched });
                                                                }}
                                                                error={this.state.errors.departuretasktype}
                                                                touched={this.state.touched.departuretasktype}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                    s.departuretasktype = v.value;
                                                                    s.departtasktypename = v.label;
                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }}
                                                            />

                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormAsyncSelectFieldClear
                                                                        defaultOptions={true}
                                                                        fieldname="departpartner"
                                                                        label="Trailer Name"
                                                                        icon="fa-truck"
                                                                        isDisabled={this.state.selectedArrivalInfo.departureplan &&
                                                                            this.state.selectedArrivalInfo.departureplan.toLowerCase() == "live" ? true : false}

                                                                        value={this.state.selectedArrivalInfo.departuretrailername}
                                                                        required={true}
                                                                        isClearable={true}
                                                                        placeholder="Enter Trailer Name"
                                                                        hideEmpty={true}

                                                                        loadOptions={carriersByLocationUUid(
                                                                            this.props.currentLocation.uuid
                                                                        )}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.departpartner = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.departpartner}
                                                                        touched={this.state.touched.departpartner}
                                                                        onClear={() => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            // Clear the partner if flag is true
                                                                            s.departpartner = "";
                                                                            s.departuretrailername = "";
                                                                            this.setState({ selectedArrivalInfo: s });


                                                                        }}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };

                                                                            if (v) {
                                                                                s.departpartner = v.uuid;
                                                                                s.departuretrailername = v.name;
                                                                                this.fetchTrailerInfo(this.props.currentLocation.uuid, s.departuretrailername);
                                                                                s.departuretrailerno = "";

                                                                                if (s.departuretrailernoLable) {
                                                                                    s.departuretrailernoLable = "";
                                                                                }

                                                                            }

                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                                {this.state.attachments.departuretrailername && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.departuretrailername.controllId,
                                                                                    fieldName: attachments.departuretrailername.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                             ></i>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    {this.state.selectedArrivalInfo.departureplan &&
                                                                        this.state.selectedArrivalInfo.departureplan.toLowerCase() === "hook" ? (
                                                                        <>
                                                                            <FFFormSelectFieldDepart
                                                                                defaultOptions={true}
                                                                                departuretrailerno={true}
                                                                                fieldname="departuretrailerno"
                                                                                label="Trailer Number"
                                                                                icon="fa-truck"
                                                                                options={this.state.trailerOptions}
                                                                                value={this.state.selectedArrivalInfo.departuretrailernoLable &&
                                                                                    this.state.selectedArrivalInfo.departuretrailernoLable != "null" ?
                                                                                    this.state.selectedArrivalInfo.departuretrailernoLable : ""}
                                                                                required={true}
                                                                                isClearable={true}
                                                                                placeholder="Enter Trailer Number"
                                                                                hideEmpty={true}
                                                                                onBlur={() => {
                                                                                    let errors = this.validate(this.validationSchema.bind(this))(
                                                                                        this.state.selectedArrivalInfo
                                                                                    );
                                                                                    let touched = { ...this.state.touched };
                                                                                    touched.departuretrailerno = true;
                                                                                    this.setState({ errors: errors, touched: touched });
                                                                                }}
                                                                                error={this.state.errors.departuretrailerno}
                                                                                touched={this.state.touched.departuretrailerno}
                                                                                onSearch={(value) => this.getAllMatchingOnSiteTrailer(value ? value : this.state.selectedArrivalInfo.departuretrailerno)}
                                                                                onChange={(v) => {
                                                                                    let s = { ...this.state.selectedArrivalInfo };
                                                                                    if (v && v.value && v.label) {
                                                                                        if (v.trailerName) {
                                                                                            s.departuretrailername = v.trailerName;
                                                                                            s.departpartner = v.trailerUuid;
                                                                                        }

                                                                                        s.departuretrailerno = v.value;
                                                                                        s.departuretrailernoLable = v.label;
                                                                                        s.departassettypename = v.assetType;
                                                                                        s.departassettype = v.assetTypeUuid;
                                                                                        s.departspot = v.spotUuid;
                                                                                        s.departspotname = v.departspotname;
                                                                                        if (s.departurestatus.toLowerCase() === "loaded") {
                                                                                            s.departuresealno = v.sealNumber;
                                                                                            s.departshipmentnumber = v.shipmentNumber;
                                                                                        }
                                                                                        if (s.departurestatus.toLowerCase() == "empty") {
                                                                                            s.departuresealno = "";
                                                                                            s.departshipmentnumber = "";
                                                                                        }

                                                                                    } else {

                                                                                        s.departuretrailerno = "";
                                                                                        s.departuretrailernoLable = "";
                                                                                    }

                                                                                    this.setState({ selectedArrivalInfo: s });
                                                                                }}

                                                                            />


                                                                        </>
                                                                    ) : (
                                                                        <FFFormTextField
                                                                            fieldname="departuretrailerno"
                                                                            label="Trailer Number"
                                                                            disabled={
                                                                                this.state.selectedArrivalInfo.departureplan &&
                                                                                    this.state.selectedArrivalInfo.departureplan.toLowerCase() === "live"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            placeholder="Enter Trailer Number"
                                                                            isClearable={false}
                                                                            icon="fa-truck"
                                                                            value={
                                                                                this.state.selectedArrivalInfo.departuretrailerno && this.state.selectedArrivalInfo.departuretrailerno != "null"

                                                                                    ? this.state.selectedArrivalInfo.departuretrailerno
                                                                                    : ""
                                                                            }
                                                                            required={true}
                                                                            onBlur={() => {
                                                                                let errors = this.validate(this.validationSchema.bind(this))(
                                                                                    this.state.selectedArrivalInfo
                                                                                );
                                                                                let touched = { ...this.state.touched };
                                                                                touched.departuretrailerno = true;
                                                                                this.setState({ errors: errors, touched: touched });
                                                                            }}
                                                                            error={this.state.errors.departuretrailerno}
                                                                            touched={this.state.touched.departuretrailerno}
                                                                            onChange={(v) => {
                                                                                let s = { ...this.state.selectedArrivalInfo };
                                                                                s.departuretrailerno = v;
                                                                                this.setState({ selectedArrivalInfo: s });
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                {this.state.attachments.departuretrailerno && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.departuretrailerno.controllId,
                                                                                    fieldName: attachments.departuretrailerno.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                             ></i>
                                                                )}
                                                            </div>
                                                            <FFFormAsyncSelectField
                                                                defaultOptions={true}
                                                                fieldname="departassettype"
                                                                label="Trailer Type"
                                                                icon="fa-truck"
                                                                value={this.state.selectedArrivalInfo.departassettypename}
                                                                required={true}
                                                                isClearable={false}
                                                                placeholder="Select the Trailer Type"
                                                                hideEmpty={true}

                                                                loadOptions={promiseEZTrackEquipmentTypeByPrefix(
                                                                    this.props.currentLocation.uuid
                                                                )}
                                                                onBlur={() => {
                                                                    let errors = this.validate(
                                                                        this.validationSchema.bind(this)
                                                                    )(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.departassettype = true;
                                                                    this.setState({ errors: errors, touched: touched });
                                                                }}
                                                                error={this.state.errors.departassettype}
                                                                touched={this.state.touched.departassettype}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };

                                                                    if (v) {
                                                                        s.departassettype = v.uuid;
                                                                        s.departassettypename = v.name;
                                                                    }

                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }
                                                                }
                                                            />

                                                            <FFFormAsyncSelectField
                                                                defaultOptions={true}
                                                                fieldname="departspot"
                                                                label="Move From Zone Spot-Number"
                                                                icon="fa-tag"

                                                                value={this.state.selectedArrivalInfo.departspotname}
                                                                required={true}
                                                                isClearable={false}
                                                                placeholder="Select the Spot"
                                                                hideEmpty={true}

                                                                loadOptions={promiseSpotTypeByLocationUUidAzure(
                                                                    this.props.currentLocation.uuid
                                                                )}
                                                                onBlur={() => {
                                                                    let errors = this.validate(
                                                                        this.validationSchema.bind(this)
                                                                    )(this.state.selectedArrivalInfo);
                                                                    let touched = { ...this.state.touched };
                                                                    touched.departspot = true;
                                                                    this.setState({ errors: errors, touched: touched });
                                                                }}
                                                                error={this.state.errors.departspot}
                                                                touched={this.state.touched.departspot}
                                                                onChange={(v) => {
                                                                    let s = { ...this.state.selectedArrivalInfo };

                                                                    if (v) {
                                                                        s.departspot = v.uuid;
                                                                        s.departspotname = v.name;
                                                                    }

                                                                    this.setState({ selectedArrivalInfo: s });
                                                                }
                                                                }
                                                            />

                                                            {/* <FFFormTextField
                                fieldname="departurereference"
                                label="Ref#"
                                //   disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                placeholder="Enter Reference Number"
                                icon="fa-tag"
                                value={
                                  this.state.selectedArrivalInfo.departurereference
                                    ? this.state.selectedArrivalInfo.departurereference
                                    : ""
                                }
                                required={false}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.departurereference = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.departurereference}
                                touched={this.state.touched.departurereference}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  s.departurereference = v;
                                  this.setState({ selectedArrivalInfo: s });
                                }}
                              /> */}
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="departuresealno"
                                                                        label="Seal#"
                                                                        //     disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                                                        placeholder="Enter Seal Number"
                                                                        icon="fa-tag"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.departuresealno && this.state.selectedArrivalInfo.departuresealno !== "null"

                                                                                ? this.state.selectedArrivalInfo.departuresealno
                                                                                : ""
                                                                        }
                                                                        required={false}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.departuresealno = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.departuresealno}
                                                                        touched={this.state.touched.departuresealno}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            s.departuresealno = v;
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.departuresealno && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.departuresealno.controllId,
                                                                                    fieldName: attachments.departuresealno.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                             ></i>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="departshipmentnumber"
                                                                        label="Departure Shipment Number"
                                                                        //   disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                                                        placeholder="Enter Shipment Number"
                                                                        icon="fa-tag"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.departshipmentnumber && this.state.selectedArrivalInfo.departshipmentnumber != "null"
                                                                                ? this.state.selectedArrivalInfo.departshipmentnumber
                                                                                : ""
                                                                        }
                                                                        required={false}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.departurereference = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.departshipmentnumber}
                                                                        touched={this.state.touched.departshipmentnumber}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            s.departshipmentnumber = v;
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.departshipmentnumber && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.departshipmentnumber.controllId,
                                                                                    fieldName: attachments.departshipmentnumber.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                             ></i>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <FFFormTextField
                                                                        fieldname="departcomment"
                                                                        label="Comments"
                                                                        //   disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                                                        placeholder="Enter Comments..."
                                                                        icon="fa-tag"
                                                                        value={
                                                                            this.state.selectedArrivalInfo.departcomment && this.state.selectedArrivalInfo.departcomment != "null"
                                                                                ? this.state.selectedArrivalInfo.departcomment
                                                                                : ""
                                                                        }
                                                                        required={false}
                                                                        onBlur={() => {
                                                                            let errors = this.validate(
                                                                                this.validationSchema.bind(this)
                                                                            )(this.state.selectedArrivalInfo);
                                                                            let touched = { ...this.state.touched };
                                                                            touched.departurereference = true;
                                                                            this.setState({ errors: errors, touched: touched });
                                                                        }}
                                                                        error={this.state.errors.departcomment}
                                                                        touched={this.state.touched.departcomment}
                                                                        onChange={(v) => {
                                                                            let s = { ...this.state.selectedArrivalInfo };
                                                                            s.departcomment = v;
                                                                            this.setState({ selectedArrivalInfo: s });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.state.attachments.departcomment && (
                                                                    <i
                                                                        className="fa-solid fa-upload"
                                                                        title="Upload"
                                                                        style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const { attachments } = this.state;

                                                                            this.setState({
                                                                                uploadModalOpen: true,
                                                                                uploadprops: {
                                                                                    ...this.state.uploadprops,
                                                                                    controllId: attachments.departcomment.controllId,
                                                                                    fieldName: attachments.departcomment.fieldName,
                                                                                },
                                                                            });
                                                                        }}                                             ></i>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </TabPane>

                                            </TabContent>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mb-3 text-right">


                                    &nbsp;&nbsp;
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() =>
                                            this.closePopup()
                                        }

                                    >
                                        Close
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;




                                    <Button
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            if (this.state.activeTab === '3') {
                                                this.onSubmit();
                                            } else {
                                                this.nextTab();
                                            }
                                        }}
                                    >
                                        {this.state.activeTab === '3' ? 'Submit' : 'Next'}
                                    </Button>

                                    {
                                        this.state.submitDisable && (
                                            <p style={{ color: "#FF0000" }}>
                                                Please fill all required fields marked with a red asterisk to enable it.
                                            </p>
                                        )
                                    }



                                </div>
                            </Form>
                        </ModalBody>
                        {/* <ModalFooter>
   
        </ModalFooter> */}
                    </Loader>
                </Modal>
            </div>

        )
    }
    closePopup() {
        this.setState({ updatePopupState: false, activeTab: '1', errors: {}, touched: {}, selectedArrivalInfo: {} })

        return this.props.onClose && this.props.onClose();

    }
    timezoneShortCode(timezone, inp) {
        if (!inp) inp = new Date();
        if (timezone) {
            return moment(inp).tz(timezone).format("z");
        } else {
            return moment(inp).tz(moment.tz.guess()).format("z");
        }
    }
    onSubmit() {
        // First, we validate the data when the form is submitted
        let errors = this.validate(this.validationSchema.bind(this))(this.state.selectedArrivalInfo);

        // If there are validation errors, do not proceed with the submission
        if (this.state.submitDisable) {
            console.log("Validation failed", errors);
            return;
        }



        let matchedCarrier = this.state.carriers.find(carrier => carrier.value === this.state.selectedArrivalInfo.arrivaltrailername);
        if (matchedCarrier) {
            this.state.selectedArrivalInfo.arrivalpartner = matchedCarrier.uuid;
        }


        let matchedCarrier1 = this.state.carriers.find(carrier => carrier.value === this.state.selectedArrivalInfo.departuretrailername);
        if (matchedCarrier1) {
            this.state.selectedArrivalInfo.departpartner = matchedCarrier1.uuid;
        }

        const selectedArrivalInfo = this.state.selectedArrivalInfo;

        const jsonString = JSON.stringify(selectedArrivalInfo, null, 2);

        const payload = {
            organizationUuid: this.props.user.company.uuid,
            arrivalTrailerName: this.state.selectedArrivalInfo.arrivalPartner,
            trailerDetails: jsonString,
        };
        console.log(payload, "rana done");

        // Get all attachments from localStorage for the specific user
        const attachmentsData = JSON.parse(localStorage.getItem(getLocalStorageName("poweryard-create-gate-task-images", this.props.user)));

        // Check if attachments exist in localStorage
        if (attachmentsData && Array.isArray(attachmentsData)) {
            // Store it in the state under `attachments` within `selectedArrivalInfo`
            selectedArrivalInfo.gateBolbModels = attachmentsData;

        }

        localStorage.removeItem(getLocalStorageName("poweryard-create-gate-task-images", this.props.user));

        this.state.selectedArrivalInfo.organizationUuid = this.props.user.company ? this.props.user.company.uuid : "null";
        this.state.selectedArrivalInfo.locationUuid = this.props.currentLocation ? this.props.currentLocation.uuid : "null";
        this.state.selectedArrivalInfo.locationCode = this.props.currentLocation ? this.props.currentLocation.code : "null";
        this.state.selectedArrivalInfo.locationName = this.props.currentLocation ? this.props.currentLocation.name : "null";
        this.state.selectedArrivalInfo.arrivalID = "0";
        if (this.state.selectedArrivalInfo.departurestatus == "bobtail") {
            this.state.selectedArrivalInfo.departureplan = "";

        }
        this.setState({
            loaded: true,

        })
        this.updateSpinner("");
        postCreateGateArrivalRecord(this.state.selectedArrivalInfo, (res) => {
            if (res.taskStatusCode === 200) {
                this.setState({
                    loaded: false,

                })
                showInfo(res.taskStatus);
                this.closePopup();
                //return this.props.onSave && this.props.onSave(res);
            }

            else {
                showInfo(res.taskStatus);
                this.setState({
                    loaded: false,

                })

            }

        }, (err) => {
            this.setState({
                loaded: false,

            })

        });



    }
    localDateToUTC(dateISOLocalString) {
        let localLoginTime = moment.utc(dateISOLocalString).utc();
        var returnDate = new Date(localLoginTime.format('MMM-DD-YYYY HH:mm:ss'));
        return returnDate;
    }
}
export default CreateGateModal;