import React, { Component } from "react";
import * as Yup from "yup";
import moment from "moment";
import MomentDisplay from "../../assets/components/MomentDisplay";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectFieldButton from "../../common/formik-free/FFFormSelectFieldButton";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import { confirmAlert } from "react-confirm-alert";
import {
  setupAddAsset,
  promiseAssetByPrefix,
  promiseTaskTypeByPrefix,
  carriersByLocationUUid,
  saveTask,
  getDefaultEZTrackEquipment,
  downloadTaskByUuid,
  getAllSecondaryReferenceByRefID,
  getTaskReferenceByRefID,
  getSecondaryReferenceByRefID,
  promiseEZTrackEquipmentTypeByPrefix,
  promiseAssetStatusesByLocationUUid,
  clientResponseBySpotUuid,
  promiseEZTrackEquipmentFuel,

} from "../../NetworkHelpers";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  Form,
  FormFeedback,
  Button,
  Label,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
} from "reactstrap";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";

import {
  convertCtoF,
  convertFtoC,
  getErrorsFromValidationError,
} from "../../common/utilities";

import { showInfo, showError } from "../../MainApp";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import TemperatureModal from "./TemperatureModal";
import CommentTaskModal from "./CommentTaskModal";
import FFFormAsyncSelectField from "../formik-free/FFFormAsyncSelectField";
import FFFormLabelButton from "../formik-free/FFFLabelButton";
import ReferenceModal from "../../PowerYard/components/ReferenceModal";
import HelpIcon from "../HelpIcon";
import FFFormTextAreaField from "../formik-free/FFFormTextAreaField";
class AddAssetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SpotonSite: false,
      SpotonSiteData: undefined,
      topggleCheck: false,
      taskUnderEdit: {
        name: "",
        location: this.props.currentLocation,
        enabled: true,
        isNew: true,
      },
      showSealNumber: false,
      secondaryRef: "",
      open: props.open,
      errors: {},
      loaded: false,
      touched: {},
      updatePopup: false,
      showSpotHistory: true,
      defaultEquipmentType: {},
      closeCheck: false,
      taskProgressTypeChecker: "",
      addingAsset: false,
      selectedTask: {
        refData: {
          prNumber: null,
          prName: null,
          prAppointmentDate: null,
          prAppointmentWindow: null,
          prAppointmentType: null,
          prAppointmentPlan: null,
          prScac: null,
          prRefIdFlagDate: null,
          cancelled: null,
          prRefIdFlag: null,
          uuid: null,
          carrier: {},
          location: null,
          secondaryRef: "",
        },
      },
      secRefNumber: null,
    };

    // getDefaultEZTrackEquipment(this.props.currentLocation.uuid, (def) => {
    //   this.setState({
    //     defaultEquipmentType: def,
    //   });
    // });
    //   if (this.props.match.params.uuid !== "new") {
    //     this.loadTask(this.props.match.params.uuid);
    //   }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  loadAssetsOnStart(obj, secRefObj) {
    setupAddAsset(obj, (res) => {
      let response = res.content;
      let task = { ...this.state.taskUnderEdit };
      if (!task.assetStatus) {
        task.assetStatus = response.assetStatuses.filter((t) => t.default)[0];
      }
      let defaultAssetStatus = response.assetStatuses.filter(
        (t) => t.default
      )[0];

      let moveToSpots = response.spots.map((it) => {
        return {
          label: it.zone + (it.name ? " - " + it.name : ""),
          value: it.zone + (it.name ? " - " + it.name : ""),
          ...it,
        };
      });
      let assetStatuses = response.assetStatuses.map((it) => {
        return {
          label: it.name,
          value: it.name,
          ...it,
        };
      });
      let carriers = response.carriers.map((it) => {
        return {
          label: it.name,
          value: it.name,
        };
      });
      let equipments = response.equipments.map((it) => {
        return {
          label: it.name,
          value: it.name,
          ...it,
        };
      });

      let defaultAssetType = equipments.filter((t) => t.def)[0];
      let taskTypes = response.taskTypes.map((it) => {
        return {
          label: it.name,
          value: it.name,
          ...it,
        };
      });
      let taskTypeList = taskTypes.filter(
        (it) =>
          it.name.toLocaleLowerCase() === "add asset" ||
          it.name.toLocaleLowerCase() === "check-in asset"
      );
      let index = taskTypeList.findIndex(
        (it) => it.name.toLocaleLowerCase() === "check-in asset"
      );
      this.setState({
        assetStatusOptions: assetStatuses,
        updatePopup: false,
        moveToSpots: moveToSpots,
        taskTypes: taskTypeList,
        carriers: carriers,
        equipments: equipments,
        showSealNumber: false,
        taskUnderEdit: {
          name: "",
          location: {
            uuid: this.props.currentLocation.uuid,
            name: this.props.currentLocation.name,
          },
          secondaryRef: secRefObj,
          enabled: true,
          isNew: true,
          type: taskTypeList[index],
          created: new Date().getTime(),
          acceptDate: new Date().getTime(),
          start: new Date().getTime(),
          end: new Date().getTime(),
          shift: this.props.user.shift,
          assetType: defaultAssetType,
          assetStatus: defaultAssetStatus,
          assignee: {
            email: this.props.user.email,
            username: this.props.user.username,
            firstname: this.props.user.firstname,
            lastname: this.props.user.lastname,
            uuid: this.props.user.uuid,
          },
        },
      });
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("componentaders updated", this.state.taskUnderEdit)
    if (
      this.props.open === true &&
      prevState.open !== true &&
      this.state.addingAsset == false
    ) {
      this.setState({
        open: true,
        addingAsset: true,
        taskUnderEdit: {
          created: new Date().getTime(),
          acceptDate: new Date().getTime(),
          start: new Date().getTime(),
          end: new Date().getTime(),
        },
      });
      let obj = {
        locationUuid: this.props.currentLocation.uuid,
      };
      this.loadAssetsOnStart(obj, null)
      getDefaultEZTrackEquipment(this.props.currentLocation.uuid, (def) => {
        this.setState({
          defaultEquipmentType: def.content,
        });
      });

      // promiseTaskTypeByPrefix("").then((results) => {

      //   results = results.filter(it => (it.name).toLocaleLowerCase() === "add asset" || (it.name).toLocaleLowerCase() === "check-in asset");
      //   let index = results.findIndex(it => (it.name).toLocaleLowerCase() === "add asset");

      // });
    }
  }
  componentWillUpdate(previousProps, prevState, snapshot) {
    if (previousProps.open && this.state.updatePopup === false) {
    }
  }
  updateBeforeClosing() {
    promiseTaskTypeByPrefix("").then((results) => {
      results = results.content;
      results = results.filter(it => (it.name).toLocaleLowerCase() === "add asset" || (it.name).toLocaleLowerCase() === "check-in asset");
      let index = results.findIndex(it => (it.name).toLocaleLowerCase() === "add asset");
      this.setState({
        taskTypeOptions: results,
        taskUnderEdit: {
          name: "",
          location: {
            uuid: this.props.currentLocation.uuid,
            name: this.props.currentLocation.name,
          },
          enabled: true,
          isNew: true,
          type: results[index],
          created: new Date().getTime(),
          acceptDate: new Date().getTime(),
          start: new Date().getTime(),
          end: new Date().getTime(),
          shift: this.props.user.shift,
          assignee: {
            email: this.props.user.email,
            username: this.props.user.username,
            firstname: this.props.user.firstname,
            lastname: this.props.user.lastname,
            uuid: this.props.user.uuid,
          },
        },
      });
    });
  }
  getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  onSubmit(val, taskObj) {
    if (val === "comments") {
      this.setState({
        loadedComment: true,
      });
    }
    if (val === "temp") {
      this.setState({
        loadedTemp: true,
      });
    }

    this.updateSpinner("Saving...");
    let task = this.state.taskUnderEdit;
    let taskToBeSubmit = {};
    taskToBeSubmit.boxedIn = task.boxedIn === 0 ? null : task.boxedIn;
    taskToBeSubmit.type = {
      uuid: task.type.uuid,
      name: task.type.name,
    };
    taskToBeSubmit.location = {
      uuid: task.location.uuid,
      name: task.location.name,
    };
    if (task.isNew === true) {
      taskToBeSubmit.isNew = true;
    }
    if (task.carrier) {
      taskToBeSubmit.carrier = {
        uuid: task.carrier.uuid,
        name: task.carrier.name,
      };
    }
    if (task.assignee) {
      taskToBeSubmit.assignee = {
        uuid: task.assignee.uuid,
        username: task.assignee.username,
      };
    }
    if (task.assetStatus) {
      taskToBeSubmit.assetStatus = {
        uuid: task.assetStatus.uuid,
        name: task.assetStatus.name,
      };
    }
    if (task.assetType) {
      // taskToBeSubmit.assetType = {
      //   uuid : task.assetType.uuid,
      //   name : task.assetType.name,
      //   baseEquipment: task.assetType.baseEquipment
      // };
      taskToBeSubmit.assetType = task.assetType.name;
    }
    if (task.moveTo) {
      taskToBeSubmit.moveTo = {
        uuid: task.moveTo.uuid,
        name: task.moveTo.zoneName,
      };
    }
    if (task.moveFrom) {
      taskToBeSubmit.moveFrom = {
        uuid: task.moveFrom.uuid,
        name: task.moveFrom.zoneName,
      };
    }
    if (task.asset != null) {
      if (task.asset.equipment === undefined || task.asset.equipment === "") {
        task.asset.equipment = null;
        // task.asset["@type"] = this.state.defaultEquipmentType.equipmentType;
        task.asset.equipment = this.state.defaultEquipmentType;
      } else if (
        task.asset.equipment !== null &&
        task.asset.equipment.baseEquipment !== null &&
        task.asset.equipment.baseEquipment !== undefined
      ) {
        // task.asset["@type"] = task.asset.equipment.baseEquipment.equipmentType;
      }

      // if (task.asset.__isNew__ || !task.asset.domicile) {
      //   task.asset.domicile = this.props.currentLocation;
      // }
    }
    // else {
    //   if (task.assetType) {
    //     task.asset = {
    //       // "@type": task.assetType.baseEquipment.equipmentType,
    //       // domicile: this.props.currentLocation,
    //       // carrier: task.carrier,
    //       equipment: task.assetType,
    //     };
    //   }

    // if(!task.yardStatusCheck){
    //   task.yardStatusCheck = false;
    // }

    if (task.asset) {
      taskToBeSubmit.asset = {
        label: task.asset.label,
        value: task.asset.value,
        __isNew__: task.asset.__isNew__,
        assetId: task.asset.assetId,
        uuid: task.asset.uuid,
        equipment: {
          uuid: task.asset.equipment.uuid,
          name: task.asset.equipment.name,
          baseEquipment: {
            uuid: task.asset.equipment.baseEquipment.uuid,
            equipmentType: task.asset.equipment.baseEquipment.equipmentType,
          },
        },
      };
    }
    // else {
    //   if (task.assetType) {
    //     task.asset = {
    //       "@type": task.assetType.baseEquipment.equipmentType,
    //       // domicile: this.props.currentLocation,

    //       equipment: task.assetType,
    //     };
    //     taskToBeSubmit.asset = task.asset;
    //   }
    // }
    if (navigator.geolocation) {
      let positionOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60 * 60 * 1000,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          taskToBeSubmit.latitude = position.coords.latitude;
          taskToBeSubmit.longitude = position.coords.longitude;
        },
        (error) => {
          //ignore errors
        },
        positionOptions
      );
    }

    if (task.fuelLevel) {
      if (!taskToBeSubmit.fuelLevel) {
        taskToBeSubmit.fuelLevel = {}; // Initialize fuelLevel if it doesn't exist
      }

      taskToBeSubmit.fuelLevel.uuid = task.fuelLevel.uuid;
      taskToBeSubmit.fuelLevel.name = task.fuelLevel.name;
      taskToBeSubmit.fuelLevel.fuelLevel = task.fuelLevel.fuelLevel;

    }
    else {

      taskToBeSubmit.fuelLevel = null;
    }
    if (task.reefer) {


      taskToBeSubmit.reefer = task.reefer;

    }

    else {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.reefer = false;

      }
    }
    if (task.continous) {

      taskToBeSubmit.continous = task.continous;

    }
    else {

      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.continous = false;

      }
    }
    if (task.actualTemp === "") {
      task.actualTemp = null;
    }
    if (task.tempSetPoint === "") {
      task.tempSetPoint = null;
    }
    if (
      taskObj &&
      taskObj.actualTemp !== undefined &&
      taskObj.actualTemp !== null &&
      taskObj.actualTemp !== ""
    ) {
      task.actualTemp = taskObj.actualTemp;
    }
    if (
      taskObj &&
      taskObj.tempSetPoint !== undefined &&
      taskObj.tempSetPoint !== null &&
      taskObj.tempSetPoint !== ""
    ) {
      task.tempSetPoint = taskObj.tempSetPoint;
    }
    if (
      this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
      this.props.company.temperatureUnitsOfMeasure !== "Celsius"
    ) {
      task.temperatureSetPointInC = convertFtoC(task.tempSetPoint);
      task.temperatureInC = convertFtoC(task.actualTemp);
      this.setState({ taskUnderEdit: task });
    }
    if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
      task.temperatureSetPointInC = task.tempSetPoint;
      task.temperatureInC = task.actualTemp;
      this.setState({ taskUnderEdit: task });
    }
    taskToBeSubmit.tempSetPoint = task.tempSetPoint;
    taskToBeSubmit.actualTemp = task.actualTemp;
    taskToBeSubmit.temperatureSetPointInC = task.temperatureSetPointInC;
    taskToBeSubmit.temperatureInC = task.temperatureInC;
    taskToBeSubmit.comments = task.comments;
    taskToBeSubmit.shipmentNumber = task.shipmentNumber;
    taskToBeSubmit.sealed = task.sealed === true ? task.sealed : false;
    taskToBeSubmit.sealNumber = task.sealNumber;
    taskToBeSubmit.movedTaskUuid = null;

    taskToBeSubmit.enabled = task.enabled;
    taskToBeSubmit.completedManually = task.completedManually;
    taskToBeSubmit.shift = task.shift;
    taskToBeSubmit.company = this.props.company;
    taskToBeSubmit.uuid = task.uuid;
    taskToBeSubmit.assignDate = task.assignDate;
    taskToBeSubmit.acceptDate = task.acceptDate;
    taskToBeSubmit.startDate = task.start;
    taskToBeSubmit.endDate = task.end;
    taskToBeSubmit.addAssetTask = true;
    var secondaryRef = null;
    if (
      this.state.taskUnderEdit.secondaryRef !== null &&
      this.state.taskUnderEdit.secondaryRef !== "" && this.state.taskUnderEdit.secondaryRef !== undefined
    ) {
      secondaryRef = this.state.taskUnderEdit.secondaryRef.valueSubmit;
      taskToBeSubmit.secondaryRef = secondaryRef;
    } else {
      taskToBeSubmit.secondaryRef = secondaryRef;
    }
    taskToBeSubmit.taskVersion = task.taskVersion;

    // task.company = this.props.company;
    // // task.assignee = this.props.user;
    // task.location = this.props.currentLocation.name;
    // task.yardStatusCheck = false;
    saveTask(
      taskToBeSubmit,
      (res) => {
        if (res.code == "1000") {
          showError(res.message);
          this.setState({ loadedTemp: false, loaded: false, loadedComment: false });
          return;
        }
        this.setState({ touched: {} });
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          res.tempSetPoint = convertCtoF(res.temperatureSetPointInC);
          if (res.tempSetPoint !== null) {
            res.tempSetPoint = this.getFlooredFixed(res.tempSetPoint, 1);
          }
          res.actualTemp = convertCtoF(res.temperatureInC);
          if (res.actualTemp !== null) {
            res.actualTemp = this.getFlooredFixed(res.actualTemp, 1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          res.tempSetPoint = res.temperatureSetPointInC;
          if (res.temperatureSetPointInC !== null) {
            res.tempSetPoint = this.getFlooredFixed(res.tempSetPoint, 1);
          }
          res.actualTemp = res.temperatureInC;
          if (res.actualTemp !== null) {
            res.actualTemp = this.getFlooredFixed(res.actualTemp, 1);
          }
        }
        if (val === "temp") {
          showInfo("Temperature saved.");
          // this.loadTask(res.content.uuid);
          // res.actualTemp = task.actualTemp;
          // res.tempSetPoint = task.tempSetPoint;
          task.uuid = res.content.uuid;
          task.taskVersion = res.content.taskVersion;
          task.isNew = false;
          this.setState({
            loadedTemp: false,
            temperatureModalOpen: false,
            taskUnderEdit: task,
          });
          //   this.props.history.push("/poweryard/assetDetail/"+res.uuid);
        } else if (val === "comments") {
          task.isNew = false;
          showInfo("Comment saved.");
          // this.loadTask(res.content.uuid);
          task.uuid = res.content.uuid;
          task.taskVersion = res.content.taskVersion;
          this.setState({
            loadedComment: false,
            commentTaskModalOpen: false,
            taskUnderEdit: task,
          });
          //   this.props.history.push("/poweryard/assetDetail/"+res.uuid);
        } else if (val === true) {
          this.setState({ loaded: false });
          showInfo("Task saved.");
          this.setState({
            taskUnderEdit: {
              name: "",
              location: this.props.currentLocation,
              enabled: true,
              isNew: true,
            },
            addingAsset: false,
            open: false
          });
          this.updateBeforeClosing();
          return (
            this.props.onSave &&
            this.props.onSave(this.state && this.state.taskUnderEdit)
          );
          // this.props.history.push("/poweryard-onsite");
        } else {
          showInfo("Task saved.");
          this.getLocationFireEvent(
            this.state.taskUnderEdit.uuid,
            this.state.taskProgressTypeChecker,
            this.props.company,
            this.props.currentLocation.uuid
          );
        }
        this.setState({
          loaded: false,
          closeCheck: false,
          taskProgressTypeChecker: "",
          open: false,
        });
      },
      () => {
        showError("Unable to save task.");
      }
    );
  }
  loadTask(uuid) {
    downloadTaskByUuid(
      uuid,
      (data) => {
        let success = data.content;
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
          if (success.tempSetPoint !== null) {
            success.tempSetPoint = this.getFlooredFixed(
              success.tempSetPoint,
              1
            );
          }
          success.actualTemp = convertCtoF(success.temperatureInC);
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          success.tempSetPoint = success.temperatureSetPointInC;
          if (success.temperatureSetPointInC !== null) {
            success.tempSetPoint = this.getFlooredFixed(
              success.tempSetPoint,
              1
            );
          }
          success.actualTemp = success.temperatureInC;
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        //   if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
        //     success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
        //     success.actualTemp = convertCtoF(success.temperatureInC);
        // }
        // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
        //     success.tempSetPoint = success.temperatureSetPointInC;
        //     success.actualTemp = success.temperatureInC;
        // }
        success.lastModified = success.updated;
        if (success.acceptDate) {
          success.acceptDate = new Date(success.acceptDate);
        } else {
          success.acceptDate = null;
        }
        if (success.startDate) {
          success.start = new Date(success.startDate);
        } else {
          success.start = null;
        }
        if (success.endDate) {
          success.end = new Date(success.endDate);
        } else {
          success.end = null;
        }
        if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY") {
          success.assignee = this.props.user;
        }
        let taskBackup = JSON.stringify(success);
        this.setState({
          taskUnderEdit: success,
          taskBackup: taskBackup,
          loadedTemp: false,
          temperatureModalOpen: false,
          loadedComment: false,
          commentTaskModalOpen: false,
          showSealNumber: data.content.sealed,
        });
      },
      (failure) => {
        showError("Unable to access task.");
      }
    );
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    if (values.acceptDate && values.acceptDate === "Invalid Date") {
      values.acceptDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    // const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      type: Yup.string().required("Type is required."),
      start: Yup.string().required("Date is required."),
      // asset: Yup.object().nullable(),
      asset: Yup.object().nullable().required("Trailer Number is required."),
      carrier: Yup.object().nullable().required("Trailer Name is required."),
      //   moveFrom: Yup.object().nullable(),
      moveTo: Yup.object().required("Move To is required."),
      boxingAssets: Yup.number()
        .typeError("Number must be between 1 to 9")
        .positive("Number must be between 1 to 9")
        .max(9, ({ max }) => "Number must be between 1 to 9")
        .min(1, ({ min }) => "Number must be between 1 to 9")
        .integer("Number must be between 1 to 9")
        .nullable(),
      assignee: Yup.object().nullable(),
      tempSetPoint: Yup.string()
        .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.999)")
        .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.999)")
        // /^-?\d{1,3}(\.\d{1,3})?$/
        .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.999)')
        .nullable()
        .transform((_, val) => val === val ? val : null),
      actualTemp: Yup.string()
        .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.999)")
        .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.999)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.999)')
        .nullable()
        .transform((_, val) => val === val ? val : null),

      //   moveTo: Yup.object()
      //     .nullable()
      //     .test("equal", "Move To must not equal Move From", function (v) {
      //       const ref = Yup.ref("moveFrom");
      //       if (v == null) {
      //         return true;
      //       }
      //       if (this.resolve(ref) != null) {
      //         return v.uuid !== this.resolve(ref).uuid;
      //       } else {
      //         return true;
      //       }
      //     }),
      acceptDate: Yup.string().max(
        new Date(),
        ({ max }) =>
          `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
      ),
      // acceptDate: Yup.date()
      //   .nullable()
      //   .max(
      //     new Date(),
      //     ({ max }) =>
      //       `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
      //   ),
      // start: Yup.date()
      //   .nullable()
      //   .min(
      //     Yup.ref("acceptDate"),
      //     ({ min }) =>
      //       `Task Start Timestamp needs to be after the assignment time.`
      //   ),
      // end: Yup.date()
      //   .nullable()
      //   .min(
      //     Yup.ref("start"),
      //     ({ min }) =>
      //       `Task End Timestamp needs to be after the Task Start Timestamp.`
      //   ),
    };
    return Yup.object().shape(validationRules);
  }

  timezoneShortCode(timezone, inp) {
    if (!inp) inp = new Date();
    if (timezone) {
      return moment(inp).tz(timezone).format("z");
    } else {
      return moment(inp).tz(moment.tz.guess()).format("z");
    }
  }
  taskProgressChecker(val) {
    this.setState({ taskProgressTypeChecker: val });
  }
  getAssetType() {
    if (
      this.state.taskUnderEdit.asset &&
      this.state.taskUnderEdit.asset.equipment
    ) {
      return this.state.taskUnderEdit.asset.equipment.name;
    } else if (this.state.taskUnderEdit.assetType) {
      return this.state.taskUnderEdit.assetType.name;
    } else if (this.state.defaultEquipmentType) {
      this.state.taskUnderEdit.assetType = this.state.defaultEquipmentType;
      return this.state.taskUnderEdit.assetType.name;
    }
    else if (typeof this.state.taskUnderEdit.assetType === "string") {
      return this.state.taskUnderEdit.assetType;
    } else {
      return "";
    }
  }
  openRefModal(ref, elem) {
    ;
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = elem;
    this.onGetRefDataByRefID(ref);
  }
  onGetRefDataByRefID(ref) {
    getSecondaryReferenceByRefID(
      ref.clickedRef,
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }

  onChangeRefNum(obj) {
    let taskUnderEditObj = { ...this.state.taskUnderEdit };
    getTaskReferenceByRefID(
      obj.value,
      (success) => {
        if (success.code === "200") {
          let response = success.content;
          if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
            response.tempSetPoint = convertCtoF(response.temperatureSetPointInC);
            if (response.tempSetPoint !== null) {
              response.tempSetPoint = this.getFlooredFixed(response.tempSetPoint, 1);
            }
            response.actualTemp = convertCtoF(response.temperatureInC);
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(response.actualTemp, 1);
            }
          }
          if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
            response.tempSetPoint = response.temperatureSetPointInC;
            if (response.temperatureSetPointInC !== null) {
              response.tempSetPoint = this.getFlooredFixed(response.tempSetPoint, 1);
            }
            response.actualTemp = response.temperatureInC;
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(response.actualTemp, 1);
            }
          }
          let task = taskUnderEditObj;
          task = response;
          task.uuid = taskUnderEditObj.uuid;
          task.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          task.acceptDate = taskUnderEditObj.acceptDate;
          task.start = taskUnderEditObj.start;
          task.end = taskUnderEditObj.end;
          task.type = taskUnderEditObj.type;
          var secRefObj = {
            value: obj.value,
            label: obj.label,
            uuid: obj.uuid,
            valueSubmit: task.secondaryRef
          }
          task.moveTo = task.moveTo === null ? {} : task.moveTo;
          task.secondaryRef = secRefObj;
          this.setState({ taskUnderEdit: task });
          // success.content.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          // success.content.uuid = taskUnderEditObj.uuid;
          // success.content.acceptDate = taskUnderEditObj.acceptDate;
          // success.content.startDate = taskUnderEditObj.startDate;
          // success.content.endDate = taskUnderEditObj.endDate;
          // success.content.type = taskUnderEditObj.type === undefined ? {} : taskUnderEditObj.type;
          // // for temperature converting 
          // if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
          //   success.content.tempSetPoint = convertCtoF(success.content.temperatureSetPointInC);
          //   if (success.content.tempSetPoint !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = convertCtoF(success.content.temperatureInC);
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          //   success.content.tempSetPoint = success.content.temperatureSetPointInC;
          //   if (success.content.temperatureSetPointInC !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = success.content.temperatureInC;
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: success.content.secondaryRef
          // }
          // success.content.moveTo = success.content.moveTo === null ? {} : success.content.moveTo;
          // success.content.secondaryRef = secRefObj;
          // this.setState({ taskUnderEdit: success.content, showSealNumber: success.content.sealed })
        }
        else if (success.code === "500") {
          // let uuidObj = {
          //   locationUuid: this.props.currentLocation.uuid,
          // };
          // let errors = {
          //   boxedIn: true
          // }
          // let touched = {
          //   boxedIn: true
          // }
          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: obj.valueSubmit,

          // };
          // this.loadAssetsOnStart(uuidObj, secRefObj)
          // this.setState({
          //   errors: errors,
          //   touched: touched,
          //   taskUnderEdit: {
          //     boxedIn: 0,
          //   }

          // })
        } else if (success.code === "1000") {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  handleSubmit = (e, val?) => {
    // let s = { ...this.state.taskUnderEdit };
    const { assetId, assetType } = this.state.taskUnderEdit;

    if (assetId && !assetType) {
      showError("Asset Type is required");
      return
    } else {
      let errors = this.validate(
        this.validationSchema.bind(this)
      )(this.state.taskUnderEdit);
      let closeCheck = val;
      let errorsIsEmpty =
        Object.keys(errors).length === 0 &&
        errors.constructor === Object;
      if (!errorsIsEmpty || this.state.errors.name) {
        let t = { ...this.state.touched };
        t.type = true;
        t.assetType = true;
        t.asset = true;
        t.carrier = true;
        t.moveTo = true;
        this.setState({ touched: t, errors: errors });
        e.preventDefault();
        return;
      }
      //   if (this.props.match.params.uuid === "new") {
      //     // this.setState({loaderMessage: "Saving ..."});
      //     this.updateSpinner("Saving ...");
      //   } else {
      //     // this.setState({loaderMessage: "Updating ..."});
      //     this.updateSpinner("Updating ...");
      //   }
      this.setState({
        loaded: true,
      });
      this.setState({ SpotonSite: false });

      this.onSubmit(closeCheck);

      e.preventDefault();
    }


  }
  render() {
    return (
      <>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        <TemperatureModal
          open={this.state.temperatureModalOpen}
          company={this.props.company}
          taskUnderEdit={this.state.taskUnderEdit}
          loadedTemp={this.state.loadedTemp}
          onSave={(taskUnderEdit) => {
            taskUnderEdit.popupType = "temperature";
            let errors = this.validate(this.validationSchema.bind(this))(
              this.state.taskUnderEdit
            );
            // let closeCheck = this.state.closeCheck;
            let errorsIsEmpty =
              Object.keys(errors).length === 0 && errors.constructor === Object;
            if (!errorsIsEmpty || this.state.errors.name) {
              let t = { ...this.state.touched };
              t.actualTemp = true;
              t.tempSetPoint = true;
              t.moveTo = true;
              this.setState({ touched: t, errors: errors });
              // e.preventDefault();
              return;
            }

            this.onSubmit("temp", taskUnderEdit);
            // e.preventDefault();
          }}
          onCancel={() => {
            this.setState({ temperatureModalOpen: false });
          }}
        />
        <CommentTaskModal
          open={this.state.commentTaskModalOpen}
          company={this.props.company}
          taskUnderEdit={this.state.taskUnderEdit}
          loadedComment={this.state.loadedComment}
          onSave={(taskUnderEdit) => {
            // this.setState({taskUnderEdit: taskUnderEdit});
            // taskUnderEdit.type = "comments";
            let errors = this.validate(this.validationSchema.bind(this))(
              this.state.taskUnderEdit
            );

            let errorsIsEmpty =
              Object.keys(errors).length === 0 && errors.constructor === Object;
            if (!errorsIsEmpty || this.state.errors.name) {
              let t = { ...this.state.touched };
              t.type = true;
              t.assetType = true;
              t.moveTo = true;
              this.setState({ touched: t, errors: errors });
              // e.preventDefault();
              return;
            }

            this.onSubmit("comments", taskUnderEdit);
          }}
          onCancel={() => this.setState({ commentTaskModalOpen: false })}
        />
        <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.state.loaded} message={this.spinner}>
            <ModalHeader className={"w-100"}>
              &nbsp; ADD / CHECK-IN ASSET
            </ModalHeader>
            <ModalBody>
              <Container>
                <Form id="Add_Asset_Form" onSubmit={this.handleSubmit}>
                  <Card className="simple-card">
                    <CardBody>
                      <div>
                        <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>Current Location</Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <span>{this.props.currentLocation.nickname}</span>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"Datetime(" +
                                  this.timezoneShortCode(
                                    this.props.currentLocation &&
                                    this.props.currentLocation.timezone,
                                    this.state.taskUnderEdit.start
                                  ) +
                                  ")"}
                                <span className={"text-danger"}>*</span>
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                placeholderText={"MM/DD/YYYY HH:MM AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.start &&
                                  "is-invalid"
                                }
                                selected={this.state.taskUnderEdit.start}
                                onChange={(date) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.accept = date;
                                  s.start = date;
                                  s.acceptDate = date;
                                  s.end = date;
                                  // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                                  if (date === null || !isNaN(date.getTime())) {
                                    this.setState({ taskUnderEdit: s });
                                  }
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm a"
                                showTimeInput
                                shouldCloseOnSelect={false}
                              />
                              <FormFeedback
                                className={"d-block"}
                                style={{ marginLeft: 0 }}
                              >
                                {this.state.errors && this.state.errors.start}
                              </FormFeedback>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FFFormSelectField
                          key={"ID_taskType"}
                          fieldname="type"
                          label="Task Type"
                          hideEmpty={true}
                          placeholder="Select the Task Type from Drop-Down (required)"
                          icon="fa-keynote"
                          value={
                            this.state.taskUnderEdit.type &&
                            this.state.taskUnderEdit.type.name
                          }
                          options={this.state.taskTypes}
                          checkered={true}
                          required={true}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.type = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.type}
                          touched={this.state.touched.type}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.type = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        {this.props.currentLocation.apiSetting !== null &&
                          this.props.currentLocation.apiSetting !== undefined &&
                          this.props.currentLocation.apiSetting.refernceApi && (
                            <FFFormLabelButton
                              label=""
                              isDisabled={
                                this.state.secRefNumber === null ? true : false
                              }
                              btnColor={"primary"}
                              isLblShow={false}
                              btnTxt="Reference Data"
                              fieldname="clientSecret"
                              onClick={() =>
                                this.openRefModal(
                                  this.state.selectedTask.refData,
                                  this.state.taskUnderEdit.secondaryRef.value
                                )
                              }
                            />
                          )}
                        {/* <HelpIcon className={"float-right"} helpMessage={"Reference number in disabled in edit task."} /> */}
                        {this.props.currentLocation.apiSetting !== null &&
                          this.props.currentLocation.apiSetting !== undefined &&
                          this.props.currentLocation.apiSetting.refernceApi && (
                            <FFFormAsyncSelectField
                              defaultOptions={false}
                              isClearable={true}
                              // isDisabled={true}
                              fieldname="secondaryRef"
                              hideEmpty={true}
                              label="Reference Number"
                              icon="fa-regular fa-hashtag"
                              placeholder="Select Your Company ID for the Shipment on this Trailer"
                              value={
                                this.state.taskUnderEdit.secondaryRef
                                  ? this.state.taskUnderEdit.secondaryRef
                                    .valueSubmit
                                  : ""
                              }
                              required={false}
                              loadOptions={
                                this.state.taskUnderEdit.location
                                  ? getAllSecondaryReferenceByRefID(
                                    this.state.taskUnderEdit.location.uuid
                                  )
                                  : getAllSecondaryReferenceByRefID
                              }
                              // isDisabled={this.state.secRefNumber}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };
                                touched.secondaryRef = true;
                                this.setState({
                                  errors: errors,
                                  touched: touched,
                                });
                              }}
                              error={this.state.errors.secondaryRef}
                              touched={this.state.touched.secondaryRef}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.secondaryRef = v;
                                if (v !== null) {
                                  if (s.isNew) {
                                    this.onChangeRefNum(v);
                                  }
                                  this.setState({
                                    taskUnderEdit: s,
                                    secRefNumber: v.valueSubmit,
                                  });
                                } else {
                                  this.setState({
                                    taskUnderEdit: s,
                                    secRefNumber: null,
                                  });
                                }
                                // this.onChangeRefNum(v);

                                // let touched = { ...this.state.touched };
                                // touched.assignee = true;
                                // this.setState({ taskUnderEdit: s, touched: touched });
                                // this.setState({ taskUnderEdit: s, secRefNumber: v.valueSubmit });
                              }}
                            />
                          )}
                        <FFFormAsyncSelectField
                          fieldname="carrier"
                          label="Trailer Name"
                          isClearable={true}
                          hideEmpty={true}
                          placeholder="Select the Trailer Carrier Name (from the list)"
                          icon="fa-truck"
                          value={
                            this.state.taskUnderEdit.carrier &&
                            this.state.taskUnderEdit.carrier.name
                          }
                          loadOptions={carriersByLocationUUid(
                            this.props.currentLocation.uuid
                          )}
                          defaultOptions={true}
                          checkered={true}
                          required={true}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.carrier = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.carrier}
                          touched={this.state.touched.carrier}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            if (
                              v == null ||
                              (s.carrier && s.carrier.uuid !== v.uuid)
                            ) {
                              s.asset = {};
                            }
                            if (v && v.__isNew__) {
                              v.name = v.label;
                            }
                            // if (s.asset) {
                            //   s.asset.carrier = v;
                            // }
                            s.carrier = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        {/* <FFFormSelectCreatableField
                          fieldname="carrier"
                          label="Trailer Name"
                          hideEmpty={true}
                          placeholder="Select the Trailer Carrier Name (from the list)"
                          icon="fa-truck"
                          value={
                            this.state.taskUnderEdit.carrier &&
                            this.state.taskUnderEdit.carrier.name
                          }
                          loadOptions={carriersByLocationUUid(
                            this.props.currentLocation.uuid
                          )}
                          defaultOptions={true}
                          checkered={true}
                          required={true}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.carrier = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.carrier}
                          touched={this.state.touched.carrier}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            if (
                              v == null ||
                              (s.carrier && s.carrier.uuid !== v.uuid)
                            ) {
                              s.asset = {};
                            }
                            if (v && v.__isNew__) {
                              v.name = v.label;
                            }
                            // if (s.asset) {
                            //   s.asset.carrier = v;
                            // }
                            s.carrier = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        ></FFFormSelectCreatableField> */}

                        <FFFormSelectCreatableField
                          key={
                            "ID_" +
                            (this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.uuid
                              : "")
                          }
                          fieldname="asset"
                          label="Trailer Number"
                          placeholder="Input/Select Trailer Number (Type Ahead, Drop Down)"
                          icon="fa-truck"
                          value={
                            this.state.taskUnderEdit.asset && this.state.taskUnderEdit.asset.assetId
                              ? this.state.taskUnderEdit.asset.assetId.replace(/ /g, '').toUpperCase()
                              : ""
                          }
                          isClearable={true}
                          isDisabled={!this.state.taskUnderEdit.carrier}
                          defaultOptions={true}
                          loadOptions={promiseAssetByPrefix(
                            this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.uuid
                              : ""
                          )}
                          hideEmpty={true}
                          checkered={true}
                          required={true}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.asset = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.asset}
                          touched={this.state.touched.asset}
                          onChange={(v) => {
                            console.log(v);
                            let s = { ...this.state.taskUnderEdit };
                            s.asset = v;
                            s.assetType =
                              v && v.equipment ? v.equipment.name : "";
                            if (v && v.__isNew__ === true) {
                              s.asset.assetId = s.asset.assetId.replace(/ /g, '').toUpperCase()
                              s.asset.label = s.asset.label.replace(/ /g, '').toUpperCase()
                              s.asset.value = s.asset.value.replace(/ /g, '').toUpperCase()
                            }
                            if (
                              v &&
                              v.latestTaskSpot != null &&
                              v.latestTaskSpot.location &&
                              v.latestTaskSpot.location.uuid ===
                              this.props.currentLocation.uuid
                            ) {
                              s.moveFrom = v.latestTaskSpot;
                            }


                            if (v && v.equipment && !v.equipment.temperatureControlled) {
                              s.tempSetPoint = null;
                              s.actualTemp = null;

                            }
                            if (v && v.equipment && !v.equipment.reefer) {
                              s.fuelLevel = null;
                              s.reefer = null;
                              s.continous = null;

                            }
                            this.setState({ taskUnderEdit: s });
                          }}
                        ></FFFormSelectCreatableField>
                        <FFFormAsyncSelectField
                          defaultOptions={true}
                          fieldname="assetType"
                          label="Asset Type"
                          icon="fa-spot"
                          // btnIcon="fa-temperature-low"
                          value={this.getAssetType()}
                          required={false}
                          isClearable={true}
                          placeholder="Asset Type - Select the Trailer Type"
                          hideEmpty={true}
                          // button
                          // btnHidden={
                          //   (this.state.taskUnderEdit.asset &&
                          //     this.state.taskUnderEdit.asset.equipment &&
                          //     this.state.taskUnderEdit.asset.equipment
                          //       .temperatureControlled) ||
                          //   (this.state.taskUnderEdit.assetType &&
                          //     this.state.taskUnderEdit.assetType
                          //       .temperatureControlled)
                          // }
                          btnEmptyState={this.getButtonState()}
                          onClick={() => {
                            this.setState({
                              taskUnderEdit: this.state.taskUnderEdit,
                              temperatureModalOpen: true,
                            });
                          }}
                          loadOptions={promiseEZTrackEquipmentTypeByPrefix(
                            this.props.currentLocation.uuid
                          )}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.assetType = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.assetType}
                          touched={this.state.touched.assetType}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            if (s.asset) {
                              s.asset.equipment = v === null ? this.state.defaultEquipmentType : v;
                            }
                            s.assetType = v;
                            if (
                              s.assetType &&
                              s.assetType.temperatureControlled !== true
                            ) {
                              s.tempSetPoint = null;
                              s.actualTemp = null;
                            }
                            if (s.assetType && s.assetType.reefer !== true) {
                              s.fuelLevel = null;
                              s.reefer = null;
                              s.continous = null;

                            }

                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        {((this.state.taskUnderEdit.asset &&
                          this.state.taskUnderEdit.asset.equipment &&
                          this.state.taskUnderEdit.asset.equipment
                            .reefer) ||
                          (this.state.taskUnderEdit.assetType &&
                            this.state.taskUnderEdit.assetType
                              .reefer || this.state.newAsset === true)) && (
                            <>
                              <FFFormAsyncSelectField
                                defaultOptions={true}
                                fieldname="fuelLevel"
                                label="Fuel Level"
                                hideEmpty={true}
                                placeholder="Select the fuel Level of the trailer (from the list)"
                                icon="fa-solid fa-gas-pump"
                                isClearable={true}
                                value={
                                  this.state.taskUnderEdit.fuelLevel
                                    ? this.state.taskUnderEdit.fuelLevel.label
                                    : ""
                                }
                                required={false}
                                loadOptions={promiseEZTrackEquipmentFuel}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.taskUnderEdit);
                                  let touched = { ...this.state.touched };

                                  touched.fuelLevel = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.fuelLevel}
                                touched={this.state.touched.fuelLevel}
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.fuelLevel = v;
                                  this.setState({ taskUnderEdit: s });
                                }}
                              />
                              <FFFormCheckboxField
                                fieldname="reefer"
                                icon="fa-power-off"

                                label="Reefer (On/Off )"
                                required={false}
                                value={
                                  this.state.taskUnderEdit.reefer == null
                                    ? false
                                    : this.state.taskUnderEdit.reefer
                                }
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.reefer = v;
                                  if (!v) { // If Reefer is false, set Continous to false as well
                                    s.continous = false;
                                  }
                                  this.setState({
                                    taskUnderEdit: s,
                                  });
                                }}
                              />
                              <FFFormCheckboxField
                                fieldname="continous"
                                icon="fa-power-off"
                                label="Continuous (Yes/No)"

                                disabled={!this.state.taskUnderEdit.reefer}
                                required={false}
                                value={
                                  this.state.taskUnderEdit.continous == null
                                    ? false
                                    : this.state.taskUnderEdit.continous
                                }
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.continous = v;
                                  this.setState({
                                    taskUnderEdit: s,
                                  });
                                }}
                              />



                            </>
                          )}
                        {((this.state.taskUnderEdit.asset &&
                          this.state.taskUnderEdit.asset.equipment &&
                          this.state.taskUnderEdit.asset.equipment
                            .temperatureControlled) ||
                          (this.state.taskUnderEdit.assetType &&
                            this.state.taskUnderEdit.assetType
                              .temperatureControlled) ||
                          this.state.newAsset === true) && (
                            <>

                              <FFFormTextField
                                fieldname="tempSetPoint"
                                isClearable={true}
                                label={
                                  "Temp - Set Point (" +
                                  (this.props.company.temperatureUnitsOfMeasure ===
                                    "Fahrenheit"
                                    ? "℉"
                                    : "℃") +
                                  ")"
                                }
                                placeholder="Enter the temperature set point"
                                icon="fa-temperature-low"
                                value={this.state.taskUnderEdit.tempSetPoint}
                                numbersOnly={false}
                                required={false}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.taskUnderEdit);
                                  let touched = { ...this.state.touched };
                                  touched.tempSetPoint = true;
                                  this.setState({
                                    errors: errors,
                                    touched: touched,
                                  });
                                }}
                                error={this.state.errors.tempSetPoint}
                                touched={this.state.touched.tempSetPoint}
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.tempSetPoint = v;
                                  this.setState({ taskUnderEdit: s });
                                }}
                              />



                            </>

                          )}
                        {((this.state.taskUnderEdit.asset &&
                          this.state.taskUnderEdit.asset.equipment &&
                          this.state.taskUnderEdit.asset.equipment
                            .temperatureControlled) ||
                          (this.state.taskUnderEdit.assetType &&
                            this.state.taskUnderEdit.assetType
                              .temperatureControlled) ||
                          this.state.newAsset === true) && (
                            <FFFormTextField
                              fieldname="actualTemp"
                              isClearable={true}
                              label={
                                "Temp - Actual (" +
                                (this.props.company.temperatureUnitsOfMeasure ===
                                  "Fahrenheit"
                                  ? "℉"
                                  : "℃") +
                                ")"
                              }
                              placeholder="Enter the actual temperature"
                              icon="fa-temperature-high"
                              value={this.state.taskUnderEdit.actualTemp}
                              numbersOnly={false}
                              required={false}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };
                                touched.actualTemp = true;
                                this.setState({
                                  errors: errors,
                                  touched: touched,
                                });
                              }}
                              error={this.state.errors.actualTemp}
                              touched={this.state.touched.actualTemp}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.actualTemp = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />
                          )}
                        <FFFormAsyncSelectField
                          defaultOptions={true}
                          fieldname="assetStatus"
                          label="Asset Status"
                          hideEmpty={true}
                          placeholder="Select the status of the trailer (from the list)"
                          icon="fa-balance-scale-left"
                          isClearable={true}
                          value={
                            this.state.taskUnderEdit.assetStatus
                              ? this.state.taskUnderEdit.assetStatus.name
                              : ""
                          }
                          required={false}
                          loadOptions={promiseAssetStatusesByLocationUUid(
                            this.props.currentLocation.uuid
                          )}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.assetStatus = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.assetStatus}
                          touched={this.state.touched.assetStatus}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.assetStatus = v;

                            this.setState({ taskUnderEdit: s });
                          }}
                        />

                        <FFFormSelectField
                          // defaultOptions={true}
                          isClearable={false}
                          fieldname="moveTo"
                          label="Move To Spot"
                          icon="fa-stop"
                          placeholder="Select Move To Spot (Type Ahead,  Drop Down)"
                          hideEmpty={true}
                          value={
                            this.state.taskUnderEdit.moveTo
                              ? this.state.taskUnderEdit.moveTo.uuid
                                ? this.state.taskUnderEdit.moveTo.zone +
                                " - " +
                                this.state.taskUnderEdit.moveTo.name
                                : this.state.taskUnderEdit.moveTo.zone
                              : null
                          }
                          required={true}
                          options={this.state.moveToSpots}
                          onBlur={() => {
                            let touched = { ...this.state.touched };
                            touched.moveTo = true;
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.moveTo}
                          touched={this.state.touched.moveTo}
                          onChange={(v) => {
                            this.setState({ SpotonSite: false });
                            if (this.props.currentLocation.enableSpotAvailability == true) {


                              let s = { ...this.state.taskUnderEdit };
                              s.moveTo = v;
                              let touched = { ...this.state.touched };
                              touched.moveTo = true;
                              this.setState({ taskUnderEdit: s, touched: touched });
                              let isany = s.moveTo.value.toLowerCase().includes("any") ? false : true;
                              if (v && s.location && s.priority && isany) {
                                console.log(s, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, s.location.uuid, 2, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });
                              }
                              else if (v && s.priority && isany) {
                                console.log(s, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, 2, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });
                              }
                              else if (v && s.location && isany) {
                                console.log(s, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, s.location.uuid, 2, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });
                              }
                              else if (v && isany) {
                                console.log(v, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });


                              }

                            }

                            else {

                              let s = { ...this.state.taskUnderEdit };
                              s.moveTo = v;
                              let touched = { ...this.state.touched };
                              touched.moveTo = true;
                              this.setState({ taskUnderEdit: s, touched: touched });


                            }




                          }}
                        />
                        <FFFormTextField
                          fieldname="shipmentNumber"
                          label="Shipment Number"
                          placeholder="Enter the shipment number"
                          icon="fa-tag"
                          value={
                            this.state.taskUnderEdit.shipmentNumber
                              ? this.state.taskUnderEdit.shipmentNumber
                              : ""
                          }
                          required={false}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.shipmentNumber = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.shipmentNumber}
                          touched={this.state.touched.shipmentNumber}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.shipmentNumber = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        <FFFormCheckboxField
                          fieldname="sealed"
                          icon="fa-power-off"
                          label="Sealed"
                          required={false}
                          value={
                            this.state.taskUnderEdit.sealed == null
                              ? false
                              : this.state.taskUnderEdit.sealed
                          }
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.sealed = v;
                            this.setState({
                              taskUnderEdit: s,
                              showSealNumber: v,
                            });
                          }}
                        />
                        {this.state.taskUnderEdit.sealed && (
                          <FFFormTextField
                            fieldname="sealNumber"
                            label="Seal #"
                            placeholder="Enter the seal number"
                            icon="fa-tag"
                            value={
                              this.state.taskUnderEdit.sealNumber
                                ? this.state.taskUnderEdit.sealNumber
                                : ""
                            }
                            required={false}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.sealNumber = true;
                              this.setState({
                                errors: errors,
                                touched: touched,
                              });
                            }}
                            error={this.state.errors.sealNumber}
                            touched={this.state.touched.sealNumber}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.sealNumber = v;
                              this.setState({ taskUnderEdit: s });
                            }}
                          />
                        )}
                        <FFFormTextAreaField
                          label="Comments"
                          required={false}
                          placeholder="Enter Task Comments"
                          icon="fa-comment"
                          fieldname="comments"
                          isClearable={true}
                          error={this.state.errors.comments}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.comments = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          touched={this.state.touched.comments}
                          value={this.state.taskUnderEdit.comments}
                          onChange={(event) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.comments =
                              event !== null ? event.target.value : null;
                            this.setState({ taskUnderEdit: s });
                          }}
                          rows={"5"}
                        />
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mb-3 text-right">
                    <Button
                      className={"float-left"}
                      color="danger"
                      onClick={() => {
                        this.setState({
                          open: false,
                          addingAsset: false,
                          errors: [],
                          touched: [],
                          showSealNumber: false,
                          secRefNumber: null,
                        });
                        this.props.onCancel && this.props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    &nbsp;&nbsp;
                    <Button
                      color="primary"
                      type="button"
                      onClick={(e) => {
                        if (this.state.SpotonSite === true) {
                          confirmAlert({

                            customUI: ({ onClose }) => {
                              return (
                                <div style={{
                                  fontFamily: 'Arial, Helvetica, sans-serif',
                                  width: '400px',
                                  padding: '30px',
                                  textAlign: 'left',
                                  background: '#fff',
                                  borderRadius: '10px',
                                  boxShadow: '0 20px 75px rgba(0, 0, 0, .13)',
                                  color: '#666'
                                }}>
                                  <h1 style={{ color: '#4a6670', fontSize: '24px', fontWeight: '700', lineHeight: '30px' }}>Spot Already Occupied</h1>

                                  This spot is already occupied or will be occupied by a Task with equal or higher Priority to your Task.  Do you still want to continue?
                                  <br />

                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Task Status:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.taskStatus === "IsAssinged" ? "Assigned" : this.state.SpotonSiteData.taskStatus}
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Trailer Name:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.trailerName}
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Trailer Type:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.trailerType}
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Trailer Number:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.trailerNumber}
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Trailer Status:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.assetStatus}
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Shipment Number:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.shipmentNumber}
                                    </h1>
                                  </h1>
                                  <br />

                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Created By:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.createdBy}
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Created Time:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      <MomentDisplay
                                        date={this.state.SpotonSiteData.createdDate}
                                        timezone={
                                          this.props.currentLocation &&
                                          this.props.currentLocation.timezone
                                        }
                                      />
                                    </h1>
                                  </h1>
                                  <br />
                                  <h1 style={{
                                    color: '#4a6670',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '30px',
                                    display: 'inline'
                                  }}>
                                    Task Priority:
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '12px',
                                      fontWeight: '550',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      {this.state.SpotonSiteData.taskPriority === 1 && 'Low'}
                                      {this.state.SpotonSiteData.taskPriority === 2 && 'Normal'}
                                      {this.state.SpotonSiteData.taskPriority === 3 && 'High'}
                                      {!this.state.SpotonSiteData.taskPriority && 'Normal'}

                                    </h1>
                                  </h1>
                                  <br />


                                  <Button className="btn btn-danger" color="danger"
                                    onClick={(e) => {
                                      onClose();
                                    }} >Return</Button>
                                  &nbsp;&nbsp;
                                  <Button className="btn btn-primary" color="primary"
                                    onClick={(e) => {
                                      this.handleSubmit(e, true); onClose();
                                    }} >Proceed</Button>

                                </div>
                              );
                            },
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                            overlayClassName: "overlay-custom-class-name",
                          });
                        }

                        else {
                          this.handleSubmit(e, true);
                        }
                      }}
                    >
                      Save &#38; Close
                    </Button>
                  </div>
                </Form>
              </Container>
            </ModalBody>
          </Loader>
        </Modal>
      </>
    );
  }
  setCloseVariable(val) {
    this.setState({ closeCheck: val });
  }
  getButtonState() {
    if (
      this.state.taskUnderEdit.start === undefined ||
      this.state.taskUnderEdit.start === null ||
      this.state.taskUnderEdit.type === undefined ||
      this.state.taskUnderEdit.type === null ||
      this.state.taskUnderEdit.asset === null ||
      this.state.taskUnderEdit.asset === undefined ||
      this.state.taskUnderEdit.carrier === undefined ||
      this.state.taskUnderEdit.carrier === null ||
      this.state.taskUnderEdit.moveTo === null ||
      this.state.taskUnderEdit.moveTo === undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  // check

  // onSubmit(){
  //     return this.props.onSave && this.props.onSave(this.state && this.state.task);
  // }
}

export default AddAssetModal;
