import React, { Component } from "react";
// import { PaginationFilterTableArrival } from "../../common/PaginationFilterTableArrival";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";

import MomentDisplay from "../../assets/components/MomentDisplay";
import { ClipboardClickable } from "../../common/ClipboardClickable";
import {
  getArrivalInfoByArrivald,
  postUserDefaultLocation,
  postMessagePWA,
  poweryardArrival,
  putTaskCancel,
  postUserDefaultShift,
  putArrivalStatusChange,
  GetPubSubLinkWWS,
  connectWebsocket,
  makeConnectionUsingWebsocket,
  postArrivalInfoByArrivald,
  postSaveArrival,
  putCheckInCheckOutStatusChange,
  updateArrivalDepart,
  checkFlagForCheckInCheckOut,
  UpdateArrivalCheckIn,
  UpdateArrivalComplete,
  UpdateArrivalCheckOut,
  GetOrganizationShortName
} from "../../NetworkHelpers";
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from "reactstrap";
import { ChangeLocationFormField } from "../../Dashboard/Main/components/CurrentLocation";
import { ChangeShiftFormField } from "../../Dashboard/Main/components/CurrentShift";
import { withRouter } from "react-router";
import { getLocalStorageName, getTableSize, endOfDay, startOfDay } from "../../common/utilities";
import * as PropTypes from "prop-types";
import { showError, showInfo } from "../../MainApp";
import TableDateRangePicker from "../../common/TableDateRangePicker";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import Switch from "react-switch";
import _uniqueId from 'lodash/uniqueId';
import SMSModal from "../../common/poweryard/SMSModal";
import AttachmentsModal from "../../common/poweryard/AttachmentsModal";
import EditGateModal from "../../common/poweryard/EditGateModal";
import Config from "../../Config";
import ShowCustomList from "../../common/poweryard/ShowCustomList";
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { PaginationFilterTableArrival } from "../../common/PaginationFilterTableArrival";
import CreateGateModal from "../../common/poweryard/CreateGateModal";
class ArrivalModel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1>Arrival Model</h1>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <h6>Do you want to complete this Arrival manually?</h6>
            </Row>
            <Row>
              <Col className="text-right" style={{ marginTop: "10px" }} >
                <Button color="primary" onClick={() => this.props.onClose && this.props.onClose()} >Yes</Button> &nbsp;
                <Button color="danger" onClick={() => this.props.onClose && this.props.onClose()} type="submit">No</Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    )
  }
}

ArrivalModel.propTypes = {
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  onChange: PropTypes.func
};


export default class Arrival extends Component {
  latestLocation = {
    longitude: '',
    latitude: ''
  };
  enabledNotification = [];
  tableColumns = [

    {
      Header: 'PROCESS',
      accessor: 'PROCESS',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        backgroundColor: '#bed6ef'
      },
      filterable: false,
      default: true,
      sortable: false,
      disableControls: true,
      Cell: (row) => {
        return <>
          {row.original.commandstatus !== 'arrive' ?

            <Button
              disabled={(!row.original.atCheckOut)}
              title="DEPARTURE"
              color='white'
              aria-label="Departure"
              className={"btn-danger"}
              onClick={() => {
                this.setState({ loaded: true })
                this.updateSpinner("")


                let obj = {
                  forceCommand: row.original.commandstatus,
                  arrivalID: row.original.arrivalID,
                  TimeZoneID: this.props.currentLocation.timezone
                };
                let v = row.original;
                v.eventStatus = "Complete";
                v.eventStatusId = "10";
                getArrivalInfoByArrivald(v.arrivalID, (res) => {
                  if (res.statusCode === "200") {



                    v.customFieldList = res.data.customFields;

                    postArrivalInfoByArrivald(v, (result) => {

                      if (result.statusCode == "200") {
                        this.showInfo("Task Departed Successfully");
                        this.tableRef.current.requestPageSearch();
                        this.setState({ loaded: false })
                      } else {
                        showError("Some error occured.");
                      }
                    },
                      (err) => {
                        // showError("Some error occured.");

                      }
                    );


                  }

                },
                  (err) => {
                    showError(err);
                  }
                );

                this.updateSpinner("Task Departing...")

              }}
            // onClick={() =>
            //   this.setState({ isOpenEdit: true, selectedArrival: row.original, btnStr: 'Save & Depart' })

            // }

            >
              <i className="fa fa-truck fa-flip-horizontal" ></i>
            </Button>

            :

            <Button
              title="ARRIVAL"
              color='white'
              aria-label="Arrival"
              // disabled={this.state.profeature &&this.state.disablearrival ? (
              //  row.original.atCheckIn  ? false  :true
              // ) : false}
              className={"btn-success"}
              // onClick={() => {
              //   let obj = {
              //     forceCommand: row.original.commandstatus,
              //     arrivalID: row.original.arrivalID,
              //     TimeZoneID: this.props.currentLocation.timezone
              //   };
              //   this.updateSpinner("Task Arrived.")
              //   this.setState({ loaded: true })
              //   putArrivalStatusChange(obj, (res) => {
              //     if (res.statusCode === "200") {
              //       this.tableRef.current.requestPageSearch();
              //       // this.setState({ loaded: false })
              //     } else {
              //       showError("Some error occured.");
              //     }
              //   },
              //     (err) => {
              //       showError(err);
              //     }
              //   );
              // }}>
              onClick={() => this.setState({ isOpenEdit: true, selectedArrival: row.original, btnStr: 'Save & Arrive' })}>
              <i className="fa fa-truck" ></i>
            </Button>
          }
        </>;


      }
    },
    {
      Header: 'Actions',
      accessor: 'totalSMS',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      filterable: false,
      style: {
        backgroundColor: '#bed6ef',

      },
      default: true,
      sortable: false,
      disableControls: true,
      width: 190,
      Cell: (row) => {
        return <>
          {/* <Row> */}
          {this.state.planId === 1 &&
            <Button
              color={row.original.totalSMS == 0 ? "secondary" : parseInt(row.original.unreadSMS) > 0 ? "danger" : "primary"}
              title="MSG"
              aria-label="MSG"
              className=""
              onClick={() => this.setState({ isOpen: true, selectedArrival: row.original })}>
              {/* <i className="fas fa-sms "  ></i> */}
              <i class="fal fa-comment-alt-lines"></i>
              {/* <i class="fa-solid fa-comment-sms"></i> */}
            </Button>
          }
          {parseInt(row.original.unreadSMS) > 0 &&

            <span className="button__badge">{row.original.unreadSMS}</span>
          }
          &nbsp;
          <Button
            color={!row.original.totalAttachments || row.original.totalAttachments == "0" ? "secondary" : "primary"}
            title="Attachments"
            aria-label="Attachments"
            disabled={!row.original.totalAttachments || row.original.totalAttachments == "0"}
            onClick={() => this.setState({ isOpenAttachment: true, selectedArrival: row.original })}>
            <i className="fa fa-paperclip "  ></i>

          </Button>
          &nbsp;
          <Button
            title="Edit"
            aria-label="Edit"
            // disabled={row.original.totalAttachments == "0" }
            onClick={() => this.setState({ isOpenEdit: true, selectedArrival: row.original })}>
            <i className="fa fa-edit "  ></i>

          </Button>
          {/* &nbsp;
          <Button
            title="Show Custom Fields"
            aria-label="Show Custom Fields"
            // disabled={row.original.totalAttachments == "0" }
            onClick={() => this.setState({ isOpenList: true, selectedArrival: row.original })}>
            <i class="fa fa-list"></i>

          </Button> */}



          {/* <UncontrolledDropdown
              className="d-flex"
              direction="right"
            >

              <DropdownToggle
                // caret

                color="secondary"
              >
                <i class="fas fa-ellipsis-v-alt"></i>
              </DropdownToggle>
              <DropdownMenu  >
                <DropdownItem header>
                  <Button
                    color={row.original.totalAttachments == "0" ? "secondary" : "primary"}
                    title="Attachments"
                    aria-label="Attachments"
                    disabled={row.original.totalAttachments == "0"}
                    onClick={() => this.setState({ isOpenAttachment: true, selectedArrival: row.original })}>
                    <i className="fa fa-paperclip "  ></i>

                  </Button>
                  &nbsp;
                  <Button
                    title="Edit"
                    aria-label="Edit"
                    // disabled={row.original.totalAttachments == "0" }
                    onClick={() => this.setState({ isOpenEdit: true, selectedArrival: row.original })}>
                    <i className="fa fa-edit "  ></i>

                  </Button>
                  &nbsp;
                  <Button
                    title="Show Custom Fields"
                    aria-label="Show Custom Fields"
                    // disabled={row.original.totalAttachments == "0" }
                    onClick={() => this.setState({ isOpenList: true, selectedArrival: row.original })}>
                    <i class="fa fa-list"></i>

                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          {/* </Row> */}
        </>

      }
    },
    // {
    //   Header: 'Attachments',
    //   accessor: 'totalAttachments',
    //   headerStyle: {
    //     backgroundColor: '#2e6c93',
    //   },
    //   filterable: false,
    //   style: {
    //     backgroundColor: '#bed6ef'
    //   },
    //   default: true,
    //   sortable: false,
    //   disableControls: true,
    //   Cell: (row) => {
    //     return <>


    //     </>

    //   }
    // },
    // {
    //   Header: 'Edit',
    //   accessor: 'edit',
    //   headerStyle: {
    //     backgroundColor: '#2e6c93',
    //   },
    //   filterable: false,
    //   style: {
    //     backgroundColor: '#bed6ef'
    //   },
    //   default: true,
    //   sortable: false,
    //   disableControls: true,
    //   Cell: (row) => {
    //     return <>


    //     </>

    //   }
    // },
    {
      accessor: 'arrivalID',
      Header: 'Arrival ID',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: false,
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivalID.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalID}>
              {row.original.arrivalID}
            </ClipboardClickable>
          )
        } else {
          return '';
        }
      })
    },


    {
      accessor: 'eta',
      Header: 'ETA',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: true,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.eta) {
          return <MomentDisplay date={arr.eta + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'createdDate',
      Header: 'Created Date',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: true,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.createdDate) {
          return <MomentDisplay date={arr.createdDate + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return ' ';
        }
      }
    },
    {
      accessor: 'arrivalendtime',
      Header: 'Arrival Time',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: true,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.arrivalendtime) {
          return <MomentDisplay date={arr.arrivalendtime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      // accessor: 'eventStatus',
      accessor: 'nextScreenStatus',
      Header: 'Event Status',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: true,
      filterable: false,
      sortable: false,

    },
    {
      accessor: 'firstname',
      Header: 'Driver First ',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: true,
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.firstname.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.firstname}>
              {row.original.firstname}
            </ClipboardClickable>
          )
        } else {
          return '';
        }
      })
    },
    {
      accessor: 'lastname',
      Header: 'Driver Last ',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      default: true,
      style: {
        color: 'black',
        backgroundColor: '#bed6ef',

      },
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.lastname.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.lastname}>
              {row.original.lastname}
            </ClipboardClickable>
          )
        } else {
          return '';
        }



      })
    },
    {
      accessor: 'trailername',
      Header: 'Carrier Name',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      default: false,
      style: {
        color: 'black',
        backgroundColor: '#bed6ef',

      },
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.trailername.toLowerCase() !== 'n/a') {
          const formattedTrailerName = row.original.trailername.toUpperCase().replace(/\s+/g, '');

          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={formattedTrailerName}>
              {formattedTrailerName}
            </ClipboardClickable>
          )
        } else {
          return '';
        }



      })
    },
    {
      accessor: 'trucknumber',
      Header: 'Truck #',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      default: true,
      style: {
        color: 'black',
        backgroundColor: '#bed6ef',

      },
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.trucknumber.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.trucknumber}>
              {row.original.trucknumber}
            </ClipboardClickable>
          )
        } else {
          return '';
        }



      })
    },

    {
      accessor: 'arrivalappt',
      Header: 'Inbound Appt',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.arrivalappt) {
          return <MomentDisplay date={arr.arrivalappt + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },





    {
      accessor: 'arrivalreference',
      Header: 'Inbound ' + (this.props.user.company.shipmentIdDisplay ? this.props.user.company.shipmentIdDisplay : 'Ref') + ' #',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      className: "clipboardShow",
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.arrivalreference.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalreference}>
              {row.original.arrivalreference}
            </ClipboardClickable>
          )
        } else {
          return '';
        }



      })
    },
    {
      accessor: 'arrivalshipmentnumber',
      Header: 'Inbound Shipment Number',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: true,
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivalshipmentnumber) {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalshipmentnumber}>
              {row.original.arrivalshipmentnumber}
            </ClipboardClickable>
          )
        } else {
          return ' ';
        }



      })
    },

    {
      accessor: 'arrivaltrailername',
      Header: 'Inbound Trlr Name',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: true,
      sortable: true,
      Cell:(row=>{

        if (row.original.arrivaltrailername) {
          return row.original.arrivaltrailername.toUpperCase();
        }
        else {
          return '';
        }

      })
    },
    {
      accessor: 'arrivaltrailertypename',
      Header: 'Inbound Trlr Type',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: true,
      sortable: true,
    },

    {
      accessor: 'arrivaltrailernumber',
      Header: 'Inbound Trlr #',
      default: true,
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      filterable: true,
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivaltrailernumber) {
          const number = row.original.arrivaltrailernumber.toUpperCase().replace(/\s+/g, '');
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={number}>
              {number}
            </ClipboardClickable>
          )
        } else {
          return ' ';
        }



      })
    },
    {
      accessor: 'arrivalAssetTypeName',
      Header: 'Inbound Asset Type',
      default: true,
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      filterable: true,
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivalAssetTypeName.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalAssetTypeName}>
              {row.original.arrivalAssetTypeName}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }



      })
    },
    {
      accessor: 'arrivalSpotName',
      Header: 'Inbound Spot Name',
      default: true,
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      filterable: true,
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivalSpotName.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalSpotName}>
              {row.original.arrivalSpotName}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }



      })
    },
    {
      accessor: 'arrivalsealed',
      Header: 'Inbound Sealed',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: false,
      sortable: false,
      Cell: (row => {
        if (row.original.arrivalsealno && row.original.arrivalsealno !== "" && row.original.arrivalsealno.toLowerCase() !== "n/a") {
          return ("Yes")
        } else {
          return ("No")
        }
      })
    },
    {
      accessor: 'arrivalsealno',
      Header: 'Inbound Seal #',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: false,
      sortable: false,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivalsealno.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalsealno}>
              {row.original.arrivalsealno}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }



      })
    },
   
    {
      accessor: 'arrivalcomment',
      Header: 'Inbound Comments',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: false,
      filterable: true,
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.arrivalcomment) {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.arrivalcomment}>
              {row.original.arrivalcomment}
            </ClipboardClickable>
          )
        } else {
          return ' ';
        }



      })
    },
    {
      accessor: 'arrivalstatus',
      Header: ' Inbound Trlr Status',
      default: true,
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8',
      },
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.arrivalstatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.arrivalstatus) return "";
        return <>
          {row.original.arrivalstatus.charAt(0).toUpperCase() + row.original.arrivalstatus.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.arrivalstatusFilterRef}
            onChange={event => {
              this.setState({ arrivalStatusFilter: event.target.value },
              );
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.arrivalStatusFilter ? this.state.arrivalStatusFilter : undefined}
          >
            <option value=''>All</option>
            <option value="Bobtail">Bobtail</option>
            <option value="Loaded">Loaded</option>
            <option value="Empty">Empty</option>

          </select>
        </>
    },
    {
      accessor: 'arrivalplan',
      Header: 'Inbound Plan',
      headerStyle: {
        backgroundColor: '#7896b5'
      },
      style: {
        color: 'black',
        backgroundColor: '#deebf8'
      },
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.arrivalplan === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.arrivalplan) return "";
        return <>
          {row.original.arrivalplan.charAt(0).toUpperCase() + row.original.arrivalplan.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.arrivalplanFilterRef}
            onChange={event => {
              this.setState({ arrivalPlanFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.arrivalPlanFilter ? this.state.arrivalPlanFilter : undefined}
          >

            <option value=''>All</option>
            <option value="Hook">Hook</option>
            <option value="live">Live</option>
            <option value="drop">Drop</option>

          </select>
        </>
    },


    {
      accessor: 'departureappt',
      Header: 'Outbound Appt',
      default: false,
      headerStyle: {
        backgroundColor: '#557087'
      },
      filterable: false,
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.departureappt) {
          return <MomentDisplay date={arr.departureappt + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },

    {
      accessor: 'departurereference',
      Header: 'Outbound ' + (this.props.user.company.shipmentIdDisplay ? this.props.user.company.shipmentIdDisplay : 'Ref') + '#',
      default: false,
      headerStyle: {
        backgroundColor: '#557087'
      },
      filterable: true,
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      sortable: true,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.departurereference.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.departurereference}>
              {row.original.departurereference}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }



      })
    },

    {
      accessor: 'departshipmentnumber',
      Header: 'Outbound Shipment Number',
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.departshipmentnumber) {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.departshipmentnumber}>
              {row.original.departshipmentnumber}
            </ClipboardClickable>
          )
        } else {
          return ' ';
        }



      })
    },
    {
      accessor: 'departuretrailername',
      Header: 'Outbound Trlr Name',
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      default: false,
      filterable: true,
      sortable: true,
      Cell:(row=>{

        if (row.original.departuretrailername) {
          return row.original.departuretrailername.toUpperCase();
        }
        else {
          return '';
        }

      })
    },
    {
      accessor: 'departuretrailertypename',
      Header: 'Outbound Trlr Type',
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      default: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'departuretrailerno',
      Header: 'Outbound Trlr #',
      default: true,
      headerStyle: {
        backgroundColor: '#557087'
      },
      width: 160,
      filterable: true,
      sortable: true,

      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.departuretrailerno) {

          const dep = row.original.departuretrailerno.toUpperCase().replace(/\s+/g, '');;
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={dep}>
              {dep}
            </ClipboardClickable>
          )
        } else {
          return '';
        }

      })
    },
    {
      accessor: 'departAssetTypeName',
      Header: 'Outbound Asset Type',
      default: true,
      headerStyle: {
        backgroundColor: '#557087'
      },
      width: 160,
      filterable: true,
      sortable: true,

      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.departAssetTypeName.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.departAssetTypeName}>
              {row.original.departAssetTypeName}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }

      })
    },
    {
      accessor: 'departSpotName',
      Header: 'Outbound Spot Name',
      default: true,
      headerStyle: {
        backgroundColor: '#557087'
      },
      width: 160,
      filterable: true,
      sortable: true,

      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.departSpotName.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.departSpotName}>
              {row.original.departSpotName}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }

      })
    },
    {
      accessor: 'departuresealed',
      Header: 'Outbound Sealed',
      headerStyle: {
        backgroundColor: '#557087'
      },

      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      default: false,
      filterable: false,
      sortable: false,
      className: "clipboardShow",
      Cell: (row => {
        if (row.original.departuresealno && row.original.departuresealno !== "" && row.original.departuresealno.toLowerCase() !== "n/a") {
          return ("Yes")
        } else {
          return ("No")
        }
      })

    },
    {
      accessor: 'departuresealno',
      Header: 'Outbound Seal #',
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      default: false,
      filterable: false,
      sortable: false,
      Cell: (row => {
        if (row.original.departuresealno.toLowerCase() !== 'n/a') {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.departuresealno}>
              {row.original.departuresealno}
            </ClipboardClickable>
          )
        } else {
          return 'N/A';
        }



      })
    },
  
    {
      accessor: 'departcomment',
      Header: 'Outbound Comments',
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      default: false,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.departcomment) {
          return (
            <ClipboardClickable
              id={this.id}
              clickClass={"hide"}
              value={row.original.departcomment}>
              {row.original.departcomment}
            </ClipboardClickable>
          )
        } else {
          return ' ';
        }



      })
    },
    {
      accessor: 'departurestatus',
      Header: 'Outbound Trlr Status',
      default: true,
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.departurestatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.departurestatus) return "";
        return <>
          {row.original.departurestatus.charAt(0).toUpperCase() + row.original.departurestatus.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.departurestatusFilterRef}
            onChange={event => {
              this.setState({ departureStatusFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.departureStatusFilter ? this.state.departureStatusFilter : undefined}
          >
            <option value=''>All</option>
            <option value="Bobtail">Bobtail</option>
            <option value="Loaded">Loaded</option>
            <option value="Empty">Empty</option>
          </select>
        </>
    },


    {
      accessor: 'departureplan',
      Header: 'Outbound Plan',
      default: true,
      headerStyle: {
        backgroundColor: '#557087'
      },
      style: {
        color: 'black',
        backgroundColor: '#c0d7ee'
      },
      width: 150,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.departurestatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.departureplan) return "";
        return <>
          {row.original.departureplan.charAt(0).toUpperCase() + row.original.departureplan.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.departureplanFilterRef}
            onChange={event => {
              this.setState({ departurePlanFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.departurePlanFilter ? this.state.departurePlanFilter : undefined}
          >
            <option value=''>All</option>
            <option value="Hook">Hook</option>
            <option value="live">Live</option>
            <option value="drop">Drop</option>
          </select>
        </>
    },
    {
      accessor: 'lastUpdatedDate',
      Header: 'Last Update Time',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.lastUpdatedDate) {
          return <MomentDisplay date={arr.lastUpdatedDate + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'checkinUsername',
      Header: 'Check-In User',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: false,
      filterable: false,
      sortable: true,
    },
    {
      accessor: 'checkinDatetime',
      Header: 'Check-In DateTime',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.checkinDatetime) {
          return <MomentDisplay date={arr.checkinDatetime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },
    // {
    //   accessor: 'departUsername',
    //   Header: 'Depart User',
    //   headerStyle: {
    //     backgroundColor: '#2e6c93',
    //   },
    //   style: {
    //     color: 'black',
    //     backgroundColor: '#bed6ef'
    //   },
    //   default: false,
    //   filterable: false,
    //   sortable: true,
    // },
    // {
    //   accessor: 'departDatetime',
    //   Header: 'Depart DateTime',
    //   headerStyle: {
    //     backgroundColor: '#2e6c93',
    //   },
    //   style: {
    //     color: 'black',
    //     backgroundColor: '#bed6ef'
    //   },
    //   default: false,
    //   filterable: false,
    //   sortable: true,
    //   Cell: row => {
    //     let arr = row.original;
    //     if (arr.departDatetime) {
    //       return <MomentDisplay date={arr.departDatetime + "Z"}
    //         timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
    //     } else {
    //       return 'N/A';
    //     }
    //   }
    // },
    {
      accessor: 'checkOutUsername',
      Header: 'Check-Out User',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: false,
      filterable: false,
      sortable: true,
    },
    {
      accessor: 'checkOutDatetime',
      Header: 'Check-Out DateTime',
      headerStyle: {
        backgroundColor: '#2e6c93',
      },
      style: {
        color: 'black',
        backgroundColor: '#bed6ef'
      },
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.checkOutDatetime) {
          return <MomentDisplay date={arr.checkOutDatetime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },

  ]
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }


  constructor(props) {

    super(props);
    this.id = _uniqueId("ID_");
    this.tableRef = React.createRef();
    this.updateSpinner();
    // this.taskStatusFilterRef = React.createRef();
    localStorage.removeItem('eventsListForNotifications');
    const parse = JSON.parse(localStorage.getItem(getLocalStorageName("poweryard-arrival", props.user)), this.mapFromJson);
    const enabledNotification = JSON.parse(localStorage.getItem(getLocalStorageName("enabledNotification", props.user)), this.mapFromJson)

    if (parse) {
      this.state = {

        disablearrival: true,
        disablecheckin: true,
        disabledeparture: true,
        disablearrivalenabled: true,
        disabledepartureenabled: true,
        profeature: parse.profeature,
        name: null,
        baseFilter: parse.baseFilter,
        previousSearchRequest: parse.previousSearchRequest,
        taskStatusFilter: parse.taskStatusFilter,
        // filterDateType: parse.filterDateType,
        typeFilterEnabled: parse.typeFilterEnabled,
        typeFilterField: parse.typeFilterField,
        FilterEnabled: parse.FilterEnabled,
        tableSize: parse.tableSize,
        columnSizes: parse.columnSizes,
        allLocations: [],
        allCompanies: [],
        showFilterCollaps: false,
        currentLocationObj: {},
        currentCompaniesObj: {},
        loaded: false,
        checkForFlag: true,
        enableDepart: false,
        tableColumns: this.tableColumns,
        planId: undefined
      };
    } else {
      this.state = {
        disablearrival: true,
        disablecheckin: true,
        disabledeparture: true,
        disablearrivalenabled: true,
        disabledepartureenabled: true,
        profeature: false,
        name: null,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            //   { id: 'LocationUuid', value: this.props.currentLocation ? this.props.currentLocation.uuid : "null" },
            // { id: 'OrganizationUuid', value: this.props.user.company ? this.props.user.company.uuid : "null" },
            // { id: "start", value: startOfDay(new Date(new Date().setDate(new Date().getDate() - 7))), operator: "GT" },
            //           { id: "end", value: endOfDay(new Date()), operator: "LT" },
            //           { id: "dateFilterField", value: "eta", operator: "EQ" }
          ],
        },
        showFilterCollaps: false,
        baseFilter: [
          { id: 'LocationUuid', value: this.props.currentLocation ? this.props.currentLocation.uuid : "null" },
          { id: 'OrganizationUuid', value: this.props.user.company ? this.props.user.company.uuid : "null" }
        ],
        filterStartDate: startOfDay(new Date(), 7),
        filterEndDate: endOfDay(new Date()),
        filterDateType: 'eta',
        typeFilterEnabled: false,
        typeFilterField: 'all',
        tableSize: { height: "500px", width: "500px" },
        columnSizes: new Map(),
        FilterEnabled: true,
        allLocations: [],
        allCompanies: [],
        currentLocationObj: {},
        currentCompaniesObj: {},
        allUsers: [],
        loaded: false,
        checkForFlag: true,
        enableDepart: false,
        tableColumns: this.tableColumns,
        planId: undefined
      };


    }
    if (enabledNotification && enabledNotification.length > 0) {
      this.state.enabledNotification = enabledNotification;
      localStorage.setItem('eventsListForNotifications', JSON.stringify(enabledNotification));
      this.subscribeToPubSubLink();

    } else {
      this.state.enabledNotification = []

    }
    if (!this.state.filterEndDate) {
      this.state.filterEndDate = endOfDay(new Date());
    }
    if (!this.state.filterStartDate) {
      this.state.filterStartDate = startOfDay(new Date(new Date().setDate(new Date().getDate() - 7)));
    }
    if (!this.state.filterDateType) {
      this.state.filterDateType = "eta";
    }
    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "500px" };
    }
    if (!this.state.columnSizes) {
      this.state.columnSizes = new Map();
    }
    this.tableColumns = this.tableColumns.map((col) => {
      if (this.state.columnSizes.has(col.accessor)) {
        col.width = this.state.columnSizes.get(col.accessor);
      }
      return col;
    });
    localStorage.setItem('connectionAllow', true);

  }
  openPWA() {
    this.setState({isOpenCreate:true});
    // new Implementaton
    // let link = `${Config.driverPortalPWA}/${this.state.pathName}-${this.props.currentLocation.code}`
    // window.open(link, '_blank');
    // Old Implementaton
    // GetOrganizationShortName(this.props.user.company.uuid, (res) => {
    //   if (res.statusCode === "200") {
    //     let link = `${Config.driverPortalPWA}/${res.content}-${this.props.currentLocation.code}`
    //     window.open(link, '_blank');
    //   } else {
    //     showError("Some error occured.");
    //   }
    // },
    //   (err) => {
    //     showError("Some error occured.");
    //   }
    // );


  }
  componentDidMount() {
    // const snd = new Audio("public/assets/sound/chime.mp3");
    //       snd.play();
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.calculateTableSize.bind(this));
    this.calculateTableSize();


    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isTablet === true) {
      document.documentElement.requestFullscreen();
      const datePickers = document.getElementsByClassName("react-datepicker__input-container");
      Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    }

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.checkForFlag == true && prevState.checkForFlag == true) {
      this.setState({ checkForFlag: false });
      this.searchForFlagPerLocation();
    }
    console.log("osman bey uuid",this.props.user)  }
  searchForFlagPerLocation() {
    // this.setState({ loaded: true })
    checkFlagForCheckInCheckOut(this.props.currentLocation.uuid, this.props.user.company.uuid, (res) => {
      let s = this.state.tableColumns;
      let index = s.findIndex(it => it.accessor == "checkInCheckOut");
      if (res.data.isCheckEnabled == true) {
        this.setState({
          profeature: true, disablearrival: true,
          disabledeparture: true,
        });

        console.log("index--", index);
        if (index == -1) {
          s.unshift({
            cleanHeader: 'Check-In/Check-Out',
            Header: 'Check-In/Check-Out',
            accessor: 'checkInCheckOut',
            headerStyle: {
              backgroundColor: '#2e6c93',
            },
            // show: false,
            style: {
              backgroundColor: '#bed6ef'
            },
            filterable: false,
            default: true,
            sortable: false,
            show: true,
            // disableControls: true,
            Cell: (row) => {
              return <>
                {row.original.atCheckIn == false ?
                  <Button
                    // disabled={row.original.currentEventStatus.trim() != 'At Check-In'}
                    // disabled={row.original.eventStatus.trim() != 'ARRIVE'}
                    disabled={

                      (this.state.disablearrival == undefined ||
                        this.state.disablearrival == false) || !(row.original.eventStatusId >=4)
                    }
                    title="Check-In"
                    color='white'
                    aria-label="Check-In"
                    className={"btn-success"}
                    onClick={() => {
                      let v = row.original
                      v.atCheckIn = true
                      this.updateSpinner("Loading...")
                      this.setState({ loaded: true, disablecheckin: false })



                      this.setState({ isOpenEdit: true, selectedArrival: row.original, btnStr: 'checkin' })

                      this.setState({ disablearrival: true });

                    }}>
                    {/* <i className="fa fa-truck" ></i> */}
                    <img style={{ width: "17.5px", height: "17px" }} src="assets/icons/in-truck.png"></img>
                  </Button>
                  :
                  <Button
                    // disabled={row.original.currentEventStatus.trim() != 'At Check-Out' || row.original.atCheckOut}
                    disabled={this.state.disabledeparture === false || row.original.atCheckOut}
                    title="Check-Out"
                    color='white'
                    aria-label="Check-Out"
                    className={"btn-danger"}
                    onClick={() => {
                      let v = row.original
                      v.atCheckOut = true
                      this.updateSpinner("Loading...")
                      this.setState({ loaded: true })
                      this.setState({ isOpenEdit: true, selectedArrival: row.original, btnStr: 'checkout' })

                      // putCheckInCheckOutStatusChange(v, (res) => {
                      //   if (res.statusCode === "200") {
                      //     this.tableRef.current.requestPageSearch();
                      //     // this.setState({ loaded: false })
                      //   } else {
                      //     showError("Some error occured.");
                      //   }
                      // },
                      //   (err) => {
                      //     showError("something went wrong");
                      //   }
                      // );
                      // let obj = {
                      //   arrivalID: v.arrivalID,
                      //   checkOutUsername: this.props.user.firstname + " " + this.props.user.lastname
                      // }
                      // UpdateArrivalCheckOut(obj, (res) => {
                      //   if (res.statusCode === "200") {
                      //     this.tableRef.current.requestPageSearch();
                      //     // this.setState({ loaded: false })
                      //   } else {
                      //     showError("Some error occured.");
                      //   }
                      // },
                      //   (err) => {
                      //     showError("Some error occured.");
                      //   }
                      // );
                      // let obj = {
                      //   arrivalID: v.arrivalID,
                      //   departUsername: this.props.user.firstname + " " + this.props.user.lastname
                      // }
                      // updateArrivalDepart(obj, (res) => {
                      //   if (res.statusCode === "200") {
                      //     this.tableRef.current.requestPageSearch();
                      //     // this.setState({ loaded: false })
                      //   } else {
                      //     showError("Some error occured.");
                      //   }
                      // },
                      //   (err) => {
                      //     showError(err);
                      //   }
                      // );
                    }}>
                    {/* <i className="fa fa-truck " ></i> */}
                    <img style={{ width: "17.5px", height: "17px" }} src="assets/icons/out-truck.png"></img>
                  </Button>
                }
              </>;
            }
          }
          )
        }
      } else {
        this.setState({ profeature: false });

        if (index > -1) {
          s.splice(index, 1);
        }
      }
      this.setState({ tableColumns: s, enableDepart: res.data.isCheckEnabled });
      console.log(" this.tableRef.current", this.tableRef.current);
      this.tableRef.current.refreshColumns();
      // this.tableRef.current.requestPageSearch();
    }, (err) => {

    })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateTableSize.bind(this));
    let oldList = JSON.parse(localStorage.getItem('eventsListForNotifications'));
    localStorage.setItem('oldeventsListForNotifications', JSON.stringify(oldList));
    this.unsubscribeToPubSubLink(true);
    window.scrollTo(0, 0);
    this.savePageState();
  }
  mapToJson(key, value) {
    let objectToSerialize = this[key];
    if (objectToSerialize instanceof Map) {
      return {
        dataType: 'Map',
        values: Array.from(objectToSerialize.entries()),
      };
    } else {
      return value;
    }
  }
  mapFromJson(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.values);
      }
    }
    return value;
  }
  savePageState() {
    localStorage.setItem(getLocalStorageName("poweryard-arrival", this.props.user), JSON.stringify({
      previousSearchRequest: this.state.previousSearchRequest,
      taskStatusFilter: this.state.taskStatusFilter,
      typeFilterEnabled: this.state.typeFilterEnabled,
      typeFilterField: this.state.typeFilterField,
      baseFilter: this.state.baseFilter,
      // filterarrive: this.state.filterarrive,
      // filterdepart: this.state.filterdepart,
      // filterDateType: this.state.filterDateType,
      showFilterCollaps: this.state.showFilterCollaps,
      FilterEnabled: this.state.FilterEnabled,
      columnSizes: this.state.columnSizes
    },
      this.mapToJson));
  }
  calculateTableSize = () => {
    let size = getTableSize("power-yard-arrival-page", "power-yard-arrival-header");
    this.setState({ tableSize: { height: size.height, width: size.width } });
  }
  setBaseFilter() {
    // let prevReq = this.state.previousSearchRequest;
    let baseFilter = this.state.baseFilter;
    if (this.state.typeFilterEnabled) {
      if (this.state.typeFilterField && this.state.typeFilterField !== 'all') {
        let index = baseFilter.findIndex(it => it.id === "commandstatus");

        if (index > -1) {
          baseFilter[index].value = this.state.typeFilterField
        }
        else {
          baseFilter = baseFilter.concat({ id: 'commandstatus', value: this.state.typeFilterField });

        }
      }
      else {
        baseFilter = baseFilter.filter(it => it.id !== 'commandstatus');
      }
    }
    // prevReq.filtered = baseFilter;
    this.setState({ baseFilter: baseFilter });
  }
  CheckDisableStatusForProcessArrival() {
    if (this.state.disablearrival == true && this.state.disablecheckin == true) {
      return true;
    }
    else if (this.state.disablearrival == true && this.state.disablecheckin == false) {
      return false;
    } else {
      return false;
    }
  }

  onSaveAndDepart() {
    console.log("onSaveAndDepart");
    let obj = {
      forceCommand: "Check-Out Complete",
      arrivalID: this.state.selectedArrival.arrivalID,
      TimeZoneID: this.props.currentLocation.timezone
    };
    if (obj.forceCommand.trim() == "Check-Out Complete") {
      let depObj = {
        arrivalID: this.state.selectedArrival.arrivalID,
        CompletedUsername: this.props.user.firstname + " " + this.props.user.lastname
      }
      UpdateArrivalComplete(depObj, (res) => {
        if (res.statusCode === "200") {
          this.setState({ loaded: true })
          showInfo(res.taskStatus.taskStatus);
          obj.forceCommand = "Check-Out Complete";
          putArrivalStatusChange(obj, (res) => {
            if (res.statusCode === "200") {
              this.tableRef.current.requestPageSearch();
              // this.setState({ loaded: false })
            } else {
              showError("Some error occured.");
            }
          },
            (err) => {
            //  showError("Some error occured.");

            }
          );
          // this.tableRef.current.requestPageSearch();
          // this.setState({ loaded: false })
        } else {
          showError("Some error occured.");
        }
      },
        (err) => {
       //   showError("Some error occured.");

        }
      );
    }

  }
  onSaveAndArival() {
    console.log("onSaveAndArival");

    let obj = {
      forceCommand: "Check-In Complete",
      arrivalID: this.state.selectedArrival.arrivalID,
      TimeZoneID: this.props.currentLocation.timezone
    };
    if (this.state.btnStr == 'checkout') {
      this.updateSpinner("Check Out Complete")

    } else {
      this.updateSpinner("Check IN Complete")

    }
    this.setState({ loaded: true })
    putArrivalStatusChange(obj, (res) => {
      if (res.statusCode === "200") {
        this.tableRef.current.requestPageSearch();
        // this.setState({ loaded: false })
      } else {
        showError("Some error occured.");
      }
    },
      (err) => {
        showError(err);
      }
    );
  }
  render() {
    const id = this.id;
    return <>
      <div id="power-yard-arrival-page">
        <Loader show={this.state.loaded} message={this.spinner}>

          <SMSModal
            hideChat={false}
            open={this.state.isOpen}
            selectedArrival={this.state.selectedArrival}
            currentCompany={this.props.user.company.name}
            tableRef={this.tableRef}
            currentLocation={this.props.currentLocation}
            onAdd={(message) => {

              postMessagePWA(message, () => {
                this.setState({ isOpen: false });
              }, () => {
              });
            }}
            onClose={() => this.setState({ isOpen: false })}
          />
          <AttachmentsModal
            hideChat={false}
            open={this.state.isOpenAttachment}
            selectedArrival={this.state.selectedArrival}
            currentLocation={this.props.currentLocation}
            // onAdd={(message) => {

            //   postMessagePWA(message, () => {
            //     this.setState({ isOpenAttachment: false });
            //   }, () => {
            //   });
            // }}
            onClose={() => {

              this.setState({ isOpenAttachment: false })
            }

            }
          />
          <CreateGateModal
            user={this.props.user}
            tabelRef={this.tableRef}
            btnStr={this.state.btnStr}
            open={this.state.isOpenCreate}
            currentLocation={this.props.currentLocation}
            onClose={() => {
              this.tableRef.current.fetchData();
              this.setState({ isOpenCreate: false, btnStr: undefined })
            }}

          />

          <EditGateModal
            user={this.props.user}
            tabelRef={this.tableRef}
            btnStr={this.state.btnStr}
            open={this.state.isOpenEdit}
            selectedArrival={this.state.selectedArrival}
            currentLocation={this.props.currentLocation}
            onSave={(arrival) => {
              if (arrival.statusCode === "200" && this.state.btnStr !== undefined && arrival.isArrDepBtnClick) {

                this.state.btnStr === 'checkout' ? this.onSaveAndDepart() : this.onSaveAndArival();
              }
              this.tableRef.current.fetchData();
              this.setState({ isOpenEdit: false, btnStr: undefined });
              // postSaveArrival(arrival, () => {
              //   this.tableRef.current.fetchData();
              //   showInfo("Arrival Saved")
              // }, (err) => {
              //   showError("Error Occured")
              // });
            }}
            onClose={() => {
              this.tableRef.current.fetchData();
              this.setState({ isOpenEdit: false, btnStr: undefined })
            }}
          />
          <ShowCustomList

            open={this.state.isOpenList}
            selectedArrival={this.state.selectedArrival}
            onClose={() => {
              this.setState({ isOpenList: false })
            }}
          />

          <ArrivalModel
            open={this.state.isShow}
            onAdd={(reasonCode) => {
              const data = this.props.position;
              data.cancelReasonCode = reasonCode;
              putTaskCancel(this.state.cancelTask.uuid, data, () => {
                this.tableRef.current.fetchData();
                this.setState({ isShow: false });
              }, () => {
              });
            }}
            onClose={() => this.setState({ isShow: false })}
          />

          <div className="column-wrapper text-left">
            <div className="page-header" id="power-yard-arrival-header">
              <Row>
                <Col>
                  <h1>Gate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.pathName &&
                      <Button onClick={() => this.openPWA()}>Create</Button>
                    }
                  </h1>
                </Col>
                <Col className={"text-left"}>
                  <ChangeShiftFormField
                    onChange={(shift, location) => {
                      if (shift) {
                        if (this.props.currentShift !== shift) {
                          this.props.setCurrentShift(shift);
                          if (shift) {
                            let data = this.props.user;
                            data.shift = shift.value;
                            data.version = (data.version + 1);
                            postUserDefaultShift(data);
                          }
                        }
                      }
                      if (location) {
                        if (this.props.currentLocation.uuid !== location.uuid) {
                          this.props.setCurrentLocation(location);
                          if (location && location.uuid) {
                            postUserDefaultLocation(location.uuid);
                          }
                        }
                      }
                      this.setState({
                        disablearrival: true,
                        disabledeparture: true,
                      });
                    }}
                    currentShift={this.props.currentShift}
                    currentLocation={this.props.currentLocation}
                  />
                </Col>
                <Col className={"text-right"}>
                  <ChangeLocationFormField
                    pageType={'gate'}
                    onChange={(location) => {
                      let pro = this.props.currentLocation.uuid;
                      if (this.props.currentLocation.uuid !== location.uuid) {
                        this.props.setCurrentLocation(location);
                        if (location && location.uuid) {
                          postUserDefaultLocation(location.uuid, (success) => {
                            let base = this.state.baseFilter;
                            let index = base.findIndex(it => it.id === "LocationUuid");
                            if (index > -1) {
                              base[index].value = location.uuid;
                              this.setState({ baseFilter: base });
                            }
                            this.setState({ enabledNotification: [] });
                            this.tableRef.current.requestPageSearch();
                            let oldList = JSON.parse(localStorage.getItem('eventsListForNotifications'));
                            localStorage.setItem('oldeventsListForNotifications', JSON.stringify(oldList));
                            this.unsubscribeToPubSubLink(true);

                            localStorage.removeItem('eventsListForNotifications');
                            localStorage.removeItem('oldeventsListForNotifications');
                            localStorage.removeItem(getLocalStorageName("enabledNotification", this.props.user));
                            this.searchForFlagPerLocation();
                            this.tableRef.current.updateEnabledNotificationsStatus();


                          });
                        }
                      }
                      this.setState({
                        disablearrival: true,
                        disabledeparture: true,
                      });
                    }}
                    currentLocation={this.props.currentLocation}
                  />
                  <Button onClick={() => {
                    if (this.state.showFilterCollaps === true) {
                      this.setState({ showFilterCollaps: false });
                    } else {
                      this.setState({ showFilterCollaps: true });
                    }
                  }}><i className={this.state.showFilterCollaps === true ? "fa fa-chevron-up" : "fa fa-chevron-down"} /></Button>
                </Col>
              </Row>

            </div>
            {/* // openFiltersCollaps={this.state.showFilterCollaps} */}
            <PaginationFilterTableArrival
              profeature={this.state.profeature}
              checked={this.state.typeFilterEnabled}
              locationUuid={this.props.currentLocation.uuid}
              arrivalStatusFilter={this.state.arrivalStatusFilter}
              openFiltersCollaps={this.state.showFilterCollaps}
              enabledNotification={this.state.enabledNotification}
              enabledNotificationsCallBack={(res) => {
                let oldList = JSON.parse(localStorage.getItem('eventsListForNotifications'));
                localStorage.setItem('oldeventsListForNotifications', JSON.stringify(oldList));
                localStorage.setItem('eventsListForNotifications', JSON.stringify(res));

                this.subscribeToPubSubLink();
              }}
              onFilterHide={() => {
                this.setState({
                  taskStatusFilter: "undefined",
                  arrivalStatusFilter: "undefined",
                  arrivalPlanFilter: "undefined",
                  departurePlanFilter: "undefined",
                  departureStatusFilter: "undefined",
                  typeFilterField: "All",
                });
              }}
              showPaginationNumbering={false}
              autoRefreshInterval={10000}
              showEditColumns
              autoRefreshEnabled={true}
              user={this.props.user}
              style={{
                maxHeight: '500px',
                maxWidth: this.state.tableSize.width,
                minHeight: "120px",
                backgroundColor: '#bababa',
                color: 'white',
                border: "1px solid white"
              }}
              ref={this.tableRef}
              get={poweryardArrival}
              successCallback={(response) => {
                // this.setState({ loaded: false })
                this.setState({ data: response.data, loaded: false, pathName: response.pathName, planId: response.planId });
              }}
              infoButton={true}
              baseFilter={this.state.baseFilter}
              // filtered={this.state.previousSearchRequest.filtered}
              onFilteredChange={(filter) => {
                const delayFilters = ['taskStatus', 'priority', 'boxedIn', 'sealed', 'reefer', 'continous'];
                let psr = { ...this.state.previousSearchRequest };
                psr.filtered = { ...this.state.filtered }
                psr.filtered = filter;
               const hasDelayFilter = psr.filtered.some((filter, index, array) => {
                    if (array.length === 1) {
                      return delayFilters.includes(filter.id);
                    } else if (array.length > 1) {
                      return index === array.length - 1 && delayFilters.includes(filter.id);
                    }
                    return false;
                  });
                this.setState({
                  previousSearchRequest: psr,
                  typeFilterEnabled: false,
                  arrivalPlanFilter: false,
                  typeFilterField: 'All'
                }, () => {

                  if (hasDelayFilter) {
                    this.tableRef.current.requestPageSearch();
                  } else {
                    setTimeout(() => {
                      this.tableRef.current.requestPageSearch();
                    }, 3000); // 4000 milliseconds = 4 seconds
                  }

                });
              }}
              clearFilter={(resetAll) => {
                if (resetAll) {
                  let psr = { ...this.state.previousSearchRequest };
                  psr.filtered = [];
                  // this.onSiteFilterRef.current.value = undefined;
                  this.setState({
                    typeFilterEnabled: false,

                    previousSearchRequest: psr,
                    arrivalPlanFilter: true,
                    arrivalStatusFilter: true,
                    departureStatusFilter: true,
                    departurePlanFilter: true,
                    typeFilterField: 'All'
                  }, () => {
                    if (this.state.typeFilterEnabled === false) {
                      let index = this.state.baseFilter.findIndex(it => it.id === "commandstatus");
                      if (index > -1) {
                        this.state.baseFilter.splice(index, 1);

                      }
                    }
                    this.tableRef.current.restoreColumns(true);
                  });

                } else {
                  let psr = { ...this.state.previousSearchRequest };
                  psr.filtered = psr.filtered.filter((f) => f.id === 'all');
                  // this.onSiteFilterRef.current.value = undefined;
                  this.setState({
                    previousSearchRequest: psr,
                    arrivalPlanFilter: true,
                    departureStatusFilter: true,
                    departurePlanFilter: true,
                    arrivalStatusFilter: true,
                  }, () => this.tableRef.current.requestPageSearch());
                }
              }}
              // footerButton={
              //   <>
              //     <label style={{ color: 'white', marginRight: '5px', padding: '5px', backgroundColor: '#2e6c93' }}>Driver Info</label>
              //     <label style={{ color: "white", padding: '5px', backgroundColor: '#7896b5', marginRight: '5px' }} >Inbound Plan</label>
              //     <label style={{ color: "white", padding: '5px', backgroundColor: '#557087' }}>Outbound Plan</label>
              //   </>

              // }
              defaultSorted={[]}
              stateStorage={"poweryard-arrival"}
              columns={this.state.tableColumns}
              filterEnabled={this.state.FilterEnabled}
              onChange={(state) => {
                this.setState({
                  previousSearchRequest: {
                    dateFilter: state.dateFilter,
                    itemsPerPage: state.itemsPerPage,
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered
                  }
                });
              }}
              onResizedChange={(customizedColumns, event) => {
                let columnSizes = this.state.columnSizes ? this.state.columnSizes : new Map();
                customizedColumns.forEach(col => {
                  columnSizes.set(col.id, col.value);
                })
                this.setState({ columnSizes: columnSizes });
              }}
            >
              <Switch

                onChange={(checked) => {
                  let baseFilter = this.state.baseFilter;
                  this.setState({ typeFilterEnabled: checked }, () => {

                    if (checked === false) {
                      let index = baseFilter.findIndex(it => it.id === "commandstatus");
                      if (index > -1) {
                        baseFilter.splice(index, 1);
                      }
                    }
                    this.setBaseFilter();
                    this.tableRef.current.requestPageSearch();
                    // localStorage.setItem(getLocalStorageName('poweryard-arrival', this.props.user), JSON.stringify(this.state));
                    this.tableRef.current.requestPageSearch();

                  });

                  // onDateFilterEnable ={(checked)=>{

                  // }}

                }}
                checked={this.state.typeFilterEnabled}

                uncheckedIcon={<div className="text-center pt-1"><i className="fas fa-truck" /></div>}
                checkedIcon={<div className="text-center pt-1"><i className="fas fa-truck" /></div>}
                title="Show Filter"
                className="react-switch"
              />
              <select
                className="ml-4"
                disabled={!this.state.typeFilterEnabled}

                ref={this.typeFilterField}
                onChange={event => {
                  this.setState({ typeFilterField: event.target.value }, () => {
                    this.setBaseFilter();
                    // localStorage.setItem(getLocalStorageName('poweryard-arrival', this.props.user), JSON.stringify(this.state));
                    this.tableRef.current.requestPageSearch();
                  })
                }}
                value={this.state.typeFilterField}
              >
                <option value="undefined" hidden={true}>Select</option>
                <option value="all">All</option>
                <option value="arrive">Arrival</option>
                <option value="depart">Departure</option>
              </select>
              {this.state.profeature &&
                <>
                  &nbsp;&nbsp;
                  <label>Arrival</label>
                  <Switch

                    onChange={(checked) => {


                      this.setState({
                        disablearrivalenabled: checked,
                        disablearrival: checked,
                      })
                    }
                    }
                    checked={this.state.disablearrivalenabled}

                    uncheckedIcon={<div className="text-center pt-1"><i className="fas fa-truck" /></div>}
                    checkedIcon={<div className="text-center pt-1"><i className="fas fa-truck" /></div>}
                    title="Arrival"
                    className="react-switch"
                  />
                  &nbsp;&nbsp;

                  <label>Departure</label>

                  <Switch
                    onChange={(checked) => {



                      this.setState({
                        disabledepartureenabled: checked,
                        disabledeparture: checked,
                      })
                    }
                    }
                    checked={this.state.disabledepartureenabled}
                    onColor="#f86c6b"

                    uncheckedIcon={<div className="text-center pt-1"><i className="fas fa-truck fa-flip-horizontal" /></div>}
                    checkedIcon={<div className="text-center pt-1"><i className="fas fa-truck fa-flip-horizontal" /></div>}
                    title="Departure"
                    className="react-switch"
                  />
                </>
              }
              {/* <TableDateRangePicker
              ref={this.tableRef}
              showTaskSelector={true}
              start={this.state.filterStartDate}
              end={this.state.filterEndDate}
              dateType={this.state.filterDateType}
              filterEnabled={this.state.dateFilterEnabled}
              onDateFilterEnable={(enabled) => {
                this.setState({ dateFilterEnabled: enabled });
                if (enabled) {
                  this.tableRef.current.setState({ page: 0, forcePage: 0 });
                  this.updateProperty("start", this.state.filterStartDate);
                  this.updateProperty("end", this.state.filterEndDate);
                  this.updateProperty("dateFilterField", this.state.filterDateType);
                } else {
                  this.removeFilter("start");
                  this.removeFilter("end");
                  this.removeFilter("dateFilterField");
                }
                this.tableRef.current.requestPageSearch();
              }}
              onStartDateChange={(date) => {
                this.updateProperty("start", date);
                this.setState({ filterStartDate: date });
                this.tableRef.current.requestPageSearch();
              }}
              onEndDateChange={(date) => {
                this.updateProperty("end", date);
                this.setState({ filterEndDate: date });
                this.tableRef.current.requestPageSearch();
              }}
              onDateTypeChange={(val) => {
                this.updateProperty("dateFilterField", val);
                this.setState({ filterDateType: val });
                this.tableRef.current.requestPageSearch();
              }}
            /> */}
            </PaginationFilterTableArrival>
          </div>
        </Loader>
      </div>
    </>
  }

  removeFilter(id) {
    let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => { this.savePageState() })
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }

    if (!found) {
      if (id === 'start') {
        filtered.push({ id: id, value: value, operator: 'GT' })
      } else if (id === 'end') {
        filtered.push({ id: id, value: value, operator: 'LT' })
      } else if (id === "filterDateType") {
        filtered.push({ id: id, value: value, operator: 'EQ' })
      } else {
        filtered.push({ id: id, value: value, operator: 'EQ' })
      }

    }
    previousSearchRequest.filtered = filtered;
    this.setState({
      previousSearchRequest: previousSearchRequest
    }, () => {
      this.savePageState();
    }
    );
  }
  setConnectionState(conection) {
    this.setState({ socketConnection: conection });
    console.log('Connection', this.state.socketConnection);
  }

  getProperty(field) {
    let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }
  }
  unsubscribeToPubSubLink(unSub?) {
    const oldenabledNotification = JSON.parse(localStorage.getItem("oldeventsListForNotifications"));
    if (oldenabledNotification && oldenabledNotification.length > 0) {
      let ackId = new Date().getTime();
      oldenabledNotification.map(it => {

        this.state.socketConnection.send(JSON.stringify({
          type: 'leaveGroup',
          group: it,
          ackId: ++ackId,
          success: false,
          error: {
            "name": "Forbidden|InternalServerError|Duplicate",
            "message": "<error_detail>"
          }
        }))
      });
    }
    if (unSub !== true) {
      this.subscribeArrayToPubSub();
    }
  }
  subscribeArrayToPubSub() {
    const enabledNotification = JSON.parse(localStorage.getItem("eventsListForNotifications"));


    setTimeout(() => {

      let acdId = new Date().getTime();
      enabledNotification.map(it => {
        this.state.socketConnection.send(JSON.stringify({
          type: 'joinGroup',
          group: it,
          ackId: ++acdId,
        }))
      });

    }, 3000);
  }
  subscribeToPubSubLink() {
    if (this.state.socketConnection) {
      const oldenabledNotification = JSON.parse(localStorage.getItem("oldeventsListForNotifications"));
      if (oldenabledNotification && oldenabledNotification !== null && oldenabledNotification.length > 0) {
        this.unsubscribeToPubSubLink();
      }
      // }
      localStorage.setItem('connectionAllow', true);
      this.state.socketConnection.onopen = this.socketConnectionOnOpen;
      this.state.socketConnection.onmessage = this.socketConnectionMade;
      if (!oldenabledNotification || oldenabledNotification === null || oldenabledNotification.length == 0) {
        this.subscribeArrayToPubSub();
      }

    } else {
      GetPubSubLinkWWS('', (res) => {
        const socketConnection = connectWebsocket(res.data);
        this.setConnectionState(socketConnection);
        this.state.socketConnection.onopen = this.socketConnectionOnOpen;
        this.state.socketConnection.onmessage = this.socketConnectionMade;
        const enabledNotification = JSON.parse(localStorage.getItem("eventsListForNotifications"));
        // const oldenabledNotification=JSON.parse(localStorage.getItem("oldeventsListForNotifications"));
        // if(oldenabledNotification && oldenabledNotification.length > 0){
        //   let ackId = 0;
        //   enabledNotification.map(it=> {

        //     socketConnection.send(JSON.stringify({
        //       type: 'leaveGroup',
        //       group: it,
        //       ackId: ++ackId,
        //     }))
        //   });
        // }
        setTimeout(() => {

          let acdId = new Date().getTime();
          enabledNotification.map(it => {
            socketConnection.send(JSON.stringify({
              type: 'joinGroup',
              group: it,
              ackId: ++acdId,
            }))
          });

        }, 3000);

      });
    }
  }
  socketConnectionOnOpen(e) {
    console.log(e)
  }

  socketConnectionMade(evt) {
    let messages = evt.data;
    let connectionID = JSON.parse(messages).connectionId;
    if (connectionID) {
      localStorage.setItem('connectionId', connectionID);
    }
    messages = JSON.parse(messages);
    console.log(messages);
    // this.setConnectionID(connectionID);
    const enabledNotification = JSON.parse(localStorage.getItem("eventsListForNotifications"));
    const savedConnectionId = localStorage.getItem("connectionId");
    const connectionAllow = localStorage.getItem("connectionAllow");
    if (savedConnectionId && connectionAllow == "true") {
      localStorage.setItem('connectionAllow', false);
      makeConnectionUsingWebsocket({
        connectionid: savedConnectionId,
        lstGroups: enabledNotification
      }, res => {

      })
    } else {
      if (messages.data) {
        let data = JSON.parse(messages.data);
        var snd = new Audio(Config.chimePath);
        snd.play();
        showInfo(data.firstname + " " + data.lastname + " has updated to " + data.EventStatus + ". (Arrival ID: " + data.ArrivalID + " ) .Thanks");
      }
    }

  }

}
Arrival.propTypes = {
  currentLocation: PropTypes.object,
  currentShift: PropTypes.string,
  position: PropTypes.object,
  user: PropTypes.object,
  setCurrentLocation: PropTypes.func,
  setCurrentShift: PropTypes.func,
}

withRouter(Arrival);

