import Link from "react-router-dom/Link";
import React from "react";
import { ITEMS_PER_PAGE_DEFAULT, KILOMETERS_PER_MILE } from "./constants";
import * as moment from "moment";

export const camelCaseToWords = (camelCase) => camelCase.replace(/([A-Z])/g, " $1");

export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }
};


export const filterCaseInsensitiveContains = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) >= 0
                :
                true
        );
    }
};


export const filterCaseInsensitiveLeadingZeroes = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase().replace(/^0+/, '')).startsWith(filter.value.toLowerCase().replace(/^0+/, ''))
                :
                true
        );
    }
};
export const appendAsteriskToStr = (str) => {
    let convertedStr = "";
    if (str) {
        convertedStr = str.substring(0, 8);
        if (str.length > 7) {
            convertedStr += Array(str.length - 8).fill('*').join('');
        }
    }
    return convertedStr;
}

export const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
        return {
            ...errors,
            [error.path]: error.errors[FIRST_ERROR],
        }
    }, {})
};

export const latLongDistance = (lat1, lon1, lat2, lon2, unit) => {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    } else {
        if (isNaN(parseFloat(lat1)) || isNaN(parseFloat(lon1)) || isNaN(parseFloat(lat2)) || isNaN(parseFloat(lon2))) return "N/A";
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        if (isNaN(dist)) return "N/A";
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515; // to miles
        if (unit === "Kilometers") { dist = dist * 1.609344 }
        return dist.toFixed(1) + " " + (unit === "Kilometers" ? "km" : "mi");
    }
};

export const padNumber = (num, size) => {
    size = size || 2;
    return ('000000000' + num).substr(-size);
};

export const isRegularLeftClick = (e) => {
    return (e.button === 0 && !e.altKey && !e.shiftKey && !e.ctrlKey && !e.metaKey);
};

export const promiseReturningEmptyList = () => () =>
    new Promise(resolve => {
        resolve([]);
    });


export const getEZTrackAssetLink = function (assetUuid, assetName) {
    return assetUuid && assetName ?
        <Link to={"/asset-profile/" + assetUuid}>{assetName}</Link>
        : "";
}
export const getShipmentTALink = function (duuid, user, assetId, assetCompanyUuid, type) {
    localStorage.removeItem("trackedShipments");
    return user && assetId && assetCompanyUuid ?
        <Link to={"/trackassured-shipment-profile/" + assetId + (assetCompanyUuid !== user.company.uuid ? "?company=" + assetCompanyUuid : "") + "/" + duuid + "/" + type + "/" + assetId}>{assetId}</Link>
        : "";

}
export const getShipmentsLink = function (duuid, user, assetId, assetCompanyUuid, type) {
    localStorage.removeItem("shipments");
    return user && assetId && assetCompanyUuid ?
        <Link to={"/shipment-profile/" + assetId + (assetCompanyUuid !== user.company.uuid ? "?company=" + assetCompanyUuid : "") + "/" + duuid + "/" + type + "/" + assetId}>{assetId}</Link>
        : "";

}
export const getShipmentLink = function (user, assetId, assetCompanyUuid) {

    return user && assetId && assetCompanyUuid ?
        <Link to={"/shipment-profile/" + assetId + (assetCompanyUuid !== user.company.uuid ? "?company=" + assetCompanyUuid : "")}>{assetId}</Link>
        : "";

}
export const formatLatLong = (number) => {
    try {
        if (number) {
            return parseFloat(number).toFixed(5);
        }
    } catch (e) { }
    return "N/A";
};

export const formatDistanceFromDomicile = (distanceFromDomicileInMeters, distanceUnitsOfMeasure) => {
    if (distanceUnitsOfMeasure === 'Miles') {
        return distanceFromDomicileInMeters ?
            <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 })
                .format(distanceFromDomicileInMeters / 1000.0 / KILOMETERS_PER_MILE)} {distanceUnitsOfMeasure}</span> :
            null
    } else {
        let distanceInMeters = distanceFromDomicileInMeters;
        if (distanceInMeters != null) {
            return <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 })
                .format(distanceInMeters / 1000.0)} {distanceUnitsOfMeasure}</span>
        } else {
            return null;
        }
    }
};

export const formatMoveFrequency = (movesInLast30Days) => {
    return movesInLast30Days >= 0 ?
        new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, style: 'percent' })
            .format(movesInLast30Days / 30) :
        null
};
export const formatIdleTime = (idleTime) => {
    if (idleTime) {
        let d = moment.duration(idleTime);
        return new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 }).format(d.asDays());
    } else {
        return null;
    }
}
export function restoreTableState(localStorageState) {
    if (localStorageState != null) {
        return {
            loading: false,
            previousSearchRequest: localStorageState.previousSearchRequest,
            page: localStorageState.page,
            pageSize: localStorageState.pageSize ? Number.parseInt(localStorageState.pageSize) : ITEMS_PER_PAGE_DEFAULT,
            autoRefreshEnabled: localStorageState.autoRefreshEnabled,
            itemsPerPage: localStorageState.itemsPerPage ? Number.parseInt(localStorageState.itemsPerPage) : ITEMS_PER_PAGE_DEFAULT,
            pages: localStorageState.pages,
            sorted: localStorageState.sorted,
            filtered: localStorageState.filtered,
            hideFilters: localStorageState.hideFilters,
            operator: localStorageState.operator ? localStorageState.operator : null,
        }
    }
}

export function getLocalStorageName(pageName, user) {
    let s = (user ? user.email + "-" : "") + pageName;
    return s;
}
export function getLocalStorageNameV2(pageName, user, Id) {
    let s = (user ? user.email + "-" : "") + pageName + "-" + Id;
    return s;
}
export function generateRandomNumber() {
    var random = '';
    random = Math.random().toString(36).slice(2);
    return random;
}
export function saveTableState(pageName, state, user) {
    localStorage.setItem(getLocalStorageName(pageName, user), JSON.stringify({
        previousSearchRequest: state.previousSearchRequest,
        page: state.page,
        pageSize: state.pageSize,
        pages: state.pages,
        autoRefreshEnabled: state.autoRefreshEnabled,
        itemsPerPage: state.itemsPerPage,
        sorted: state.sorted,
        filtered: state.filtered,
        hideFilters: state.hideFilters,
        operator: state.operator ? state.operator : null,
        columns: state.columns
    }))
}

export function latLongLink(item) {
    if (item.latitude && item.longitude) {
        return <span>&nbsp;<a className="map-link" href="#" title={"(" + item.latitude + "," + item.longitude + ")"} onClick={
            (e) => {
                e.preventDefault();
                window.open("https://www.google.com/maps?q=" + item.latitude + "," + item.longitude, "maps", "");
            }
        }><i className="fa fa-map"></i></a></span>;
    } else {
        return "";
    }
}

export function getDwellForAssetType(assetType, tractorDwell, trailerDwell) {
    if (assetType === "Trailer") {
        return trailerDwell;
    } else if (assetType === "Tractor") {
        return tractorDwell;
    }
}
export function convertCtoF(tempInC) {
    if (tempInC == null) {
        return null;
    }
    return tempInC * 9 / 5 + 32;
}
export function convertFtoC(tempInF) {
    if (tempInF == null) {
        return null;
    }
    return (tempInF - 32) * 5 / 9;
}
export function getIcon(glyph, color) {
    var canvas, ctx;
    canvas = document.createElement('canvas');
    canvas.width = canvas.height = 30;
    ctx = canvas.getContext('2d');
    if (color) {
        ctx.fillStyle = color;
    }
    ctx.font = '30px FontAwesome';
    ctx.fillText(glyph, 0, 26);
    return canvas.toDataURL();
}


export function getDwellSecondsFromField(dwell, unit) {
    if (!unit || unit === "Minutes") {
        return dwell * 60;
    } else if (unit === "Hours") {
        return dwell * (60 * 60)
    } else if (unit === "Days") {
        return dwell * (60 * 60 * 24);
    }
}

export function getDwellFromSeconds(dwellInSeconds, unit) {
    if (!dwellInSeconds) return null;
    if (!unit || unit === "Minutes") {
        return dwellInSeconds / 60;
    } else if (unit === "Hours") {
        return dwellInSeconds / (60 * 60)
    } else if (unit === "Days") {
        return dwellInSeconds / (60 * 60 * 24);
    }
}

export function startOfDay(date, days?) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    if (days) {
        date = date.setDate(date.getDate() - days);
        date = new Date(date);
        date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        return date;
    } else {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }
}

export function endOfDay(date, days?) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    if (days) {
        date = date.setDate(date.getDate() + days);
        date = new Date(date);
        date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        return date;
    } else {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }
}
export function startOfDayEST(date) {
    const currentTimezoneOffset = date.getTimezoneOffset();
    const isEST = currentTimezoneOffset === 300; 
  
      date.setDate(date.getDate() - 7); 
      date.setHours(23, 59, 59, 999); 
    
  
    return date.toISOString();
  }
export function endOfDayEST(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  
    const currentTimezoneOffset = date.getTimezoneOffset();
    const isEST = currentTimezoneOffset === 300; 
  
    if (isEST) {
        console.log("Time");
      const nextDay = new Date(date.getTime() + 0 * 1000); 
      nextDay.setHours(23, 59, 59, 999);
      return nextDay.toISOString();
    } else {
        console.log("Time3");

      date.setHours(23, 59, 59, 999);
      return date.toISOString();
    }
  }
export function getTableSize(pageElementId, pageHeaderElementId) {
    let w = window.innerWidth - 16;
    let h = window.innerHeight - 183.5; // 67.5 for filter row above table, 70 for table footer, 29 for table header, 17 for horizontal scroll bar
    let abh = document.getElementById('pft-action-bar-header');
    if (abh !== null) {
        h = window.innerHeight - abh.offsetHeight - 2;
    }
    let abf = document.getElementById('pft-action-bar-footer');
    if (abf !== null) {
        h = h - abf.offsetHeight;
    }
    let d = document.getElementById(pageElementId);
    if (d === null) {
        return { height: "500px", width: "500px" }
    }
    let f = document.getElementById("ez-app-footer");
    if (f !== null) {
        h = h - f.offsetHeight;
    } else {
        h = d.offsetHeight - 75;
    }
    let hdr = document.getElementById("ez-app-header");
    if (hdr !== null) {
        h = h - hdr.offsetHeight;
    } else {
        h = h - 55;
    }
    let shipmentsHeader = document.getElementById(pageHeaderElementId);
    if (shipmentsHeader !== null) {
        h = h - shipmentsHeader.offsetHeight;
    }
    return { height: h, width: w };
}
export function convertShapeJsonToMapObject(shape, map, google) {
    if (shape.geometry.type === "Point") {
        return new google.maps.Circle({
            map,
            center: { lat: shape.geometry.coordinates[1], lng: shape.geometry.coordinates[0] },
            radius: shape.properties.radius,
        });
    } else if (shape.geometry.type === "Polygon") {
        let coords = [];
        shape.geometry.coordinates[0].map((coord) => coords.push({ lat: coord[1], lng: coord[0] }));
        return new google.maps.Polygon({
            map,
            paths: coords,
        });
    }
}
export function milesToMeters(miles) {
    return miles * 1609.34;
}