import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormField from "./FormField";
import moment from "moment";
import {
  getPhysicalAddressInformation,
  getZipInformation,
  promiseEZTrackEquipmentByPrefix,
} from "../../NetworkHelpers";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import { FFStateSelect } from "../../common/formik-free/FFStateSelect";
import { FFCountrySelect } from "../../common/formik-free/FFFormCountrySelect";
import FFFormPhoneTextField from "../../common/formik-free/FFFormPhoneTextField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import { FFAssetTypesSelect } from "../../common/formik-free/FFAssetTypesSelect";
import { FFTaskTypesSelect } from "../../common/formik-free/FFTaskTypesSelect";
import { FFAssetStatusSelect } from "../../common/formik-free/FFAssetStatusSelect";
import { FFCarrierSelect } from "../../common/formik-free/FFCarrierSelect";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import { FFLocationsSelect } from "../../common/formik-free/FFLocationsSelect";
import { Alert, Col, Row } from "reactstrap";
import {
  getDwellFromSeconds,
  getDwellSecondsFromField,
} from "../../common/utilities";
import GeofenceMap from "../../PingAssets/components/GeofenceMap";
import { DRAW_ON_MAP, SPECIFY_RADIUS } from "../../common/constants.js";
import { FFCancelReasonCodeSelect } from "../../common/formik-free/FFCancelReasonCodeSelect";
import { showError, showInfo } from "../../MainApp";

var tzlookup = require("tz-lookup");

export class DwellInputWithUnit extends Component {
  render() {
    const options = [
      { value: "Minutes", label: "Minutes" },
      { value: "Hours", label: "Hours" },
      { value: "Days", label: "Days" },
    ];
    return (
      <Row>
        <Col>
          <FFFormTextField
            numbersOnly
            label={this.props.label}
            icon="fas-clock"
            placeholder=""
            fieldname="value"
            error={this.props.error}
            onChange={(newValue) => {
              this.props.onChange({ unit: this.props.unit, value: newValue });
            }}
            touched={this.props.touched}
            value={this.props.value}
          />
        </Col>
        <Col>
          <FFFormSelectField
            placeholder="Minutes"
            fieldname="type"
            name="type"
            value={this.props.unit}
            options={options}
            noSearch={true}
            isDisabled={this.props.disabled}
            onChange={(newUnit) => {
              this.props.onChange({
                unit: newUnit.value,
                value: this.props.value,
              });
            }}
          />
        </Col>
      </Row>
    );
  }
}

export class LocationFormFields extends Component {
  constructor(props) {
    super(props);
    this.userAddedInput = false;
    if (props.hideEzCheckInLocation) {
      props.values["hideEzCheckInLocation"] = true;
      props.assetTypes = promiseEZTrackEquipmentByPrefix;
    }
    this.state = {
      disableAddress: true,
      disableCountry: true,
      disableButton: false,
      disableCity: true,
      geofenceType: SPECIFY_RADIUS,
      canDownloadListOfStatesForCountries: ["United States", "Canada", "Mexico"],
    };
  }

  onChange(fieldName, newValue) {
    this.props.values[fieldName] = newValue;
    this.props.setFieldTouched(fieldName);
    this.props.onChange(this.props.values);
  }
  onChangeMulti(fieldName, newValue) {
    this.props.values[fieldName].push(newValue);
    this.props.setFieldTouched(fieldName);
    this.props.onChange(this.props.values);
  }
  updateTz(latitude, longitude) {
    try {
      this.props.values["timezone"] = tzlookup(latitude, longitude);
    } catch (e) {
      console.error(e);
    }
  }

  checkManualInput(event) {
    this.userAddedInput = true;
  }

  getMapSize() {
    let s = document.getElementById("location-form-fields");
    if (s === null) {
      return { height: "500px", width: "800px" };
    } else {
      let w = s.offsetWidth;
      let h = w * 0.6;
      return { height: h, width: w };
    }
  }

  filerAssets() {
    return this.props.values["countrycode"];
  }

  render() {
    return (
      <>
        <div id={"location-form-fields"}>
          <FFFormTextField
            label="Name"
            placeholder=""
            icon="fa-truck"
            fieldname="name"
            error={this.props.errors ? this.props.errors.name : ""}
            onChange={(newValue) => {
              this.onChange("name", newValue);
              this.setState({ disableAddress: false });
            }}
            touched={this.props.touched.name}
            onTouch={this.props.handleBlur}
            value={this.props.values.name}
            required={true}
          />
          <FFFormTextField
            label="Address 1"
            icon="fa-building"
            placeholder=""
            fieldname="address1"
            disabled={this.props.values.name ? false : true}
            error={this.props.errors.address1}
            onChange={(newValue) => {
              this.onChange("address1", newValue);
            }}
            touched={this.props.touched.address1}
            onTouch={this.props.handleBlur}
            value={this.props.values.address1}
            required={true}
          />
          <FFFormTextField
            label="Address 2"
            icon="fa-building"
            placeholder=""
            fieldname="address2"
            error={this.props.errors.address2}
            onChange={(newValue) => {
              this.onChange("address2", newValue);
            }}
            touched={this.props.touched.address2}
            onTouch={this.props.handleBlur}
            value={this.props.values.address2}
            required={false}
          />
          <FFFormTextField
            label="City"
            icon="fa-building"
            placeholder=""
            fieldname="city"
            disabled={this.props.values.address1 ? false : true}
            error={this.props.errors.city}
            onChange={(newValue) => {
              this.onChange("city", newValue);
              this.setState({ disableCountry: false });

            }}
            touched={this.props.touched.city}
            onTouch={this.props.handleBlur}
            value={this.props.values.city}
            required={true}
          />
          <FormField
            label={"Country"}
            required={true}
            errors={this.props.errors}
            icon={"fa-building"}
            fieldname={"country"}
          >
            <FFCountrySelect
              name={"country"}
              placeholder={""}
              fieldname={"country"}
              error={this.props.errors.country}
              disabled={this.props.values.city ? false : true}
              onBlur={() => {
                this.props.setFieldTouched("country");
              }}
              handleChange={this.props.handleChange}
              touched={this.props.touched.country}
              value={this.props.values.country}
              required={true}
              onChange={(country) => {
                country
                  ? this.onChange("country", country.value)
                  : this.onChange("country", "");
                this.onChange("state", "");

              }}
            ></FFCountrySelect>
          </FormField>
          {this.state.canDownloadListOfStatesForCountries.indexOf(
            this.props.values.country
          ) !== -1 ? (
            <FormField
              label={"State / County / Province"}
              errors={this.props.errors}
              icon={"fa-building"}
              fieldname={"state"}
              required={true}
            >
              <FFStateSelect
                name={"state"}
                selectedCountry={this.props.values.country}
                fieldname={"state"}
                disabled={
                  (this.props.values.country &&
                    this.props.values.name &&
                    this.props.values.address1 &&
                    this.props.values.city) ? false : true

                }


                error={this.props.errors.state}
                onBlur={() => {
                  this.props.setFieldTouched("state");
                }}
                touched={this.props.touched.state}
                value={this.props.values.state}
                required={true}
                onChange={(e) => {
                  e
                    ? this.onChange("state", e.value)
                    : this.onChange("state", "");
                  if (
                    e &&
                    (this.props.values["country"] === "United States" || this.props.values["country"] === "Mexico"
                    ) &&
                    this.props.values["address1"] &&
                    this.props.values["city"]
                  ) {
                    getPhysicalAddressInformation(
                      this.props.values["address1"],
                      this.props.values["city"],
                      e.value,
                      (location) => {
                        if (location.code === "200") {
                          this.props.setDisable(false);

                          this.props.setFieldValue(
                            "country",
                            location.content.country
                          );
                          this.props.setFieldValue(
                            "postal",
                            location.content.zipcode
                          );
                          this.props.setFieldValue(
                            "city",
                            location.content.city
                          );
                          this.props.setFieldValue(
                            "state",
                            location.content.state
                          );


                          this.props.setFieldValue(
                            "latitude",
                            location.content.latitude
                          );
                          this.props.setFieldValue(
                            "longitude",
                            location.content.longitude
                          );

                          this.updateTz(
                            location.content.latitude,
                            location.content.longitude
                          );
                        }
                        else {
                          this.props.setDisable(true);
                          this.onChange("state", "");
                          showError("Invalid field value, Address not found");
                        }
                      }
                    );
                  }
                }}
              />
            </FormField>
          ) : (
            <FFFormTextField
              label="State / County / Province"
              placeholder=""
              icon="fa-building"
              fieldname="state"
              disabled={
                (this.props.values.country &&
                  this.props.values.name &&
                  this.props.values.address1 &&
                  this.props.values.city) ? false : true

              }

              error={this.props.errors.state}
              touched={this.props.touched.state}
              value={this.props.values.state}
              required={true}
              onChange={(newState) => {
                this.onChange("state", newState);
              }}
            />
          )}
          <FFFormTextField
            label={
              this.props.values["country"]
                ? this.props.values["country"] === "United States"
                  ? "Zip Code"
                  : "Postal Code"
                : "Postal Code"
            }
            icon="fa-building"
            placeholder=""
            fieldname="postal"
            required={!this.props.allowQuickAdd}
            error={this.props.errors.postal}
            onTouch={this.props.handleBlur}
            onChange={(val) => {
              // if (this.props.values["country"] === "United States" && val.length > 4) {
              //   getZipInformation(val, (location) => {
              //     this.props.setFieldValue("city", location.city);
              //     this.props.setFieldValue("state", location.state);
              //     if (!this.userAddedInput) {
              //       this.props.setFieldValue("latitude", location.latitude);
              //       this.props.setFieldValue("longitude", location.longitude);
              //     }
              //     this.updateTz(location.latitude, location.longitude)
              //   }, () => {
              //   })
              // }
              this.onChange("postal", val);
            }}
            touched={this.props.touched.postal}
            value={this.props.values.postal}
          />
          <FFFormTextField
            label="Latitude"
            placeholder=""
            icon="fa-map-marker-alt"
            fieldname="latitude"
            onKeyDown={(e) => {
              this.checkManualInput(e);
            }}
            error={this.props.errors.latitude}
            onTouch={this.props.handleBlur}
            onChange={(v) => {
              this.onChange("latitude", v);
              this.updateTz(v, this.props.values["longitude"]);
            }}
            touched={this.props.touched.latitude}
            value={this.props.values.latitude}
            required={true}
            numbersOnly
          />
          <FFFormTextField
            label="Longitude"
            placeholder=""
            icon="fa-map-marker-alt"
            fieldname="longitude"
            onKeyDown={(e) => {
              this.checkManualInput(e);
            }}
            error={this.props.errors.longitude}
            onTouch={this.props.handleBlur}
            onChange={(v) => {
              this.onChange("longitude", v);
              this.updateTz(this.props.values["latitude"], v);
            }}
            touched={this.props.touched.longitude}
            value={this.props.values.longitude}
            required={true}
            numbersOnly
          />
          <FFFormCheckboxField
            label="Comment Highlight Enabled"
            icon="fa-power-off"
            fieldname="highlightEnabled"
            error={this.props.errors.highlightEnabled}
            handleBlur={this.props.handleBlur}
            onChange={(newValue) => {
              this.onChange("highlightEnabled", newValue);
            }}
            touched={this.props.touched.highlightEnabled}
            value={this.props.values.highlightEnabled}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
          />
          {this.props.values.highlightEnabled == true && (
            <>
              <FFFormTextField
                label="Comment Background Color"
                icon="fa-paintbrush"
                placeholder=""
                fieldname="backgroundColor"
                error={this.props.errors.backgroundColor}
                onChange={(newValue) => {
                  this.onChange("backgroundColor", newValue);
                }}
                touched={this.props.touched.backgroundColor}
                onTouch={this.props.handleBlur}
                value={this.props.values.backgroundColor}
                colorInput
              />
              <FFFormTextField
                label="Comment Text Color"
                icon="fa-paintbrush"
                placeholder=""
                fieldname="textColor"
                error={this.props.errors.textColor}
                onChange={(newValue) => {
                  this.onChange("textColor", newValue);
                }}
                touched={this.props.touched.textColor}
                onTouch={this.props.handleBlur}
                value={this.props.values.textColor}
                colorInput
              />
            </>
          )}
          {/* {this.props.allowQuickAdd ? "" :
//   <DwellInputWithUnit
//     label="Warning Tractor Dwell"
//     value={getDwellFromSeconds(this.props.values.warningTractorDwellInSeconds, this.props.values.warningTractorDwellUnit)}
//     touched={this.props.touched.warningTractorDwellInSeconds}
//     unit={this.props.values.warningTractorDwellUnit}
//     onChange={(obj) => {
//       this.onChange("warningTractorDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
//       this.onChange("warningTractorDwellUnit", obj.unit === '' ? null : obj.unit);
//     }}
//   />
// }
// {this.props.allowQuickAdd ? "" :
//   <DwellInputWithUnit
//     label="Critical Tractor Dwell"
//     value={getDwellFromSeconds(this.props.values.criticalTractorDwellInSeconds, this.props.values.criticalTractorDwellUnit)}
//     touched={this.props.touched.criticalTractorDwellInSeconds}
//     unit={this.props.values.criticalTractorDwellUnit}
//     onChange={(obj) => {
//       this.onChange("criticalTractorDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
//       this.onChange("criticalTractorDwellUnit", obj.unit === '' ? null : obj.unit);
//     }}
//   />
// }
// {this.props.allowQuickAdd ? "" :
//   <DwellInputWithUnit
//     label="Warning Trailer Dwell"
//     value={getDwellFromSeconds(this.props.values.warningTrailerDwellInSeconds, this.props.values.warningTrailerDwellUnit)}
//     touched={this.props.touched.warningTrailerDwellInSeconds}
//     unit={this.props.values.warningTrailerDwellUnit}
//     onChange={(obj) => {
//       this.onChange("warningTrailerDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
//       this.onChange("warningTrailerDwellUnit", obj.unit === '' ? null : obj.unit);
//     }}
//   />
// }
// {this.props.allowQuickAdd ? "" :
//   <DwellInputWithUnit
//     label="Critical Trailer Dwell"
//     value={getDwellFromSeconds(this.props.values.criticalTrailerDwellInSeconds, this.props.values.criticalTrailerDwellUnit)}
//     touched={this.props.touched.criticalTrailerDwellInSeconds}
//     unit={this.props.values.criticalTrailerDwellUnit}
//     onChange={(obj) => {
//       this.onChange("criticalTrailerDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
//       this.onChange("criticalTrailerDwellUnit", obj.unit === '' ? null : obj.unit);
//     }}
//   />
// }
// {this.props.allowQuickAdd ? "" :
//   <DwellInputWithUnit
//     label="Warning Container Dwell"
//     value={getDwellFromSeconds(this.props.values.warningContainerDwellInSeconds, this.props.values.warningContainerDwellUnit)}
//     touched={this.props.touched.warningContainerDwellInSeconds}
//     unit={this.props.values.warningContainerDwellUnit}
//     onChange={(obj) => {
//       this.onChange("warningContainerDwellInSeconds", obj.value === '' ? null : obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
//       this.onChange("warningContainerDwellUnit", obj.unit === '' ? null : obj.unit);
//     }}
//   />}
// {this.props.allowQuickAdd ? "" :
//   <DwellInputWithUnit
//     label="Critical Container Dwell"
//     value={getDwellFromSeconds(this.props.values.criticalContainerDwellInSeconds, this.props.values.criticalContainerDwellUnit)}
//     touched={this.props.touched.criticalContainerDwellInSeconds}
//     unit={this.props.values.criticalContainerDwellUnit}
//     onChange={(obj) => {
//       this.onChange("criticalContainerDwellInSeconds", obj.value === '' ? null : obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
//       this.onChange("criticalContainerDwellUnit", obj.unit === '' ? null : obj.unit);
//     }}
//   />
// }
 */}
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <FFFormTextField
              label="Contact Email Address"
              icon="fa-envelope"
              placeholder=""
              fieldname="email"
              error={this.props.errors.email}
              onTouch={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("email", newValue);
              }}
              touched={this.props.touched.email}
              value={this.props.values.email}
              required={false}
            />
          )}
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <FFFormTextField
              label="Contact First Name"
              placeholder=""
              icon="fa-id-card"
              fieldname="contactFirstName"
              error={this.props.errors.contactFirstName}
              onTouch={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("contactFirstName", newValue);
              }}
              touched={this.props.touched.contactFirstName}
              value={this.props.values.contactFirstName}
              required={false}
            />
          )}
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <FFFormTextField
              label="Contact Last Name"
              placeholder=""
              icon="fa-id-card"
              fieldname="contactLastName"
              error={this.props.errors.contactLastName}
              onTouch={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("contactLastName", newValue);
              }}
              touched={this.props.touched.contactLastName}
              value={this.props.values.contactLastName}
              required={false}
            />
          )}
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <FFFormPhoneTextField
              label="Contact Phone"
              icon="fa-phone"
              fieldname="phone"
              error={this.props.errors.phone}
              handleBlur={this.props.handleBlur}
              onChange={(e) => {
                this.onChange("phone", e.target.value);
              }}
              countryCodeFieldName={"countryCode"}
              onCountryCodeChange={(e) => {
                this.onChange("countryCode", e);
              }}
              countryCode={this.props.values.countryCode}
              touched={this.props.touched.phone}
              value={this.props.values.phone}
              placeholder={
                this.props.values["countryCode"] === "US" ||
                  !this.props.values["countryCode"]
                  ? "###-###-#### ext. XXXX"
                  : "#########"
              }
              setFieldTouched={this.props.setFieldTouched}
              setFieldValue={this.props.setFieldValue}
            />
          )}
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <FFFormPhoneTextField
              label="Contact Mobile"
              icon="fa-phone"
              fieldname="cell"
              error={this.props.errors.cell}
              handleBlur={this.props.handleBlur}
              onChange={(e) => {
                this.onChange("cell", e.target.value);
              }}
              countryCodeFieldName={"cellCountryCode"}
              onCountryCodeChange={(value) => {
                this.onChange("cellCountryCode", value);
              }}
              countryCode={this.props.values.cellCountryCode}
              touched={this.props.touched.cell}
              value={this.props.values.cell}
              placeholder={
                this.props.values["cellCountryCode"] === "US" ||
                  !this.props.values["cellCountryCode"]
                  ? "###-###-####"
                  : "#########"
              }
              setFieldTouched={this.props.setFieldTouched}
              setFieldValue={this.props.setFieldValue}
            />
          )}
          <FFFormSelectField
            label="Timezone"
            placeholder=""
            required
            icon="fa-clock"
            fieldname="timezone"
            name="timezone"
            error={this.props.errors.timezone}
            onBlur={() => {
              this.props.setFieldTouched("timezone");
            }}
            onChange={(zone) => {
              this.onChange("timezone", zone ? zone.value : "");
            }}
            touched={this.props.touched.timezone}
            value={this.props.values.timezone}
            maxMenuHeight={150}
            options={moment.tz.names()}
            setFieldTouched={this.props.setFieldTouched}
            setFieldValue={this.props.setFieldValue}
            isClearable
          />
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <FFFormTextField
              label="Location Code"
              placeholder=""
              icon="fa-cog"
              fieldname="code"
              error={this.props.errors.code}
              onChange={(newValue) => {
                this.onChange("code", newValue);
              }}
              touched={this.props.touched.code}
              onTouch={this.props.handleBlur}
              value={this.props.values.code}
              helpMessage={"The code used in your system for the location."}
            />
          )}
          <FFFormTextField
            label="Billing Code"
            placeholder=""
            icon="fa-hashtag"
            fieldname="billingCode"
            error={this.props.errors.billingCode}
            onChange={(newValue) => {
              this.onChange("billingCode", newValue);
            }}
            touched={this.props.touched.billingCode}
            onTouch={this.props.handleBlur}
            value={this.props.values.billingCode}
          />
          {!this.props.values.ezCheckInSite && (
            <FFFormTextField
              label="Location Nickname"
              placeholder=""
              icon="fa-cog"
              fieldname="nickname"
              error={this.props.errors.nickname}
              onChange={(newValue) => {
                this.onChange("nickname", newValue);
              }}
              onTouch={this.props.handleBlur}
              touched={this.props.touched.nickname}
              value={this.props.values.nickname}
              helpMessage={
                "The common name used in your company when referring to the location."
              }
            />
          )}
          {this.props.values.ezCheckInSite && (
            <FFFormTextField
              label="Location Nickname"
              placeholder=""
              icon="fa-cog"
              fieldname="nickname"
              error={this.props.errors.nickname}
              onChange={(newValue) => {
                this.onChange("nickname", newValue);
              }}
              required
              onTouch={this.props.handleBlur}
              touched={this.props.touched.nickname}
              value={this.props.values.nickname}
              helpMessage={
                "The common name used in your company when referring to the location."
              }
            />
          )}
          <FFFormCheckboxField
            label="Enabled"
            icon="fa-power-off"
            fieldname="enabled"
            error={this.props.errors.enabled}
            handleBlur={this.props.handleBlur}
            onChange={(newValue) => {
              this.onChange("enabled", newValue);
            }}
            touched={this.props.touched.enabled}
            value={this.props.values.enabled}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
          />
          <FFFormCheckboxField
            label="Spot Availability"
            icon="fa-power-off"
            fieldname="enableSpotAvailability"
            error={this.props.errors.enableSpotAvailability}
            handleBlur={this.props.handleBlur}
            onChange={(newValue) => {
              this.onChange("enableSpotAvailability", newValue);
            }}
            touched={this.props.touched.enableSpotAvailability}
            value={this.props.values.enableSpotAvailability}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
          />
          <FFFormCheckboxField
            label="Enable Move Me"
            icon="fa-power-off"
            fieldname="enableMoveMe"
            error={this.props.errors.enableMoveMe}
            handleBlur={this.props.handleBlur}
            onChange={(newValue) => {
              this.onChange("enableMoveMe", newValue);
            }}
            touched={this.props.touched.enableMoveMe}
            value={this.props.values.enableMoveMe}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
          />
          <FFFormCheckboxField
            label="Enable Priority"
            icon="fa-power-off"
            fieldname="enablePriority"
            error={this.props.errors.enablePriority}
            handleBlur={this.props.handleBlur}
            onChange={(newValue) => {
              this.onChange("enablePriority", newValue);
            }}
            touched={this.props.touched.enablePriority}
            value={this.props.values.enablePriority}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
          />
          <FFFormCheckboxField
            label="Enable Yard Arrival ID"
            icon="fa-power-off"
            fieldname="enableArrivalTracking"
            error={this.props.errors.enableArrivalTracking}
            handleBlur={this.props.handleBlur}
            disabled={this.props.values.enableArrivalTracking}
            onChange={(newValue) => {
              this.onChange("enableArrivalTracking", newValue);
            }}
            touched={this.props.touched.enableArrivalTracking}
            value={this.props.values.enableArrivalTracking}ATBB8AKVRV4dmKJmJ4ynWaARgc5t2EF64AB9


            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
          />
          {/* {this.props.values.enableArrivalTracking && (
            <FFFormCheckboxField
              label="Enable New Task Form"
              icon="fa-power-off"
              fieldname="enableNewTask"
              error={this.props.errors.enableNewTask}
              handleBlur={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("enableNewTask", newValue);
              }}
              touched={this.props.touched.enableNewTask}
              value={this.props.values.enableNewTask}
              setFieldValue={this.props.setFieldValue}
              setFieldTouched={this.props.setFieldTouched}
            />
          )} */}
          {this.props.user.company.enableGate && (
            <FFFormCheckboxField
              label="Gate"
              icon="fa-power-off"
              fieldname="pwaEnabled"
              error={this.props.errors.pwaEnabled}
              handleBlur={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("pwaEnabled", newValue);
              }}
              touched={this.props.touched.pwaEnabled}
              value={this.props.values.pwaEnabled}
              setFieldValue={this.props.setFieldValue}
              setFieldTouched={this.props.setFieldTouched}
            />
          )}
          {this.props.user.company.enableGate && (
            <FFFormCheckboxField
              label="Gate Task Enable"
              icon="fa-power-off"
              fieldname="enableGateTask"
              error={this.props.errors.enableGateTask}
              handleBlur={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("enableGateTask", newValue);
              }}
              touched={this.props.touched.enableGateTask}
              value={this.props.values.enableGateTask}
              setFieldValue={this.props.setFieldValue}
              setFieldTouched={this.props.setFieldTouched}
            />
          )}

          {this.props.user.company.enablePowerYard && (
            <FFFormCheckboxField
              label="PowerYard Site"
              icon="fa-power-off"
              fieldname="ezCheckInSite"
              error={this.props.errors.ezCheckInSite}
              handleBlur={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange("ezCheckInSite", newValue);
                this.onChange("enableTaskInProcess", newValue);
                if (newValue === false) {
                  this.onChange("enableTaskAssigment", newValue);
                }
              }}
              touched={this.props.touched.ezCheckInSite}
              value={this.props.values.ezCheckInSite}
              setFieldValue={this.props.setFieldValue}
              setFieldTouched={this.props.setFieldTouched}
            />
          )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite && (
              <FFFormCheckboxField
                label="PowerYard Task in Progress"
                icon="fa-power-off"
                fieldname="enableTaskInProcess"
                error={this.props.errors.enableTaskInProcess}
                handleBlur={this.props.handleBlur}
                onChange={(newValue) => {
                  this.onChange("enableTaskInProcess", newValue);
                }}
                touched={this.props.touched.enableTaskInProcess}
                value={this.props.values.enableTaskInProcess}
                setFieldValue={this.props.setFieldValue}
                setFieldTouched={this.props.setFieldTouched}
              />
            )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite && (
              <FFFormCheckboxField
                label="Task Assignment Required "
                icon="fa-power-off"
                fieldname="enableTaskAssigment"
                error={this.props.errors.enableTaskAssigment}
                handleBlur={this.props.handleBlur}
                onChange={(newValue) => {
                  this.onChange("enableTaskAssigment", newValue);
                }}
                touched={this.props.touched.enableTaskAssigment}
                value={this.props.values.enableTaskAssigment}
                setFieldValue={this.props.setFieldValue}
                setFieldTouched={this.props.setFieldTouched}
              />
            )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite &&
            this.props.user.company.enablePowerYardPro && (
              <FFFormCheckboxField
                label="Asset Dwell"
                icon="fa-power-off"
                fieldname="enableArrivalLog"
                error={this.props.errors.enableArrivalLog}
                handleBlur={this.props.handleBlur}
                onChange={(newValue) => {
                  this.onChange("enableArrivalLog", newValue);
                }}
                touched={this.props.touched.enableArrivalLog}
                value={this.props.values.enableArrivalLog}
                setFieldValue={this.props.setFieldValue}
                setFieldTouched={this.props.setFieldTouched}
              />
            )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite &&
            this.props.user.company.enablePowerYardPro && (
              <FFFormCheckboxField
                label="On-Site & Yard Check "
                icon="fa-power-off"
                fieldname="enableYardCheck"
                error={this.props.errors.enableYardCheck}
                handleBlur={this.props.handleBlur}
                onChange={(newValue) => {
                  this.onChange("enableYardCheck", newValue);
                }}
                touched={this.props.touched.enableYardCheck}
                value={this.props.values.enableYardCheck}
                setFieldValue={this.props.setFieldValue}
                setFieldTouched={this.props.setFieldTouched}
              />
            )}
          {(() => {
            if (
              this.props.values.ezCheckInSite &&
              !this.props.values.ezcheckinEnabled
            ) {
              return (
                <FormField
                  label={"Asset Types"}
                  errors={this.props.errors}
                  icon={"fa-truck-loading"}
                  fieldname={"equipments"}
                  helpMessage={
                    "Selected Asset Types will be used for the PowerYard at this location."
                  }
                >
                  <FFAssetTypesSelect
                    name={"equipments"}
                    fieldname={"equipments"}
                    error={this.props.errors["equipments"]}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    touched={this.props.touched["equipments"]}
                    value={this.props.values["equipments"]}
                    onAssetTypeChange={this.props.onAssetTypeChange}
                  ></FFAssetTypesSelect>
                </FormField>
              );
            } else if (
              !this.props.values.ezCheckInSite &&
              this.props.values.ezcheckinEnabled
            ) {
              return (
                <FormField
                  label={"Asset Types"}
                  errors={this.props.errors}
                  icon={"fa-truck-loading"}
                  fieldname={"equipments"}
                  helpMessage={
                    "Selected Asset Types will be used for the PowerYard at this location."
                  }
                >
                  <FFAssetTypesSelect
                    name={"equipments"}
                    fieldname={"equipments"}
                    error={this.props.errors["equipments"]}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    touched={this.props.touched["equipments"]}
                    value={this.props.values["equipments"]}
                    onAssetTypeChange={this.props.onAssetTypeChange}
                  ></FFAssetTypesSelect>
                </FormField>
              );
            } else if (
              this.props.values.ezCheckInSite &&
              this.props.values.ezcheckinEnabled
            ) {
              return (
                <FormField
                  label={"Asset Types"}
                  errors={this.props.errors}
                  icon={"fa-truck-loading"}
                  fieldname={"equipments"}
                  helpMessage={
                    "Selected Asset Types will be used for the PowerYard at this location."
                  }
                >
                  <FFAssetTypesSelect
                    name={"equipments"}
                    fieldname={"equipments"}
                    error={this.props.errors["equipments"]}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    touched={this.props.touched["equipments"]}
                    value={this.props.values["equipments"]}
                    onAssetTypeChange={this.props.onAssetTypeChange}
                  ></FFAssetTypesSelect>
                </FormField>
              );
            }
          })()}

          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite && (
              <FormField
                label={"Task Types"}
                errors={this.props.errors}
                icon={"fa-truck-loading"}
                fieldname={"taskType"}
                helpMessage={
                  "Selected Task Types will be used for the PowerYard at this location."
                }
              >
                <FFTaskTypesSelect
                  name={"taskType"}
                  fieldname={"taskType"}
                  error={this.props.errors["taskType"]}
                  handleBlur={this.props.handleBlur}
                  handleChange={this.props.handleChange}
                  touched={this.props.touched["taskType"]}
                  value={this.props.values["taskType"]}
                  onTaskTypeChange={this.props.onTaskTypeChange}
                ></FFTaskTypesSelect>
              </FormField>
            )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite && (
              <FormField
                label={"Asset Statuses"}
                errors={this.props.errors}
                icon={"fa-truck-loading"}
                fieldname={"assetStatuses"}
                helpMessage={
                  "Selected Asset Statuses will be used for the PowerYard at this location."
                }
              >
                <FFAssetStatusSelect
                  name={"assetStatuses"}
                  fieldname={"assetStatuses"}
                  error={this.props.errors["assetStatuses"]}
                  handleBlur={this.props.handleBlur}
                  handleChange={this.props.handleChange}
                  touched={this.props.touched["assetStatuses"]}
                  value={this.props.values["assetStatuses"]}
                  onAssetStatusChange={this.props.onAssetStatusChange}
                ></FFAssetStatusSelect>
              </FormField>
            )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite && (
              <FormField
                label={"Partner / Trailer Name"}
                errors={this.props.errors}
                icon={"fa-truck"}
                fieldname={"carrier"}
                helpMessage={
                  "Selected carriers will be used for the PowerYard at this location."
                }
              >
                <FFCarrierSelect
                  name={"Trailer Name"}
                  fieldname={"carrier"}
                  error={this.props.errors["carrier"]}
                  handleBlur={this.props.handleBlur}
                  handleChange={this.props.handleChange}
                  touched={this.props.touched["carrier"]}
                  value={this.props.values["carrier"]}
                  onCarriersChange={this.props.onCarrierChange}
                ></FFCarrierSelect>
              </FormField>
            )}
          {this.props.user.company.enablePowerYard &&
            this.props.values.ezCheckInSite && (
              <FormField
                label={"Cancel Reason Code"}
                errors={this.props.errors}
                icon={"fa-truck-loading"}
                fieldname={"cancelReasons"}
                helpMessage={
                  "Selected cancel reason codes will be used for the PowerYard at this location."
                }
              >
                <FFCancelReasonCodeSelect
                  name={"cancelReasons"}
                  fieldname={"cancelReasons"}
                  error={this.props.errors["cancelReasons"]}
                  handleBlur={this.props.handleBlur}
                  handleChange={this.props.handleChange}
                  touched={this.props.touched["cancelReasons"]}
                  value={this.props.values["cancelReasons"]}
                  onCancelReasonCodeChange={this.props.onCancelReasonCodeChange}
                ></FFCancelReasonCodeSelect>
              </FormField>
            )}
          {this.props.allowQuickAdd ? (
            ""
          ) : (
            <>
              <Alert>
                Company defaults will be used for the Geo-Fence radius used for
                shipment tracking and the Dwell Limit settings unless values are
                indicated below to override them.
              </Alert>
              <FFFormCheckboxField
                label="Enable  Geofence"
                icon="fa-globe-americas"
                placeholder=""
                fieldname="geofenceEnabled"
                error={this.props.errors.geofenceEnabled}
                onTouch={this.props.handleBlur}
                onChange={(newValue) => {
                  if (newValue) {
                    this.onChange(
                      "geofenceRadius",
                      this.state.oldGeofenceRadius
                    );
                  } else {
                    this.setState({
                      oldGeofenceRadius: this.props.values.geofenceRadius,
                    });
                    this.onChange("geofenceRadius", "");
                  }
                  this.onChange("geofenceEnabled", newValue);
                }}
                touched={this.props.touched.geofenceEnabled}
                value={this.props.values.geofenceEnabled}
                required="false"
              />
              <FFFormCheckboxField
                label="Enable  Domicile"
                icon="fa-globe-americas"
                placeholder=""
                fieldname="isDomicile"
                error={this.props.errors.isDomicile}
                onTouch={this.props.handleBlur}
                onChange={(newValue) => {
                  this.onChange("isDomicile", newValue);
                }}
                touched={this.props.touched.isDomicile}
                value={this.props.values.isDomicile}
                required="false"
              />
            </>
          )}
          {this.props.allowQuickAdd ? (
            ""
          ) : this.props.values.geofenceEnabled ? (
            <FFFormSelectField
              fieldname={"geofenceType"}
              label={"Geofence Type"}
              options={[SPECIFY_RADIUS, DRAW_ON_MAP]}
              value={this.props.values.geofenceType}
              onChange={(selection) => {
                this.onChange("geofenceType", selection.value);
              }}
            />
          ) : null}
          {this.props.allowQuickAdd ||
            !this.props.values.geofenceEnabled ||
            this.props.values.geofenceType === SPECIFY_RADIUS ? (
            ""
          ) : (
            <Alert color={"danger"}>
              If geofence is not drawn , it will revert back to preset radius
              settings.
            </Alert>
          )}
          {this.props.allowQuickAdd ||
            !this.props.values.geofenceEnabled ||
            this.props.values.geofenceType === DRAW_ON_MAP ? (
            ""
          ) : (
            <FFFormTextField
              label={
                "Geofence Radius (" +
                (this.props.values &&
                  this.props.values.company &&
                  this.props.values.company.distanceUnitsOfMeasure
                  ? this.props.values.company.distanceUnitsOfMeasure
                  : "Miles") +
                ")"
              }
              icon="fa-tape"
              placeholder=""
              fieldname="geofenceRadius"
              error={this.props.errors.geofenceRadius}
              onTouch={this.props.handleBlur}
              onChange={(newValue) => {
                this.onChange(
                  "geofenceRadius",
                  newValue === "" ? null : newValue
                );
              }}
              touched={this.props.touched.geofenceRadius}
              value={this.props.values.geofenceRadius}
              required="false"
              numbersOnly={true}
            />
          )}
          {this.props.allowQuickAdd ||
            !this.props.values.geofenceEnabled ||
            this.props.values.geofenceType === SPECIFY_RADIUS ? (
            ""
          ) : (
            <div style={this.getMapSize()}>
              <GeofenceMap
                center={{
                  lat: this.props.values.latitude,
                  lng: this.props.values.longitude,
                }}
                locationName={this.props.values.name}
                size={this.getMapSize()}
                onChange={(shape, geoJson) => {
                  let vals = this.props.values;
                  vals.geofenceType = DRAW_ON_MAP;
                  vals.shape = geoJson;
                  this.props.onChange(vals);
                }}
                shape={this.props.values.shape}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

LocationFormFields.propTypes = {
  errors: PropTypes.any,
  handleBlur: PropTypes.any,
  handleChange: PropTypes.any,
  touched: PropTypes.any,
  values: PropTypes.any,
  user: PropTypes.any,
  onCountryChange: PropTypes.any,
  onStateChange: PropTypes.any,
};
