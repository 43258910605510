import React, { Component } from 'react';
import Config from '../../Config';
import { Modal, ModalHeader, ModalBody, Input, Card, CardBody, Button } from 'reactstrap';
import ReactTable from "react-table";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import { showError, showInfo } from '../../MainApp';
import { v4 as uuidv4 } from 'uuid';
import {getLocalStorageName} from '../../common/utilities';
class UploadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            selectedFile: null,
            isUploading: false,
            deleteModalOpen: false,
            deleteComments: "",
            itemToDelete: null,
            uploadingImages: {}, // New: To track which images are being uploaded
        };

    }

    componentDidUpdate(prevProps) {
        // Check if the modal is opened and if it's a new task
        if (this.props.uploadModalOpen && !prevProps.uploadModalOpen) {
            if (this.props.isnewtask) {
                // Load images from localStorage when it's a new task
                this.loadImagesFromLocalStorage();
            } else {
                // Otherwise, fetch the data from the API
                this.fetchUploadedImages();
            }
        }
    }
    
    loadImagesFromLocalStorage = () => {
        const localStorageKey = getLocalStorageName("poweryard-create-gate-task-images", this.props.user, this.props.uploadprops.controllId);
    
        // Retrieve data from localStorage based on the unique key
        const storedImages = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    
        // Filter the stored images based on the controllId
        const filteredImages = storedImages.filter(image => image.controllId === this.props.uploadprops.controllId);
    
        // Set the table data with the filtered images
        const images = filteredImages.map(item => ({
            id: uuidv4(), // You can use the original image ID if needed
            fileName: item.filename,
            quality: item.ImageQuality,
            path: item.path,
            upload: item.Upload,
        }));
    
        this.setState({ tableData: images });
    };
    

    fetchUploadedImages = async () => {
        const url = `${Config.driverApiServer}/api/AppRun/GetGateImagesUploadToBlob?ArrivalId=${this.props.arrivalID}&ControllId=${this.props.uploadprops.controllId}`;
        try {
            const response = await fetch(url,
             {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                const images = result.data.map(item => ({
                    id: uuidv4(), // Use a unique identifier if available
                    fileName: item.attacmentPath.split('/').pop(), // Get the file name
                    quality: item.imageQuality,
                    path: item.attacmentPath,
                    upload: item.upload,
                }));

                this.setState({ tableData: images });
            } else {
                console.error('Failed to fetch images');
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    handleFileChange = async (event) => {
        let file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    
        if (file) {
            if (!allowedFileTypes.includes(file.type)) {
                showError("Only .jpg, jpeg, and .png files are allowed.");
                return;
            }
    
            if (file.size > maxFileSize) {
                showError("File size cannot exceed 5 MB.");
                return;
            }
    
            if (this.state.tableData.length >= 5) {
                showError("You cannot add more than 5 records. Please delete one to add a new file.");
                return;
            }
    
            const fileData = {
                id: uuidv4(),
                fileName: file.name,
                file: file,
            };
    
            if (this.checkFileNameExists(file.name)) {
                showError(`File with name ${file.name} already exists.`);
            } else {
                try {
                    const quality = await this.checkImageQuality(file);
                    fileData.quality = quality;
    
                    this.setState((prevState) => ({
                        tableData: [...prevState.tableData, fileData],
                        selectedFile: fileData.file,
                    }));
                } catch (error) {
                    console.error("Error checking image quality:", error);
                }
            }
        }
    };
    


    checkImageQuality = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
    
            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;
    
                image.onload = () => {
                    const { width, height } = image;
                    let quality;
    
                    if (width < 500 || height < 500) {
                        quality = "Bad";
                    } else if (width < 1000 || height < 1000) {
                        quality = "Average";
                    } else {
                        quality = "Good";
                    }
    
                    resolve(quality);
                };
    
                image.onerror = (error) => {
                    reject("Failed to load image");
                };
            };
    
            reader.onerror = (error) => {
                reject("Failed to read file");
            };
        });
    };
    
    checkFileNameExists(newFileName) {
        const { tableData } = this.state;

        const fileExists = tableData.some(file => file.fileName === newFileName);
        return fileExists;
    }

    // Function to calculate the blur using Laplacian variance
    // Function to calculate the blur using Laplacian variance
   

    handleViewImage = (path, d64) => {
        if (this.props.isnewtask) {
            // If it's a new task, download the image
            this.downloadImage(d64, "downloaded_image.jpg");  // You can change the filename as needed
        } else {
            // If it's not a new task, open the image URL
            window.open(path);
        }
    };
    
    downloadImage = (d64, fileName) => {
        const link = document.createElement('a');
        link.href = `data:image/jpeg;base64,${d64}`;  // Assuming the image is jpeg, change if necessary
        link.download = fileName;
        link.click();
    };
    
    handleDeleteImage = (id) => {
        this.setState((prevState) => ({
            tableData: prevState.tableData.filter(item => item.id !== id),
        }));
    };
    handleDeleteImageV2 = (id) => {
        this.setState({ itemToDelete: id, deleteModalOpen: true });
    };

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]); // Get the Base64 part
            reader.onerror = (error) => reject(error);
        });
    };

    handleUploadImage = async (fileData) => {
        const url = `${Config.driverApiServer}/api/AppRun/GateImagesUploadToBlob`;
        try {
            const base64Image = await this.convertToBase64(fileData.file);
            const payload = {
                organizationUuid: this.props.organizationUuid,
                d64: base64Image,
                filename: fileData.fileName,
                path: "your/path/here",
                fieldName: this.props.uploadprops.fieldName,
                controllId: this.props.uploadprops.controllId,
                arrivalId: this.props.arrivalID,
                Upload: true,
                ImageQuality: await this.checkImageQuality(fileData.file),
                comments: "your comments",
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                alert("Image uploaded successfully!");

                // Update the state to mark the image as uploaded
                this.setState((prevState) => ({
                    tableData: prevState.tableData.map(item =>
                        item.id === fileData.id ? { ...item, upload: true } : item
                    ),
                }));
            } else {
                showError("Failed to upload the image.");
            }
        } catch (error) {
            console.error("Error during upload process:", error);
            showError("Failed to upload the image.");
        }
    };
    handleSave = async () => {
        this.setState({ isUploading: true });
    
        const imagesToUpload = this.state.tableData.filter(item => !item.upload);
        for (const fileData of imagesToUpload) {
            this.setState(prevState => ({
                uploadingImages: {
                    ...prevState.uploadingImages,
                    [fileData.id]: true,  // Mark this file as being uploaded
                }
            }));
    
            try {
                if (this.props.isnewtask) {
                    // Construct the localStorage key dynamically
                    const localStorageKey = getLocalStorageName("poweryard-create-gate-task-images", this.props.user);
    
                    // Prepare the payload
                    const payload = {
                        organizationUuid: this.props.organizationUuid,
                        d64: await this.convertToBase64(fileData.file),
                        filename: fileData.fileName,
                        path: "your/path/here",
                        fieldName: this.props.uploadprops.fieldName,
                        controllId: this.props.uploadprops.controllId,
                        arrivalId: this.props.arrivalID,
                        Upload: true,
                        ImageQuality: await this.checkImageQuality(fileData.file),
                        comments: "your comments",
                    };
    
                    // Retrieve existing images from localStorage (if any)
                    const existingImages = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    
                    // Add the new image to the existing images
                    existingImages.push(payload);
    
                    // Save the updated images back to localStorage for the user
                    localStorage.setItem(localStorageKey, JSON.stringify(existingImages));
    
                    showInfo("Attachments saved .");
                } else {
                    // Proceed with the regular upload to API if not a new task
                    await this.handleUploadImage(fileData);
                }
            } catch (error) {
                console.error("Error handling upload:", error);
            }
    
            this.setState(prevState => ({
                uploadingImages: {
                    ...prevState.uploadingImages,
                    [fileData.id]: false,  // Remove the loader once done
                }
            }));
        }
    
        this.setState({ isUploading: false });
        this.handleCancel();
    };
    
    handleDeleteFromBlob = async () => {
        const url = `${Config.driverApiServer}/api/AppRun/DeleteGateImage`;
        const { itemToDelete, deleteComments, tableData } = this.state;
        const item = tableData.find(item => item.id === itemToDelete);
        console.log(item, itemToDelete, deleteComments, "deleteComments");
        if (!item) return;

        const payload = {
            url: item.path,  // This should already be the full path
            comments: "",
        };

        try {
            const response = await fetch(url, {
                method: 'POST', // Ensure this matches your API's expected method
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                alert("Image deleted successfully!");
                this.setState(prevState => ({
                    tableData: prevState.tableData.filter(item => item.id !== itemToDelete),
                    deleteModalOpen: false,
                    deleteComments: "",
                    itemToDelete: null,
                }));
            } else {
                const errorData = await response.json();
                console.error("Error deleting image:", errorData);
                showError("Failed to delete the image.");
            }
        } catch (error) {
            console.error("Error during delete process:", error);
            showError("Failed to delete the image.");
        }
    };

    handleCancelDelete = () => {
        this.setState({ deleteModalOpen: false, deleteComments: "", itemToDelete: null });
    };

    handleCancel = () => {
        this.setState({ tableData: [] }); // Clear the table data on cancel
        this.props.onClose && this.props.onClose(); // Call the parent onClose function
    };

    triggerFileInput = () => {
        this.fileInput.click(); // Triggers the hidden file input on button click
    };
    tableColumns = [
        {
            accessor: "index",
            Header: "#",
            Cell: (row) => row.index + 1,
        },
        {
            accessor: "fileName",
            Header: "File Name",
            Cell: (row) => row.original.fileName || "",
        },
        {
            accessor: "quality",
            Header: "Quality",
            Cell: (row) => row.original.quality || "",
        },
        {
            accessor: "action",
            Header: "Action",
            Cell: (row) => (
                <div>
                    {this.state.uploadingImages[row.original.id] ? (
                        <i className="fa fa-spinner fa-spin" style={{ marginRight: '10px' }}></i>
                    ) : (
                        <>
                            {row.original.upload &&(
                               <i
                               className="fa-regular fa-eye"
                               style={{ cursor: 'pointer', marginRight: '10px' }}
                               onClick={() => this.handleViewImage(row.original.path, row.original.d64)}
                           ></i>
                            )}

                        </>
                    )}
                    {this.state.isUploading==false && (<i
                        className="fa-solid fa-trash-can"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (row.original.upload) {
                                this.handleDeleteImageV2(row.original.id);
                            } else {
                                this.handleDeleteImage(row.original.id);
                            }
                        }}
                    ></i>)}
                </div>
            ),
        }

    ];

    render() {
        return (
            <>
                <Modal isOpen={this.props.uploadModalOpen} toggle={this.props.toggleModal} size="lg">
                    <ModalHeader toggle={this.props.toggleModal}>
                        Upload Details
                    </ModalHeader>

                    <ModalBody>

                        <Card className="simple-card">
                            <div style={{ backgroundColor: '#f0f0f0', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
                                <p style={{ color: 'black', margin: 0, fontWeight: 'bold' }}>Please follow the guidelines for uploading files:</p>
                                <p style={{ color: 'black', margin: 0 }}>
                                    You can attach up to five image files like <span style={{ color: 'red' }}>.jpg, jpeg and .png</span> with a maximum size of   <span style={{ color: 'red' }}>5 MB</span> each.Please be sure to attach them in the order you would like them displayed.
                                </p>
                            </div>
                            <CardBody>
                                <div style={{ marginBottom: '20px' }}>
                                    <button
                                        style={{
                                            padding: '10px 20px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={this.triggerFileInput}
                                    >
                                        Browse
                                    </button>

                                    {/* The hidden file input */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }} // Hide the default file input
                                        onChange={this.handleFileChange}
                                        ref={(input) => (this.fileInput = input)} // Reference to trigger the input
                                    />
                                </div>
                                <div className="main-table-wrapper">
                                    <ReactTable
                                        manual
                                        showPagination={false}
                                        columns={this.tableColumns}
                                        data={this.state.tableData}
                                        className="-striped -highlight"
                                        style={{
                                            maxHeight: this.state.tableData.length > 5 ? "400px" : `${this.state.tableData.length * 60}px`,
                                            minHeight: "300px",
                                            overflowY: "auto",
                                        }}
                                    />
                                </div>
                            </CardBody>
                        </Card>

                        <div className="mb-3 mt-2 text-left">

                            <Button
                                className={"float-right"}
                                color="primary"
                                onClick={this.handleSave}
                                disabled={this.state.isUploading}
                            >
                                Save
                            </Button>
                            <Button
                                className={"float-right  mr-2"}
                                color="danger"
                                onClick={this.handleCancel}
                            >
                                Close
                            </Button>


                        </div>

                    </ModalBody>
                </Modal>
                {/* Delete Confirmation Modal */}
                <Modal isOpen={this.state.deleteModalOpen} toggle={this.handleCancelDelete}>
                    <ModalHeader toggle={this.handleCancelDelete}>Confirm Deletion</ModalHeader>
                    <ModalBody>
                        <p>Are You Sure You Want To Delete The Image?</p>

                        <Button color="danger" onClick={this.handleDeleteFromBlob}>
                            Delete
                        </Button>
                        &nbsp;&nbsp;

                        <Button color="secondary" onClick={this.handleCancelDelete}>
                            Cancel
                        </Button>
                    </ModalBody>
                </Modal>

            </>
        );
    }
}

export default UploadModal;
